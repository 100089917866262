<template>
  <v-container>
    <v-row>
      <v-col class="mt-14" cols="12">
        <h2>Balance Sheet</h2>
      </v-col>
      <v-col class="mt-1 col-12 col-sm-12 col-md-12 col-lg-8 col-xl-6">
        <h3 class="mb-1">Select a time period:</h3>
        <v-form @submit.prevent="generatePDF" ref="form">
          <v-row class="justify-center mb-0">
            <v-col class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="selectedStartDate"
                    label="Start Date"
                    prepend-icon="mdi-calendar-range"
                    persistent-hint
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="startDateRules"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  :show-current="false"
                  v-model="selectedStartDate"
                  no-title
                  @input="menu1 = false"
                  :min=minDate
                  :max=maxDate
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="selectedEndDate"
                    label="End Date"
                    prepend-icon="mdi-calendar-range"
                    persistent-hint
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="endDateRules"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  :show-current="false"
                  v-model="selectedEndDate"
                  no-title
                  @input="menu2 = false"
                  :min=minDate
                  :max=maxDate
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="mb-0 mt-0">
            <v-col style="width: 100%">
              <v-textarea
                class="remarks-textarea"
                rows="3"
                :counter="200"
                label="Remarks"
                v-model="remarks"
                :rules="remarksRules"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="text-right" style="width: 100%">
              <v-btn @click="generatePDF" color="primary">
                <v-icon left>mdi-printer</v-icon>
                Print
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import getTransactionsMinMaxDates from '@/api/customer/getTransactionsMinMaxDates';
import createReportSheet from '@/api/customer/createReportSheet';

export default {
  data() {
    return {
      menu1: false,
      menu2: false,
      selectedStartDate: '',
      selectedEndDate: '',
      minDate: '',
      maxDate: '',
      remarks: '',
      endDateRules: [(v) => (v >= this.selectedStartDate) || 'The end date is earlier than the start date!'],
      startDateRules: [(v) => (v <= this.selectedEndDate)],
      remarksRules: [(v) => v?.length <= 200 || 'Max 200 characters'],
    };
  },
  created() {
  },
  computed: {
  },
  mounted() {
    this.fetchMinMaxDates();
  },
  watch: {
    selectedStartDate: {
      immediate: true,
      async handler() {
        await this.$nextTick();
        this.$refs.form.validate();
      },
    },
    selectedEndDate: {
      immediate: true,
      async handler() {
        await this.$nextTick();
        this.$refs.form.validate();
      },
    },
  },
  methods: {
    fetchMinMaxDates() {
      getTransactionsMinMaxDates()
        .then((response) => {
          this.minDate = response.data.min_date;
          this.maxDate = response.data.max_date;
          // eslint-disable-next-line prefer-destructuring
          this.selectedStartDate = this.minDate.split(' ')[0];
          // eslint-disable-next-line prefer-destructuring
          this.selectedEndDate = this.maxDate.split(' ')[0];
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
        });
    },
    generatePDF() {
      if (!this.$refs.form.validate()) return;
      createReportSheet(this.selectedStartDate, this.selectedEndDate, this.remarks, null);
    },
  },
};
</script>

<style lang="scss">
  .remarks-textarea textarea {
    padding: 15px;
  }

  .remarks-textarea.v-textarea.v-input--is-focused .v-input__slot {
    transition: 0.4s;
    background-color: white;
  }
</style>
