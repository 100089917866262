<template>
  <div class="monthly-usage">
    <div class="usage-filter-wrapper">
      <div>
        <v-select
          class="company-select"
          style="width: 250px;"
          solo
          placeholder="Company"
          clearable
          dense
          :items="companies"
          item-value="id"
          item-text="name"
          label="Company"
          v-model="companySelected"
          @change="onCompanyfilterUpdate"
        ></v-select>
      </div>
      <div>
        <v-select
          class="ml-2 month-select"
          style="width: 250px;"
          solo
          placeholder="Duration"
          dense
          :items="rangeItems"
          :item-text="item => item.text"
          :item-value="item => item.value"
          v-model="selectedRange"
          @change="onDurationUpdate"
        ></v-select>
      </div>
    </div>
    <div v-if="loading" class="d-flex justify-center">
      <v-progress-circular
        indeterminate
        size="25"
        width="2"
        color="primary"
      ></v-progress-circular>
    </div>
    <LineChartGenerator
      v-show="!loading"
      ref="lineChart"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </div>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from 'chart.js';
import getTrainingMinutesMonthly from '@/api/admin/statistics/getTrainingMinutesMonthly';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
);

export default {
  name: 'AdminTrainingsLineChart',
  components: {
    LineChartGenerator,
  },
  props: {
    chartId: {
      type: String,
      default: 'admin-trainings-line-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    companies: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: true,
      trainings: [],
      minutes: [],
      date: new Date(),
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth() + 1,
      previousYear: new Date().getFullYear() - 1,
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Minutes',
            backgroundColor: '#21BA45',
            data: [],
          },
          {
            label: 'Trainings',
            backgroundColor: '#CE0F69',
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
      companySelected: null,
      selectedRange: 12,
      rangeItems: [
        { text: '3 months', value: 3 },
        { text: '6 months', value: 6 },
        { text: '12 months', value: 12 },
        { text: '24 months', value: 24 },
      ],
    };
  },
  mounted() {
    this.getChartData();
  },
  methods: {
    onDurationUpdate() {
      this.loading = true;
      this.getChartData();
    },
    onCompanyfilterUpdate() {
      this.loading = true;
      this.getChartData();
    },
    getChartData() {
      const companyId = this.companySelected ? this.companySelected : null;
      let startYear = this.currentYear;
      let startMonth = this.currentMonth - this.selectedRange + 1;
      while (startMonth <= 0) {
        startYear -= 1;
        startMonth += 12;
      }

      const yearsNeeded = this.currentYear - startYear + 1;
      const promises = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < yearsNeeded; i++) {
        const yearToFetch = this.currentYear - i;
        promises.push(getTrainingMinutesMonthly(yearToFetch, companyId));
      }

      Promise.all(promises)
        .then((responses) => {
          const combinedData = [];
          responses.forEach((response, index) => {
            const yearData = response?.data || [];
            yearData.forEach((item) => {
              // eslint-disable-next-line no-param-reassign
              item.year = this.currentYear - index;
            });
            combinedData.push(...yearData);
          });

          const relevantData = this.getLastMonthsData(combinedData, this.selectedRange);
          this.updateChartData(relevantData);
          this.$refs.lineChart.updateChart();
          this.loading = false;
        })
        .catch((error) => {
          console.error('Error fetching chart data:', error);
        });
    },
    getLastMonthsData(combinedData, months) {
      combinedData.sort((a, b) => a.year - b.year || a.month - b.month);

      const endYear = this.currentYear;
      const endMonth = this.currentMonth;
      let startYear = this.currentYear;
      let startMonth = this.currentMonth - months + 1;

      while (startMonth <= 0) {
        startYear -= 1;
        startMonth += 12;
      }

      return combinedData.filter((item) => {
        if (startYear === endYear) {
          return item.year === startYear && item.month >= startMonth && item.month <= endMonth;
        }
        if (item.year > startYear && item.year < endYear) {
          return true;
        }
        if (item.year === startYear && item.month >= startMonth) {
          return true;
        }
        if (item.year === endYear && item.month <= endMonth) {
          return true;
        }
        return false;
      });
    },
    updateChartData(data) {
      this.chartData.datasets[0].data = data.map((item) => item.minutes);
      this.chartData.datasets[1].data = data.map((item) => item.number);
      this.chartData.labels = data.map((item) => {
        const label = this.getMonthNameAndYear(item.month, item.year);
        return label;
      });
    },
    getMonthNameAndYear(month, year) {
      const date = new Date(year, month - 1);
      const monthName = date.toLocaleString(navigator.language, { month: 'long' });
      return `${monthName} ${year}`;
    },
  },
};
</script>

<style lang="scss">
.monthly-usage {
  position: relative;
}

.monthly-usage .usage-filter-wrapper {
  display: flex;
  position: absolute;
  top: -41px;
  right: 20px;
}
</style>
