<template>
  <v-container>
    <div>
      <span v-if="machines !== undefined && machines.length > 0">
        <v-row>
          <v-col class="mt-1 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
            <h3 class="mb-3">Choose dataset</h3>
            <v-select
              label="Select machine"
              :items="machines"
              item-text="title"
              item-value="id"
              v-model="selected_machine_dir"
              @change="onMachineSelectionChange()">
            </v-select>
            <v-select
              label="Select dataset"
              :items="dataset_dirs"
              itemText="name"
              itemValue="name"
              v-model="selected_dataset_dir"
              enabled="selected_dataset_valid"
              @change="onDataSelectionChange()">
            </v-select>
            <template v-if="loadingFileListing">
              <div class="overlay-loading"></div>
              <div class="icon-loading">
                <v-progress-circular
                  indeterminate
                  size="25"
                  width="2"
                  color="primary"
                ></v-progress-circular>
              </div>
            </template>
          </v-col>
          <v-col cols="12">
            <v-row v-if="selected_dataset_valid">
              <v-col class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                <div class="box-card pt-3 pl-4 pb-3 elevation-1" style="height:100%;">
                  <h3>Info</h3>
                  <br>
                  Dataset "{{ dataset.name }}" has {{ dataset.images.length }} image(s) and
                  {{ dataset.labels.length }} label(s)<br/>
                  <ul v-if="false && dataset.images.length > 0">
                    <li v-for="img in dataset.images.slice(0, 8)" v-bind:key="img"
                      style="list-style-type: none;">
                      {{ img }}
                    </li>
                    <span v-if="dataset.images.length > 20">...</span>
                  </ul>
                  <br>
                  There are {{ num_enabled_classes }} enabled classes
                    <span v-if="num_enabled_classes > 0"> ({{ enabled_classes.join(', ') }})</span>
                    and {{ num_disabled_classes }} disabled classes
                    <span v-if="num_disabled_classes > 0">({{ disabled_classes.join(', ') }})</span>
                </div>
              </v-col>
              <v-col class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
                <div class="box-card elevation-1" style="border-radius: 8px; min-height: 150px">
                  <v-row class="pt-3 pl-4 pb-0">
                    <v-col cols="6">
                      <h3>Colours</h3>
                    </v-col>
                    <v-col cols="6" class="pt-0 pb-1 pr-7" :align="'right'">
                      <v-btn
                        @click="onAddColorClick()"
                        color="primary"
                        class="mt-3 mb-3"
                        :disabled="dataset.colortable.length >= dataset.labelmap.length"
                        >
                        Add colour
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-simple-table dense v-if="dataset !== null
                    && dataset.colortable != null
                    && dataset.colortable.length > 0"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th style="width: 30%">Index</th>
                          <th style="width: 40%">Colour</th>
                          <th style="width: 30%">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(color, index) in dataset.colortable" v-bind:key="index"
                          style="height: 67px;">
                          <td>{{ index }}</td>
                          <td>
                            <input type="color" :value="!ignore_colors ? color : default_colors[index]"
                              @change="onColorChange($event, index)" :disabled="colorsIgnored">
                          </td>
                          <td>
                            <v-btn icon @click="onDeleteColorClick(index)" :disabled="ignore_colors">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <div Class="text-center pt-5 pb-3" v-else>
                    No Colours
                  </div>
                </div>
              </v-col>
              <v-col class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
                <div class="box-card elevation-1" style="border-radius: 8px; min-height: 150px">
                  <span v-if="is_adding_class">
                    <v-text-field class="pt-5 pl-3"
                      label="Name" v-model="adding_class_name"
                      @input="onInput($event)" hide-details dense>
                      <template slot="append-outer">
                        <v-btn icon @click="onButtonAddClassAddClick()"
                          color="primary"><v-icon>mdi-check</v-icon></v-btn>
                        <v-btn icon @click="is_adding_class = false"
                          color="primary"><v-icon>mdi-close</v-icon></v-btn>
                      </template>
                    </v-text-field>
                  </span>
                  <span v-else-if="is_editing_class">
                    <v-text-field class="pt-5 pl-3"
                      label="Name" v-model="editing_class_name"
                      @input="onInput($event)" hide-details dense>
                      <template slot="append-outer">
                        <v-btn icon @click="onClassEditClick(editing_class_name)"
                          color="primary"><v-icon>mdi-check</v-icon></v-btn>
                        <v-btn icon @click="is_editing_class = false"
                          color="primary"><v-icon>mdi-close</v-icon></v-btn>
                      </template>
                    </v-text-field>
                  </span>
                  <v-row class="pt-3 pl-4 pb-0">
                    <v-col cols="6">
                      <h3>Classes</h3>
                    </v-col>
                    <v-col cols="6" class="pt-0 pb-1 pr-7" :align="'right'">
                      <v-btn
                        @click="is_adding_class = true"
                        :disabled="is_adding_class || is_editing_class"
                        color="primary"
                        class="mt-3 mb-3"
                        >
                        Add class
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-simple-table dense
                    v-if="dataset !== null && dataset.labelmap !== null
                    && dataset.labelmap.length > 0"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th style="width: 15%">Index</th>
                          <th style="width: 35%">Name</th>
                          <th style="width: 20%">Enabled</th>
                          <th style="width: 30%">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(classname, index) in dataset.labelmap" v-bind:key="index">
                          <td>{{ index }}</td>
                          <td>{{ classname.Name }}</td>
                          <td>
                            <v-switch @change="onLabelMapEntryEnableChange(index, $event)"
                              :input-value="!classname.Ignored"></v-switch>
                          </td>
                          <td>
                            <v-btn icon @click="onLabelMapEntryEdit(index)">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn icon @click="onLabelMapEntryDelete(index)">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <div Class="text-center pt-5 pb-3" v-else>No Classes</div>
                </div>
              </v-col>
              <v-col cols="12" class="pt-0 pb-0">
                <v-row justify="end">
                  <v-col class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">
                    <v-checkbox
                      class="mt-3"
                      color="#CE0F69"
                      label="Use default colours"
                      v-model="ignore_colors"
                      @change="onUseDefaultColors()"
                      :disabled="defaultColorsUsed"
                    ></v-checkbox>
                  </v-col>
                  <v-col class="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2">
                    <v-btn style="float: right;" @click="onNextButtonClick(dataset)"
                      color="primary" class="mt-3"
                      :disabled="next_button_clicked || dataset.labelmap == null || !selected_dataset_valid
                        || labelling_method == '' || dataset.labelmap.length < 1
                        || !hasEnabledClasses()
                        || (!ignore_colors
                          && dataset.colortable.length < dataset.labelmap.length)">
                        <!-- NEXT BUTTON -->
                        {{ next_button_clicked ? 'Loading...' : 'Save & continue' }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </span>
      <span v-else>
        Loading datasets...
      </span>
    </div>
  </v-container>
</template>

<script>
import getMachines from '@/api/user/getMachines';
import folderList from '@/api/user/folderList';
import getDataset from '@/api/labelling/getDataset';
import saveDataset from '@/api/labelling/saveDataset';

export default {
  name: 'LabellingConfig',
  data() {
    return {
      dataset_dirs: [],
      selected_machine_dir: '',
      selected_dataset_dir: '',
      dataset: null,
      selected_dataset_valid: false,
      is_adding_class: false,
      adding_class_name: '',
      is_editing_class: false,
      editing_class: null,
      editing_class_name: '',
      labelling_method: 'bbox',
      loadingFileListing: false,
      machines: [],
      machine: '',
      colors: [
        '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
        '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
        '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
        '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
        '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
        '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
        '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
        '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
        '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
        '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF',
      ],
      color_index: 0,
      default_colors: [],
      ignore_colors: false,
      next_button_clicked: false,
    };
  },
  created() {
    this.getMachines();
  },
  computed: {
    enabled_classes() {
      const result = [];
      if (this.dataset.labelmap == null) return result;
      this.dataset.labelmap.forEach(
        (obj) => {
          if (!obj.Ignored) {
            result.push(obj.Name);
          }
        },
      );
      return result;
    },
    disabled_classes() {
      const result = [];
      if (this.dataset.labelmap == null) return result;
      this.dataset.labelmap.forEach(
        (obj) => {
          if (obj.Ignored) {
            result.push(obj.Name);
          }
        },
      );
      return result;
    },
    num_enabled_classes() {
      if (this.enabled_classes !== null && this.enabled_classes !== undefined) {
        return this.enabled_classes.length;
      }
      return 0;
    },
    num_disabled_classes() {
      if (this.disabled_classes !== null && this.disabled_classes !== undefined) {
        return this.disabled_classes.length;
      }
      return 0;
    },
    defaultColorsUsed() {
      if (((this.dataset.colortable.every((c) => this.colors.includes(c))) && !(this.dataset.colortable.length < this.dataset.labelmap.length)) || ((this.dataset.colortable.length < this.dataset.labelmap.length) && this.ignore_colors)) {
        return true;
      }
      return false;
    },
    colorsIgnored() {
      if (!(this.dataset.colortable.every((c) => this.colors.includes(c))) && this.ignore_colors) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async getMachines() {
      await getMachines()
        .then((response) => {
          this.machines = response.data;
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.loadingFileListing = false;
        });
    },
    fetchFoldersOfMachine(machineId) {
      this.loadingFileListing = true;
      folderList(machineId)
        .then((response) => {
          this.dataset_dirs = response.data;
        })
        .finally(() => {
          this.loadingFileListing = false;
        });
    },
    fetchDataset(machineId, dataset) {
      this.loadingFileListing = true;
      getDataset(machineId, dataset)
        .then((response) => {
          this.dataset = response.data.data;
          this.dataset.machine = machineId;
          this.selected_dataset_valid = true;
        })
        .finally(() => {
          this.loadingFileListing = false;
        });
    },
    onMachineSelectionChange() {
      this.dataset_dirs = [];
      this.machine = '';
      this.selected_dataset_dir = null;
      this.selected_dataset_valid = false;
      this.fetchFoldersOfMachine(this.selected_machine_dir);
    },
    onDataSelectionChange() {
      this.selected_dataset_valid = false;
      this.fetchDataset(this.selected_machine_dir, this.selected_dataset_dir);
    },
    onAddColorClick() {
      if (this.dataset.colortable.length === 0) {
        this.color_index = 0;
      }
      this.dataset.colortable.push(this.colors[((this.color_index += 1) % this.colors.length)]);
    },
    onDeleteColorClick(index) {
      this.dataset.colortable.splice(index, 1);
      for (let i = 1; i < this.dataset.colortable.length; i += 1) {
        if (this.colors.includes(this.dataset.colortable.slice(-i)[0])) {
          this.color_index = this.colors.indexOf(this.dataset.colortable.slice(-i)[0]);
          break;
        }
      }
      this.ignore_colors = false;
    },
    onColorChange(e, index) {
      this.dataset.colortable[index] = e.target.value;
      this.dataset.colortable.splice(index, 1, this.dataset.colortable[index]);
      this.ignore_colors = false;
    },
    onUseDefaultColors() {
      if (this.ignore_colors) {
        this.color_index = 0;
      }
      this.dataset.colortable.length = this.dataset.labelmap.length;
      for (let i = 0; i < this.dataset.colortable.length; i += 1) {
        this.default_colors[i] = this.colors[((this.color_index += 1) % this.colors.length)];
        if (this.dataset.colortable[i] === undefined) {
          for (let j = 0; j < this.colors.length; j += 1) {
            if (!this.dataset.colortable.includes(this.colors[j])) {
              this.dataset.colortable[i] = this.colors[j];
              break;
            }
          }
        }
      }
    },
    onInput(e) { return e; },
    onButtonAddClassAddClick() {
      let duplicateNames = false;
      this.dataset.labelmap.forEach((item) => {
        if (this.adding_class_name.toLowerCase() === item.Name.toLowerCase()) {
          duplicateNames = true;
        }
      });
      if (duplicateNames) {
        this.$root.$emit('class-names-duplicated');
        return;
      }
      if (this.adding_class_name === '') {
        this.$root.$emit('empty-class-name');
        return;
      }
      this.dataset.labelmap.push({ Name: this.adding_class_name, Ignored: false });
      this.is_adding_class = false;
      this.adding_class_name = '';
    },
    onClassEditClick(className) {
      this.editing_class.Name = className;
      this.is_editing_class = false;
    },
    hasEnabledClasses() {
      return this.num_enabled_classes > 0;
    },
    onLabelMapEntryDelete(classno) {
      this.dataset.labelmap.splice(classno, 1);
    },
    onLabelMapEntryEdit(classIndex) {
      this.is_editing_class = true;
      this.editing_class = this.dataset.labelmap[classIndex];
      this.editing_class_name = this.dataset.labelmap[classIndex].Name;
    },
    onLabelMapEntryEnableChange(classno, state) {
      this.dataset.labelmap[classno].Ignored = !state;
    },
    onNextButtonClick(ds) {
      try {
        if (this.ignore_colors) {
          this.dataset.colortable = this.default_colors;
        }
        this.next_button_clicked = true;
        saveDataset(this.dataset.machine, this.dataset).then(
          () => {
            this.next_button_clicked = false;
            this.$store.dispatch('user/setDataset', { ds });
            this.$emit('config-saved');
          },
        ).catch(
          () => {
            this.next_button_clicked = false;
          },
        );
      } catch (error) {
        console.error(error);
      }
    },
  },
  beforeMount() {
    const DEV = false;
    if (DEV) {
      this.dataset = this.$store.getters['user/dataset'];
      this.machine = this.dataset.machine;
      this.selected_machine_dir = this.dataset.machine;
      this.selected_dataset_dir = this.dataset.name;
      this.selected_dataset_valid = true;
    } else {
      this.$store.dispatch('user/setDataset', { ds: null });
    }
  },
  mounted() {
  },
  components: {
  },
};
</script>

<style scoped lang="scss"></style>
