function getTrainings(start = 0, state = undefined, machine_id = undefined, user_id = undefined) {
  return window.$http({
    url: '/api/v1/training/trainings',
    method: 'get',
    params: {
      start,
      state,
      machine_id,
      user_id,
    },
  });
}

export default getTrainings;
