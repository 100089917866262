<template>
  <div>
  <v-row class="dashboard">
    <v-col class="mt-14" cols="12">
      <h2>Dashboard</h2>
    </v-col>
    <v-col class="" cols="12">
      <h3>{{ $t('dashboard.applications') }}</h3>
    </v-col>
    <v-col cols="12" sm="6" md="4" lg="4" :style="$vuetify.breakpoint.xl ? ' flex: 1 0 18%;' : ''"
      v-for="(cardItemFrame, index) in cardItemFrames" :key="index">
      <DashboardCard v-if="cardItems[`${cardItemFrame}`]"
                     :iconsvg="cardItems[`${cardItemFrame}`].iconsvg"
                     :title="cardItems[`${cardItemFrame}`].title"
                     :description="cardItems[`${cardItemFrame}`].description"
                     :route="cardItems[`${cardItemFrame}`].route"
                     :disabled="cardItems[`${cardItemFrame}`].disabled"
      />
      <DashboardCard v-else
                     :layout="'free'"
                     :iconsvg="''"
                     :title="''"
                     :description="''"
                     :route="''"
      />
    </v-col>
  </v-row>
  <v-row class="dashboard">
    <v-col class="mt-2" cols="12">
  <h3>{{ $t('dashboard.reports') }}</h3>
</v-col>
  <v-col cols="12" md="6" class="d-grid">
    <div  class="box-card elevation-3">
      <div class="box-card__header">
        Statistics
      </div>
      <div class="pt-11 pb-13">
        <customer-user-statistics/>
      </div>
    </div>
  </v-col>
  <v-col v-if="this.$store.getters['user/canStartTrainings']" cols="12" md="6" class="d-grid">
    <div  class="box-card elevation-3 d-table">
      <div class="box-card__header d-flex justify-space-between">
        Last Results
        <v-switch
          v-model="showTestings"
          label="Toggle trainings/testings"
        ></v-switch>
      </div>
      <testing-results v-if="showTestings"/>
      <training-results v-else/>
      <div class="box-card__footer text-center">
        <v-btn class="my-3" small :to="showTestings ? '/customer/testings' : '/customer/trainings'"
               color="#CE0F69">
          Go To {{ showTestings ? 'Testings' : 'Trainings' }}
        </v-btn>
      </div>
    </div>
  </v-col>
  <v-col cols="12">
    <div class="box-card elevation-3">
      <div class="box-card__header">
        {{ $t('trainings.chart.title') }}
      </div>
      <div>
        <LineChart class="pt-5 pa-15 line-chart"
          :activeMonthNr="this.activeMonthsNr"
          :activeMonths="this.activeMonths"/>
      </div>
    </div>
  </v-col>
  </v-row>
  </div>
</template>

<script>

import DashboardCard from '@/components/DashboardCard.vue';
import LineChart from '@/components/dashboard/CustomerUser/Line.vue';
import checkTokenExpiriesDate from '@/helper/checkTokenExpiriesDate';
import CustomerUserStatistics from './CustomerUser/Statistics.vue';
import TestingResults from './CustomerUser/TestingResults.vue';
import TrainingResults from './CustomerUser/TrainingResults.vue';

export default {
  name: 'CostumerUserDashboard',
  components: {
    TestingResults,
    TrainingResults,
    CustomerUserStatistics,
    DashboardCard,
    LineChart,
  },
  data() {
    return {
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      activeMonths: [],
      activeMonthsNr: [],
      permissions: {
        canManageFilestorage: this.$store.getters['user/canManageFilestorage'] === true,
        canStartTrainings: this.$store.getters['user/canStartTrainings'] === true,
      },
      cardItemFrames: ['filemanager', 'trainings', 'aitesting', 'labellingtool', 'simulation'],
      cardItems: {
        filemanager: {
          title: 'File Manager',
          description: 'Upload and manage files for your AI trainings/testings',
          iconsvg: 'FileManager.svg',
          route: '/filemanager/list',
          disabled: true,
        },
        trainings: {
          title: 'AI Training',
          description: 'Create new trainings, check status and download results',
          iconsvg: 'AITraining.svg',
          route: '/customer/trainings',
          disabled: true,
        },
        aitesting: {
          title: 'AI Testing',
          description: 'Analyse your AI model',
          iconsvg: 'AITesting.svg',
          route: 'customer/testings',
          disabled: true,
        },
        labellingtool: {
          title: 'Labelling Tool',
          description: 'Label your images for classification',
          iconsvg: 'Labelling.svg',
          route: '/labelling',
          disabled: false,
        },
        simulation: {
          title: 'Simulation',
          description: 'Generate synthetic image data',
          iconsvg: 'Simulation.svg',
          route: '',
          disabled: true,
        },
      },
      showTestings: false,
    };
  },
  created() {
    this.checkPermissions();
    this.getLastSixMonths();
  },
  methods: {
    checkPermissions() {
      const userLogged = checkTokenExpiriesDate();
      if (userLogged) {
        Object.keys(this.cardItems).forEach((cardItem) => {
          if (cardItem === 'filemanager') {
            if (this.permissions.canManageFilestorage) {
              this.cardItems[cardItem].disabled = false;
            } else {
              this.cardItems[cardItem].route = '';
              this.cardItems[cardItem].disabled = true;
            }
          }
          if (cardItem === 'trainings' || cardItem === 'aitesting') {
            if (this.permissions.canStartTrainings) {
              this.cardItems[cardItem].disabled = false;
            } else {
              this.cardItems[cardItem].route = '';
              this.cardItems[cardItem].disabled = true;
            }
          }
        });
      }
    },
    getLastSixMonths() {
      const today = new Date();
      let d = 0;
      let month = 0;
      const activeMonths = [];
      let i = 6;

      for (i; i > 0; i -= 1) {
        d = new Date(today.getFullYear(), today.getMonth() - i, 1);
        month = this.months[d.getMonth()];
        activeMonths.push(month);
        this.activeMonthsNr.push(d.getMonth() + 1);
      }
      // add active months
      activeMonths.push(this.months[today.getMonth()]);
      this.activeMonthsNr.push(today.getMonth() + 1);
      this.activeMonths = activeMonths;
    },
  },
};
</script>
<style lang="scss">
.line-chart div {
  max-height: 40vh;
}
.dashboard .box-card__header .v-input--switch {
  margin: 0;
  .v-input__control {
    .v-input__slot {
      margin: 0;
    }
    .v-messages {
      display: none;
    }
  }
  &.v-input--selection-controls {
    padding-top: 0 !important;
  }
}
</style>
