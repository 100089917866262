<template>
  <div>
    <v-container>
      <v-row>
        <v-col class="mt-14" cols="12">
          <h2>Labelling</h2>
        </v-col>
      </v-row>
    </v-container>
    <keep-alive exclude="LabellingTool">
      <LabellingConfig v-if="!config_valid" v-on:config-saved="config_valid = true;">
      </LabellingConfig>
      <LabellingTool v-else>
      </LabellingTool>
    </keep-alive>
    <DialogConfirm ref="confirm"/>
  </div>
</template>

<script>

import LabellingConfig from '@/components/labelling/LabellingConfig.vue';
import LabellingTool from '@/components/labelling/LabellingTool.vue';
import DialogConfirm from '@/components/DialogConfirm.vue';

export default {
  name: 'LabellingPage',
  components: {
    LabellingConfig,
    LabellingTool,
    DialogConfirm,
  },
  data() {
    return {
      config_valid: false,
      labels_unchanged: false,
    };
  },
  beforeMount() {
    this.config_valid = false;
    this.labels_unchanged = true;
  },
  mounted() {
    this.$root.$on('labels-changed', () => {
      this.labels_unchanged = false;
    });
    this.$root.$on('labels-unchanged', () => {
      this.labels_unchanged = true;
    });
    this.$root.$on('labels-saved', () => {
      this.labels_unchanged = true;
    });
    this.$root.$on('back-to-config', () => {
      if (this.config_valid && !this.labels_unchanged) {
        this.$refs.confirm.open('Unsaved Changes',
          'Do you really want to leave? You have unsaved changes!')
          .then((confirm) => {
            if (confirm) {
              this.config_valid = false;
              this.labels_unchanged = true;
            }
          });
      } else {
        this.config_valid = false;
        this.labels_unchanged = true;
      }
    });
    this.$root.$on('labels-overlapped', () => {
      this.$refs.confirm.open('Overlapped Labels',
        'You cannot save your changes, because you have overlapped labels!');
    });
    this.$root.$on('labels-outside-of-canvas', () => {
      this.$refs.confirm.open('Invalid Labels',
        'You cannot save your changes, because you have labels outside of the canvas!');
    });
    this.$root.$on('class-names-duplicated', () => {
      this.$refs.confirm.open('Duplicate Name',
        'You have entered a class name that already exists in the label map. Only unique names are allowed.');
    });
    this.$root.$on('empty-class-name', () => {
      this.$refs.confirm.open('No Class Name',
        'Please enter a class name.');
    });
    /* this.$root.$on('direct-to-labelling', () => {
      this.config_valid = true;
    }); */
    window.addEventListener('beforeunload', (e) => {
      if (this.config_valid && !this.labels_unchanged) {
        e.preventDefault();
        e.returnValue = '';
      }
    });
  },
  beforeDestroy() {
    this.$root.$off('back-to-config');
    this.$root.$off('labels-overlapped');
    this.$root.$off('labels-outside-of-canvas');
    this.$root.$off('class-names-duplicated');
    this.$root.$off('empty-class-name');
  },
  computed: {
  },
  beforeRouteLeave(to, from, next) {
    if (this.config_valid && !this.labels_unchanged) {
      this.$refs.confirm.open('Unsaved Changes',
        'Do you really want to leave? You have unsaved changes!')
        .then((confirm) => {
          if (confirm) {
            this.config_valid = false;
            this.labels_unchanged = true;
            next();
          } else {
            next(false);
          }
        });
    } else {
      next();
    }
  },
};
</script>

<style lang="scss"></style>
