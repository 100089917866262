function updateUser(user) {
  const { id } = user;
  const data = {
    firstname: user.firstname,
    lastname: user.lastname,
    mobile_number: user.mobile_number,
    is_active: user.isActive,
    can_start_trainings: user.can_start_trainings,
    can_manage_filestorage: user.can_manage_filestorage,
  };
  return window.$http({
    url: `/api/v1/customer/user/${id}`,
    method: 'put',
    data,
  });
}

export default updateUser;
