function deleteTesting(testing) {
  console.log('deleteTesting', testing);
  const { id } = testing;
  return window.$http({
    url: `/api/v1/testing/${id}`,
    method: 'delete',
  });
}

export default deleteTesting;
