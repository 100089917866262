<template>
  <div>
    <v-row class="justify-start dashboard admin">
      <v-col class="mt-14" cols="12">
        <h2>Dashboard</h2>
      </v-col>
      <v-col cols="12" md="6">
        <div class="box-card elevation-3">
          <div class="box-card__header">
            General
          </div>
          <div class="pt-6 pb-7">
            <general-statistics/>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div class="box-card elevation-3">
          <div class="box-card__header">
            Trainings
          </div>
          <div class="pt-6 pb-7">
            <training-statistics/>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
          <div class="box-card elevation-3">
            <div class="box-card__header">
              {{ $t('dashboard.charts.supervisor.usageChart.title') }}
            </div>
            <TrainingLineChart class="pt-5 pa-15 line-chart" :companies="companies" />
          </div>
      </v-col>
      <v-col cols="12">
          <div class="box-card elevation-3">
            <div class="box-card__header">
              {{ $t('dashboard.charts.supervisor.instanceChart.title') }}
            </div>
            <InstanceLine class="pt-5 pa-15 line-chart" />
          </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import TrainingLineChart from '@/components/dashboard/Admin/TrainingsLine.vue';
import InstanceLine from '@/components/dashboard/Admin/InstanceLine.vue';
import GeneralStatistics from './Admin/GeneralStatistics.vue';
import TrainingStatistics from './Admin/TrainingStatistics.vue';

export default {
  name: 'CostumerUserDashboard',
  components: {
    GeneralStatistics,
    TrainingStatistics,
    TrainingLineChart,
    InstanceLine,
  },
  data() {
    return {
      chartData: {},
      isInstanceData: true,
      companies: [],
    };
  },
  created() {
    this.currentUserIsAdmin = this.$store.getters['user/isAdmin'];
  },
  methods: {
  },
};
</script>
<style lang="scss">
.dashboard {
  .box-card__footer {
    border-top: 1px solid rgba(169, 169, 169, 0.6);
  }
}
</style>
