<template>
  <v-container>
    <v-row class="justify-center">
      <v-col class="mt-16" cols="10" sm="6">
        <h1 class="text-center">Which customer account would you like to be active on?</h1>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col class="mt-16" cols="10" sm="6">
        <h2 class="text-center">Please select a company:</h2>
      </v-col>
    </v-row>
    <v-form @submit.prevent="onSubmit" ref="form">
      <v-row class="justify-center">
        <v-col cols="6">
          <div class="select-wrapper">
            <v-select
              v-model="selectedCompany"
              :items="companies"
              item-text="company.name"
              item-value="company.id"
              label="Select a company"
              :rules="rules.companyOk"
              outlined
            ></v-select>
          </div>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col class="text-right" cols="10" sm="6">
          <v-btn type="submit">Continue</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>

export default {
  name: 'CustomerUserPage',
  data() {
    return {
      selectedCompany: null,
      companies: [],
      currentUserIsAdmin: false,
      rules: {
        companyOk: [(c) => c !== null || 'Field is required'],
      },
    };
  },
  created() {
    const userState = this.$store.getters['user/userState'];
    this.currentUserIsAdmin = userState.isAdmin;
    this.companies = userState.companies;
    if (this.currentUserIsAdmin) {
      this.companies = [{ company: { name: 'Admin', id: 0 } }, ...this.companies];
    }
    console.log('companies', this.companies);
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    onSubmit() {
      if (!this.$refs.form.validate()) return;

      this.$store.dispatch('dialogloader/show', 'The user is being active on the selected customer account ...');

      this.$store.dispatch('user/companyUser', this.selectedCompany)
        .then(() => {
          this.$router.push('/dashboard');
        })
        .finally(() => this.$store.dispatch('dialogloader/hide'))
        .catch(() => {
          this.$store.dispatch('notificationbar/showNotification', {
            msg: 'Network Error: Server is not responding.',
            color: 'error',
            show: true,
          });
          this.$router.push('/logout');
        });
    },
  },
};
</script>
