<template>
  <div>
    <v-row class="justify-center my-auto">
      <v-col cols="3" class="text-center">
        <v-progress-circular
          :rotate="-90"
          :size="100"
          :width="3"
          :value="100"
          :indeterminate="loadingActiveCompanies"
          color="black"
        >
          <div class="value">{{activeCompanies}}</div>
        </v-progress-circular>
        <div class="circle-label pt-7">Companies</div>
      </v-col>
      <v-col cols="3" class="text-center">
        <v-progress-circular
          :rotate="-90"
          :size="100"
          :width="3"
          :value="100"
          :indeterminate="loadingTotalUsers"
          color="black"
        >
          <div class="value">{{activeUsers}}</div>
        </v-progress-circular>
        <div class="circle-label pt-7">Users</div>
      </v-col>
      <v-col cols="3" class="text-center">
        <v-progress-circular
          :rotate="-90"
          :size="100"
          :width="3"
          :value="100"
          :indeterminate="loadingInstances"
          color="black"
        >
          <div class="value">{{totalInstances}}</div>
        </v-progress-circular>
        <div class="circle-label pt-7">Instances</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import getAllUsers from '@/api/admin/customerUser/getAllUsers';
import getAllCompanies from '@/api/admin/company/getAllCompanies';
import getInstances from '@/api/aws/getInstances';

export default {
  name: 'AdminGeneralStatistics',
  data() {
    return {
      activeCompanies: 0,
      loadingActiveCompanies: true,
      activeUsers: 0,
      loadingTotalUsers: true,
      totalInstances: 0,
      loadingInstances: true,
    };
  },
  created() {
    this.fetchUsers();
    this.fetchActiveCompanies();
    this.fetchTotalInstances();
  },
  methods: {
    fetchActiveCompanies() {
      getAllCompanies()
        .then((response) => {
          const companiesArray = response.data.data;
          let activeCompaniesCount = 0;
          companiesArray.forEach((company) => {
            if (company.deactivated_at === null) {
              activeCompaniesCount += 1;
            }
          });
          this.activeCompanies = activeCompaniesCount;
        })
        .finally(() => {
          this.loadingActiveCompanies = false;
        });
    },
    fetchUsers() {
      getAllUsers()
        .then((response) => {
          const userArr = response.data.data;
          let activeUsersCount = 0;
          userArr.forEach((user) => {
            if (user.user.is_active === true) {
              activeUsersCount += 1;
            }
          });
          this.activeUsers = activeUsersCount;
        })
        .finally(() => {
          this.loadingTotalUsers = false;
        });
    },
    fetchTotalInstances() {
      getInstances()
        .then((response) => {
          this.totalInstances = response.data.length;
        })
        .finally(() => {
          this.loadingInstances = false;
        });
    },
  },
};
</script>
<style lang="scss">
.dashboard.admin .v-progress-circular__info {
  font-size: 25px;
}
</style>
