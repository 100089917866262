function getTestings(start = undefined, state = undefined, machine_id = undefined,
  user_id = undefined) {
  return window.$http({
    url: '/api/v1/testing/testings',
    method: 'get',
    params: {
      start,
      state,
      machine_id,
      user_id,
    },
  });
}

export default getTestings;
