<template>
  <v-row class="justify-start">
    <v-col cols="12" sm="6">
    <v-select
      label="Select machine (path)"
      v-model="selectedItemId"
      v-on:change="activate()"
      :items="data"
      itemText="title"
      itemValue="id"
      :rules="$rules.required"
    ></v-select>
    </v-col>
  </v-row>
</template>

<script>
import getMachines from '@/api/user/getMachines';

export default {
  name: 'Machines',
  data() {
    return {
      data: [],
      loading: true,
      totalItems: 0,
      selectedItemId: 0,
    };
  },
  props: {
    selected: {
      type: Number,
      default: 0,
    },
  },
  created() {
    this.fetchData();
    this.selectedItemId = this.selected;
  },
  methods: {
    fetchData() {
      this.loading = true;
      getMachines()
        .then((response) => {
          this.data = response.data;
          this.totalItems = response.data.length;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    activate() {
      const machine = this.data.find((e) => e.id === this.selectedItemId);
      this.$emit('input', machine);
    },
  },
};
</script>
