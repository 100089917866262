function editTraining(training) {
  const { id } = training;
  const data = {
    title: training.title,
    description: training.description,
    internal_identifier: training.internalIdentifier,
    steps: training.steps,
    learning_rate: training.learningRate,
    machine_id: training.machineId,
    training_model_id: training.training_model.id,
    dataset_directory: training.datasetDirectory,
    augmentations: training.augmentations,
  };
  console.log('data', data, training);
  return window.$http({
    url: `/api/v1/training/${id}`,
    method: 'put',
    data,
  });
}

export default editTraining;
