function sendInvitation(user, permissions) {
  console.log('sendInvitation', user);
  const temp = {
    can_manage_filestorage: permissions.includes('can_manage_filestorage'),
    can_start_trainings: permissions.includes('can_start_trainings'),
  };
  const data = {
    email: user.email,
    permissions: temp,
  };

  return window.$http({
    url: '/api/v1/customer/send-invitation',
    method: 'post',
    data,
  });
}

export default sendInvitation;
