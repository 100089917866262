<template>
  <div>
    <v-container>
      <v-row>
        <v-col class="mt-14" cols="12">
              <h2>Testings</h2>
        </v-col>
      </v-row>
    </v-container>
    <Testings />
  </div>
</template>

<script>

import Testings from '@/components/testings/Testings.vue';

export default {
  name: 'TestingsPage',
  components: {
    Testings,
  },
};
</script>
<style lang="scss">

</style>
