<template>
  <v-row class="ma-0">
    <v-expansion-panel>
      <v-col class="testing py-0" cols="12" :data-id="testing.testing.id"
       :class="testing.testing.id">
        <v-row class="">
          <v-col class="testing__firstcol pl-0 " md="7" sm="5">
            <v-row class="">
              <v-col class="py-0" md="8" sm="12">
                <div class="testing__date">
                  {{ format_date(testing.testing.created_at) }}
                </div>
                <div class="testing__title notranslate" translate="no">
                  <div v-if="!testing.testing.title">unknown</div>
                  <div v-else>{{ testing.testing.title }}</div>
                </div>
                <div class="testing__creator">
                  {{ testing.testing.company_user.user.firstname }}
                  {{ testing.testing.company_user.user.lastname }}
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="" md="3" sm="4">
            <v-row class="testing__actions">
                <v-col md="5" sm="5">
                <div class="dataset"
                     v-if="checkErrorsWarnings(testing.testing.errors_and_warnings)
                  || testing.testing.state === 'defective'"
                        :class="{
                    'warning-dataset': (testing.testing.state === 'created' &&
                    testing.testing.errors_and_warnings.warnings ?
                    testing.testing.errors_and_warnings.warnings.length : 0 )
                    || testing.testing.state === 'defective',
                    'error-dataset': testing.testing.errors_and_warnings.errors ?
                    testing.testing.errors_and_warnings.errors.length : 0
                  }">
                <v-icon class="mr-1">
                  mdi-alert
                </v-icon>
                <span v-if="testing.testing.state !== 'error'">Dataset</span>
              </div>
              </v-col>
              <v-col md="7" sm="7">
                <v-btn class="start-testing-btn" small @click="onStart(testing)"
                     :disabled="testing.testing.state === 'defective' || exceeded_resources"
                     v-if="testing.testing.state === 'created'
                     || testing.testing.state === 'defective'">
                <v-icon>mdi-play</v-icon>
                Start
              </v-btn>
              <v-btn class="start-download-btn" small v-if="testing.testing.state === 'finished'
                && testing.testing.is_exported === true" @click="onDownloadResultTesting(testing)"
                     color="#21BA45">
                <v-icon>mdi-download</v-icon>
                Download
              </v-btn>
              <div class="testing__info-spinner"
                   v-if="testing.testing.state === 'started'
                   && testing.testing.started_at === null">
                <v-icon class="mdi-loading">mdi-spin</v-icon>
                Initialising<br>Testing
              </div>
              <div class="testing__info-spinner ml-lg-15"
                v-if="testing.testing.state === 'finished' &&
                testing.testing.is_exported === false">
                <v-icon class="mdi-loading">mdi-spin</v-icon>
                Preparing<br>Download
              </div>
          </v-col>
          </v-row>
          </v-col>

          <v-col class="ma-0 pa-3 testing__status" md="2" sm="3">
            <div class="testing__status--msg" v-bind:class="this.statusMsgClass(testing)"
            >
              {{ getTestingStatus }}
              <div class="testing__date" v-if="testing.testing.state === 'started'
                && testing.testing.started_at">
                {{ format_date(testing.testing.started_at) }}
              </div>
              <div class="testing__date" v-else-if="testing.testing.state === 'finished'">
                {{ format_date(testing.testing.finished_at) }}
              </div>
            </div>
            <v-menu offset-y min-width="200px" nudge-left="20px" left>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  :ripple="true"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-card class="testing__dots-vertical">
                <v-list>
                  <template
                    v-for="(item, index) in actionTesting"
                  >
                    <v-list-item
                      :disabled="!item.state.includes(testing.testing.state)"
                      :title="item.name"
                      :ripple="true"
                      :key="item.key"
                      class="mt-1"
                    >
                      <v-list-item-content
                        @click="actionTestingFunc(item.key, testing, index)"
                      >
                        <v-list-item-title>
                          {{ item.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                </v-list>
              </v-card>
            </v-menu>
            <v-expansion-panel-header class="accordion-btn">
            </v-expansion-panel-header>
          </v-col>
        </v-row>
      </v-col>
      <div v-if="testing.testing.state === 'running'" class="mt-3">
        <div v-if="testing.testing.state === 'running'"
             class="testing__progress--linear">
          <v-progress-linear
            :value="progressPercent"
            color="transparent"
            height="25"
          >
            <template v-slot:default="{ value }">
              <strong class="progess-percent">{{ Math.ceil(value) }}%</strong>
            </template>
          </v-progress-linear>
          <v-progress-linear :value="progressPercent"
                             color="#21BA45"
                             height="6"
                             reverse
                             buffer-value="0"
                             absolute bottom stream
          />
        </div>
      </div>
      <v-expansion-panel-content>
        <v-row class="ma-0">
          <v-col class="pb-0 ps-0" cols="12" sm="6">
            <v-row class="">
              <v-col class="pt-0" md="10" sm="10">
                {{ testing.testing.description }}
              </v-col>
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Internal ID: </span>
                <span>
                  {{ testing.testing.internal_identifier }}
                </span>
                <hr class="mt-5"/>
              </v-col>
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Machine: </span>
                <span>{{ testing.training.machine_title }}</span>
              </v-col>
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Data directory:&nbsp;</span>
                <span class="notranslate" translate="no">
                  {{ testing.testing.dataset_directory }}
                </span>
              </v-col>
<!-- uncomment when BE is ready -->
<!--              <v-col class="" md="10" sm="10">-->
<!--                <span class="font-weight-bold">Number of files: </span>-->
<!--                <span>{{ testing.testing.count_of_files }}</span>-->
<!--              </v-col>-->
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Model (Training): </span>
                <span>
                  {{ testing.training.title }}
                </span>
              </v-col>
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Testmode: </span>
                <span>{{ testing.testing.mode }}</span>
              </v-col>
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Creator: </span>
                <span>
                  {{ testing.testing.company_user.user.firstname }}
                  {{ testing.testing.company_user.user.lastname }}
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pb-0 mb-5" cols="12" sm="6">
            <v-row class="info-box">
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Status: </span>
                <span class="state" :class="testing.testing.state">
                    {{ getTestingStatus }}</span>
              </v-col>
              <v-col class="" md="10" sm="10"
                     v-if="testing.testing.state === 'running' ||
                     testing.testing.state === 'finished'">
                <span class="font-weight-bold">Elapsed time: </span>
                <span>{{ getElapsedTime }}</span>
              </v-col>
              <v-col class="" md="10" sm="10" v-if="testing.testing.state === 'running'">
                <span class="font-weight-bold">Estimated time: </span>
                <span>{{ getEstimatedTime }}</span>
              </v-col>
              <v-col class="" md="10" sm="10"
                     v-if="testing.testing.state === 'running' ||
                     testing.testing.state === 'finished'">
                <span class="font-weight-bold">Starting date: </span>
                <span>{{ format_date(testing.testing.started_at, true, true) }}</span>
              </v-col>
              <v-col class="" md="10" sm="10" v-if="testing.testing.state === 'finished'">
                <span class="font-weight-bold">Finishing date: </span>
                <span>{{ format_date(testing.testing.finished_at, true, true) }}</span>
              </v-col>
              <v-col class="" md="10" sm="10">
                <div class="d-flex align-center mb-3">
                  <span class="font-weight-bold mr-3">Dataset validation: </span>
                  <span
                    class="d-flex align-center">
                    <span v-if="(testing.testing.errors_and_warnings.errors &&
                      testing.testing.errors_and_warnings.errors.length)
                      || testing.testing.state === 'defective'"
                          class="error-message mr-3">
                           fail
                    </span>
                    <span v-else class="ok-message mr-3">
                           ok
                    </span>
                    <v-btn
                      v-if="testing.testing.state === 'defective'
                      || testing.testing.state === 'created'"
                      class=""
                      small @click="retryValidation(testing)">
                              <v-icon>mdi-refresh</v-icon>
                              Retry
                    </v-btn>
                  </span>
                </div>
                <v-row v-if="testing.testing.errors_and_warnings.errors">
                  <v-col class="error-message pb-0" cols="12"
                         v-for="(error, index) in testing.testing.errors_and_warnings.errors"
                         :key="index">
                    <p>... {{ error.message }}</p>
                    <template v-if="error.data && error.data.length > 0">
                      <v-row
                        v-if="typeof error.data === 'string' || error.data instanceof String">
                        <v-col class="error-message py-0 pl-5" cols="12">
                          <v-icon
                            right
                            class="error-message"
                          >mdi-arrow-right-thin
                          </v-icon>
                          {{ error.data }}
                        </v-col>
                      </v-row>
                      <v-row class="pl-5"
                             v-else>
                        <v-col class="error-message py-0 pl-5" cols="12"
                               v-for="(dataItem, index) in error.data"
                               :key="index">
                          <v-icon
                            right
                            class="error-message"
                          >mdi-arrow-right-thin
                          </v-icon>
                          {{ dataItem }}
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>
                </v-row>

                <v-row v-if="testing.testing.errors_and_warnings.warnings
                  && testing.testing.errors_and_warnings.warnings.length > 0">
                  <v-col class="warning-message pb-0" cols="12"
                         v-for="(warning, index) in testing.testing.errors_and_warnings.warnings"
                         :key="index">
                    <p>... {{ warning.message }}</p>
                    <v-row class="pl-5" v-if="warning.data && warning.data.length > 0">
                      <v-col class="warnings-message py-0 pl-5" cols="12"
                             v-for="(dataItem, index) in warning.data"
                             :key="index">
                        <v-icon
                          right
                          class="warnings-message"
                        >mdi-arrow-right-thin
                        </v-icon>
                        {{ dataItem }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="" cols="12">
                <hr class="mt-5" v-if="testing.testing.state === 'started'"/>
              </v-col>
              <v-col class="mt-auto p-2" md="10" sm="10"
                  v-if="testing.testing.state === 'started' ||
                  testing.testing.state === 'running'">
                  <v-btn class="btn-as-link"
                         @click="onCancel(testing.testing)">
                    cancel training
                  </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-row>
</template>

<script>

import moment from 'moment';

export default {
  name: 'Testing',
  components: {

  },
  props: {
    testing: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
    },
    exceeded_resources: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: 0,
      actionTesting: [
        { name: 'Clone', key: 'clone', state: ['created', 'defective', 'started', 'running', 'aborted', 'finished', 'error', 'failed', 'unknown'] },
        { name: 'Edit', key: 'edit', state: ['created', 'defective'] },
        { name: 'Cancel', key: 'cancel', state: ['started', 'running'] },
        { name: 'Delete', key: 'delete', state: ['created', 'defective'] },
      ],
    };
  },
  mounted() {
    if (this.testing.job && this.testing.job.progress) {
      this.value = `${Math.round((this.testing.progress) * 1000) / 10}`;
    }
  },
  methods: {
    statusMsgClass(testing) {
      return `training__status--msg-${testing.testing.state}`;
    },
    checkErrorsWarnings(items) {
      return !!((items.errors && items.errors.length)
        || (items.warnings && items.warnings.length));
    },
    retryValidation(testing) {
      this.$emit('retry', testing.testing);
    },
    onDelete(testing) {
      this.$emit('delete', testing);
    },
    onCancel(testing) {
      this.$emit('cancel', testing);
    },
    onStart(testing) {
      this.$emit('start', testing);
    },
    onDownloadResultTesting(testing) {
      this.$emit('download', testing);
    },
    formatPercentFromFactor(factor) {
      return Math.ceil(factor * 100);
    },
    panelClicked() {
      this.$emit('panelClicked', this.id);
    },
    getDuration(d2, d1) {
      const diff = d1.getTime() - d2.getTime();
      return {
        getDays() {
          return Math.floor((diff / (1000 * 60 * 60 * 24)));
        },
        getHours() {
          return Math.floor(((diff - this.getDays() * 1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
        },
        getMinutes() {
          return Math.floor(((diff - (this.getDays() * 1000 * 60 * 60 * 24
            + this.getHours() * 1000 * 60 * 60))
            / (1000 * 60)));
        },
        toString() {
          const days = this.getDays() > 0 ? `${this.getDays()} days` : '';
          const hours = this.getHours() > 0 ? `${this.getHours()} hours` : '';
          const minutes = this.getMinutes() > 0 ? `${this.getMinutes()} minutes` : '';
          return `${days} ${hours} ${minutes}`;
        },
      };
    },
    actionTestingFunc(key, testing, index) {
      // eslint-disable-next-line
      const id = { id: testing.testing.id };

      switch (key) {
        case 'clone':
          this.$emit('clone', testing);
          break;
        case 'edit':
          this.$emit('edit', testing, index);
          break;
        case 'cancel':
          this.onCancel(testing.testing);
          break;
        case 'delete':
          this.onDelete(testing.testing);
          break;
        default:
      }
    },
  },
  computed: {
    progressPercent() {
      return Math.round((this.testing.testing.progress) * 1000) / 10;
    },
    getElapsedTime() {
      if (this.testing.testing.elapsed_minutes === 0) {
        return '-';
      }
      const remainingMinutes = this.testing.testing.elapsed_minutes % (24 * 60);
      const hours = Math.floor(remainingMinutes / 60);
      const minutes = Math.floor(remainingMinutes % 60);

      return `${hours > 0 ? `${hours} hour(s)` : ''} ${minutes} minute(s)`;
    },
    getEstimatedTime() {
      if (this.testing.testing.remaining_minutes_estimate > 0) {
        const d1 = moment.unix(0).local().toDate();
        const d2 = moment.unix(Math.round(this.testing.testing.remaining_minutes_estimate) * 60)
          .local().toDate();
        return `approx. ${this.getDuration(d1, d2).toString()}`;
      }
      if (
        this.testing.testing.remaining_minutes_estimate === 0
        || this.testing.testing.remaining_minutes_estimate === 0.0
      ) {
        return 'approx. 1 minute';
      }
      return '-';
    },
    getTestingStatus() {
      if (this.testing.testing.state === 'defective') {
        return 'Created';
      }
      if (this.testing.testing.state === 'created') {
        return 'Ready';
      }
      return this.testing.testing.state;
    },
  },
};
</script>

<style lang="scss">
.v-list {
  .v-list-item {
    cursor: pointer;
  }
}
</style>
