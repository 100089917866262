function createTesting(testing) {
  console.log('createTesting', testing);
  const data = {
    title: testing.title,
    description: testing.description,
    internal_identifier: testing.internalIdentifier,
    training_id: testing.training.id,
    dataset_directory: testing.datasetDirectory,
    mode: testing.testingMode.name.toLowerCase(),
  };
  return window.$http({
    url: '/api/v1/testing/create',
    method: 'post',
    data,
  });
}

export default createTesting;
