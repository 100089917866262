<template>
  <div>
    <v-row>
      <v-col class="pt-3 pb-7" cols="12" :align="'center'">
        <v-select dense outlined flat hide-details
          prefix="Page"
          :items="list_pages"
          v-model="active_page"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-list class="pl-4 pr-4 pt-4 pb-4" style="border-radius: 5px;
      box-shadow: inset 0 0 7px #bdbfc0; min-height: 439px;" dense>
      <v-list-item v-for="image of sliced_images" @click="$emit('image-click', image)"
        v-bind:key="image" :value="image" :selected="loaded_image == image" :title="image"
        :style="style_object(image)"
        class="pl-4 pr-2">
        <span>
          {{ shortened_image(image, 32) }}
          <v-icon>
            {{ image_has_label(image) ? 'mdi-check' : 'mdi-close' }}
          </v-icon>
        </span>
      </v-list-item>
    </v-list>
    <v-container class="pt-4 pl-0 pr-0 pb-0">
      <v-row>
        <v-col class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-3" style="text-align: left;">
          <v-btn color="primary"
            @click="onImagePreviousButton()"
            :disabled="active_page == 1"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
        <v-col class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-6">
          <v-row justify="center">
            <v-switch
              class="image-list-switch"
              dense
              v-model="show_unlabelled_images"
              @change="onChangeShowUnlabelled()"
            >
              <template v-slot:label>
                <span style="font-size: 13px">Unlabelled Images</span>
              </template>
            </v-switch>
          </v-row>
        </v-col>
        <v-col class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-3" style="text-align: right;">
          <v-btn color="primary"
            @click="onImageNextButton()"
            :disabled="active_page > list_pages.length - 1"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped></style>

<script>
export default {
  props: {
    dataset: {
      type: Object,
      default() { return {}; },
    },
    loaded_image: {
      type: String,
      default: '',
    },
    per_page: {
      type: Number,
      required: true,
    },
    pages: {
      type: Array,
      default: () => [1],
    },
    unlabelled_images: {
      type: Array,
      default() { return []; },
    },
  },
  data() {
    return {
      active_page: 1,
      images: [],
      list_pages: [],
      show_unlabelled_images: false,
    };
  },
  beforeMount() {
  },
  mounted() {
    this.list_pages = this.pages;
    this.images = this.dataset.images;
  },
  computed: {
    sliced_images() {
      return this.images.filter((value, index) => {
        const min = (this.active_page - 1) * this.per_page;
        const max = (this.active_page) * this.per_page;
        if (index >= min && index < max) {
          return true;
        }
        return false;
      });
    },
  },
  methods: {
    update_active_page(page) {
      this.active_page = page;
    },
    shortened_image(image, max) {
      let shortname = image.substring(0, max - 3);
      if (image.length > max) {
        shortname += '...';
      }
      return shortname;
    },
    new_ext(s, ext) {
      const pos = s.includes('.') ? s.lastIndexOf('.') : s.length;
      const fileName = s.substr(0, pos);
      const output = `${fileName}${(ext.startsWith('.') ? ext : `.${ext}`)}`;
      return output;
    },
    image_has_label(imageName) {
      return this.dataset.labels.includes(this.new_ext(imageName, 'json'));
    },
    style_object(image) {
      const selected = this.loaded_image === image;
      // const doneColor = '#80FF80';
      // const notDoneColor = '#FF8080';
      const doneColor = '#ffffff';
      const notDoneColor = '#ffffff';
      if (selected) {
        return {
          // 'background-color': this.image_has_label(image) ? doneColor : notDoneColor,
          'background-color': '#f2f2f2',
          'border-radius': '5px',
          border: '1px solid #cccccc',
        };
      }
      return {
        'background-color': this.image_has_label(image) ? doneColor : notDoneColor,
        'border-radius': '5px',
      };
    },
    onChangeShowUnlabelled() {
      this.$root.$emit('unlabelled-images-switched', this.show_unlabelled_images);
      if (this.show_unlabelled_images) {
        this.images = this.unlabelled_images;
        const pagesArray = [];
        const num = Math.ceil(this.images.length / this.per_page);
        for (let i = 1; i <= num; i += 1) {
          pagesArray.push(i);
        }
        this.list_pages = pagesArray;
      } else {
        this.images = this.dataset.images;
        this.list_pages = this.pages;
      }
    },
    onImagePreviousButton() {
      if (this.active_page > 1) {
        this.active_page -= 1;
      }
    },
    onImageNextButton() {
      if (this.active_page < this.list_pages.length) {
        this.active_page += 1;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.mdi-check {
  color: #21ba45;
}
.mdi-close {
  color: red;
}
.image-list-switch {
  padding-top: 0px;
}
</style>
