function getTrainingMinutesMonthly(year, companyId) {
  const params = { year };
  if (companyId) {
    params.company_id = companyId;
  }

  return window.$http({
    url: '/api/v1/admin/dashboard/training-minutes-monthly',
    method: 'get',
    params,
  });
}

export default getTrainingMinutesMonthly;
