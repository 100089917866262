function startTesting(testing) {
  console.log('startTesting', testing);
  const { id } = testing;
  return window.$http({
    url: `/api/v1/testing/start/${id}`,
    method: 'put',
  });
}

export default startTesting;
