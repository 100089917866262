function changePassword(fields) {
  const data = {
    password_old: fields.password_old,
    password_new: fields.password_new,
    password_repeat: fields.password_repeat,
    pin: fields.pin,
  };
  return window.$http({
    url: '/api/v1/general/change-password',
    method: 'put',
    data,
  });
}

export default changePassword;
