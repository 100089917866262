<template>
  <div>
    <div v-if="loading" class="d-flex justify-center">
      <v-progress-circular
        indeterminate
        size="25"
        width="2"
        color="primary"
      ></v-progress-circular>
    </div>
    <LineChartGenerator
      v-show="!loading"
      ref="lineChart"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </div>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from 'chart.js';

import getInstances from '@/api/aws/getInstances';
import getInstanceWorkload from '@/api/admin/statistics/getInstanceWorkload';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
);

export default {
  name: 'AdminInstanceLineChart',
  components: {
    LineChartGenerator,
  },
  props: {
    chartId: {
      type: String,
      default: 'admin-instance-line-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: true,
      values: [],
      date: new Date(),
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Running Instances',
            backgroundColor: '#CE0F69',
            data: [],
          },
          {
            label: 'AWS Instances',
            backgroundColor: '#21BA45',
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              stepSize: 1,
              callback(value) {
                return Math.round(value);
              },
            },
            beginAtZero: true,
          },
          x: {
            ticks: {
              maxTicksLimit: 144,
              autoSkip: false,
              maxRotation: 0,
            },
            grid: {
              display: true,
              drawOnChartArea: true,
              drawTicks: true,
            },
          },
        },
      },
      totalInstanceData: [],
      instanceRefreshIntervalId: null,
    };
  },
  mounted() {
    this.getChartData();
    this.instanceRefreshIntervalId = setInterval(() => {
      this.getChartData();
    }, 120000);
  },
  beforeDestroy() {
    clearInterval(this.instanceRefreshIntervalId);
  },
  methods: {
    async getChartData() {
      this.loading = true;
      const labels = [];
      getInstances().then((response) => {
        const instanceData = response.data;
        const totalInstanceData = Array(144).fill(instanceData.length);
        this.totalInstanceData = totalInstanceData;
        this.chartData.datasets[1].data = totalInstanceData;
      });
      const currentMonth = this.date.getMonth() + 1;
      const currentYear = this.date.getFullYear();
      getInstanceWorkload(currentMonth, currentYear).then((response) => {
        const { data } = response;
        const chartData = Array.from({ length: 144 }, (_, i) => {
          const currentTime = Date.now() - 12 * 60 * 60 * 1000 + i * 5 * 60 * 1000;
          const time = new Date(Math.round(currentTime / (5 * 60 * 1000)) * 5 * 60 * 1000);
          const localTime = new Date(time.getTime() - time.getTimezoneOffset() * 60 * 1000);
          let formattedTime = `${localTime.toISOString().slice(0, 16)}:00`;
          formattedTime = formattedTime.replace('T', ' ');
          labels.push(formattedTime);
          // check if formattedTime is in the data object
          if (data[formattedTime]) {
            return `"${formattedTime}": ${data[formattedTime]}`;
          }
          return `"${formattedTime}": 0`;
        });
        // only set full hours as labels for the x-axis
        const adaptedLabels = labels.map((label) => {
          const date = new Date(label);
          if (date.getMinutes() === 0) {
            const browserLanguage = navigator.language;
            return date.toLocaleTimeString(browserLanguage, { hour: '2-digit', minute: '2-digit' });
          }
          return '';
        });
        // Correct the data array to match the labels array
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < chartData.length; i++) {
          if (chartData[i] === '') {
            chartData.splice(i, 1);
            // eslint-disable-next-line no-plusplus
            i--;
          }
        }
        const values = chartData.map((entry) => {
          const value = entry.split(':')[3];
          return value;
        });
        this.chartData.datasets[0].data = values;
        this.chartData.labels = adaptedLabels;
        this.loading = false;
      });
    },
  },
};
</script>
