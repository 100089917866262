function sendEmail(email) {
  console.log('sendEmail', email);
  const data = {
    subject: email.subject,
    message: email.message,
  };

  return window.$http({
    url: '/api/v1/customer/send-email',
    method: 'post',
    data,
  });
}

export default sendEmail;
