<template>
  <div class="training-statistics">
    <div class="company-select-wrapper">
      <v-select
        class="ml-2 statuses"
        style="width: 250px;"
        solo
        placeholder="Company"
        clearable
        dense
        :items="companies"
        item-value="id"
        item-text="name"
        label="Company"
        v-model="companySelected"
        @change="onCompanyfilterUpdate"
      ></v-select>
    </div>
    <v-row class="justify-center my-auto">
      <v-col cols="3" class="text-center">
        <v-progress-circular
          :rotate="-90"
          :size="100"
          :width="3"
          :value="100"
          :indeterminate="loadingTotalTrainings"
          color="#CE0F69"
        >
          <div class="value">{{ loadingTotalTrainings ? '' : totalTrainings }}</div>
        </v-progress-circular>
        <div class="circle-label pt-7">Trainings</div>
      </v-col>
      <v-col cols="3" class="text-center">
        <v-progress-circular
          :rotate="-90"
          :size="100"
          :width="3"
          :value="100"
          :indeterminate="loadingUsedSpace"
          color="#CE0F69"
        >
          <div class="value">{{ loadingUsedSpace ? '' : usedSpace }}</div>
        </v-progress-circular>
        <div class="circle-label pt-7">Storage (GB)</div>
      </v-col>
      <v-col cols="3" class="text-center">
        <v-progress-circular
          :rotate="-90"
          :size="100"
          :width="3"
          :value="100"
          :indeterminate="loadingtotalUsedMinutes"
          color="#CE0F69"
        >
          <div class="value">{{ loadingtotalUsedMinutes ? '' : totalUsedMinutes }}</div>
        </v-progress-circular>
        <div class="circle-label pt-7">Minutes</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import getAllCompanies from '@/api/admin/company/getAllCompanies';
import getStorageSpace from '@/api/aws/getStorageSpace';
import getTrainingStatistics from '@/api/admin/statistics/getTrainingTrainingStatistics';
import getCompanyUsedStorageSpace from '@/api/admin/statistics/getCompanyUsedStorageSpace';

export default {
  name: 'AdminTrainingStatistics',
  data() {
    return {
      totalTrainings: 0,
      loadingTotalTrainings: true,
      usedSpace: 0,
      loadingUsedSpace: true,
      totalUsedMinutes: 0,
      loadingtotalUsedMinutes: true,
      companies: [],
      companySelected: null,
    };
  },
  created() {
    this.fetchTotalUsedSpace();
    this.fetchAllCompanies();
    this.fetchTotalMinutesAndTrainingsAndTrainings();
  },
  methods: {
    fetchAllCompanies() {
      getAllCompanies()
        .then((response) => {
          this.companies = response.data.data;
        });
    },
    fetchTotalMinutesAndTrainingsAndTrainings() {
      getTrainingStatistics('')
        .then((response) => {
          this.totalTrainings = response.data.number;
          this.totalUsedMinutes = response.data.minutes;
        })
        .finally(() => {
          this.loadingTotalTrainings = false;
          this.loadingtotalUsedMinutes = false;
        });
    },
    fetchTotalUsedSpace() {
      getStorageSpace()
        .then((response) => {
          this.usedSpace = Math.round((response.data.size * 10) / (1024 * 1024 * 1024)) / 10;
        })
        .finally(() => {
          this.loadingUsedSpace = false;
        });
    },
    onCompanyfilterUpdate() {
      this.loading = true;
      this.loadingTotalTrainings = true;
      this.loadingUsedSpace = true;
      this.loadingtotalUsedMinutes = true;
      this.fetchCompanyStats(this.companySelected);
    },
    fetchCompanyStats(company) {
      getTrainingStatistics(company)
        .then((response) => {
          this.totalTrainings = response.data.number;
          this.totalUsedMinutes = response.data.minutes;
        })
        .finally(() => {
          this.loadingTotalTrainings = false;
          this.loadingtotalUsedMinutes = false;
        });
      if (company) {
        this.fetchCompanyUsedSpace(company);
      } else {
        this.fetchTotalUsedSpace();
      }
    },
    fetchCompanyUsedSpace(company) {
      getCompanyUsedStorageSpace(company)
        .then((response) => {
          this.usedSpace = Math.round((response.data.size * 10) / (1024 * 1024 * 1024)) / 10;
        })
        .finally(() => {
          this.loadingUsedSpace = false;
        });
    },
  },
};
</script>

<style lang="scss">
.justify-space-evenly {
  justify-content: space-evenly;
}

.training-statistics {
  position: relative;
}

.training-statistics .company-select-wrapper {
  position: absolute;
  top: -77px;
  right: 20px;
}
</style>
