<template>
  <v-container>
    <v-row class="justify-start dashboard">
      <v-col class="mt-14" cols="12">
        <h2>Profile</h2>
      </v-col>
      <v-col cols="12" md="6" class="d-grid">
        <div class="box-card elevation-1">
          <div class="box-card__header">
            <h3>My Profile</h3>
          </div>
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="data"
            disable-sort
            hide-default-footer
          >
            <template v-slot:item.value="{ item }">
              <strong>{{ item.value }}</strong>
            </template>
          </v-data-table>
          <div class="box-card__footer text-center">
            <v-dialog v-model="dialog" max-width="800px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="getProfileDataForEdit()"
                  class="my-3" small
                  color="#CE0F69">
                  {{ $t('profile.edit_button') }}
                </v-btn>
              </template>

              <v-card>
                <v-form @submit.prevent="onSaveItem" ref="form">
                  <v-toolbar dense flat>
                    <v-toolbar-title>Edit Profile</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <form-error :errors="formErrors.company">
                      <v-text-field
                        :label="$t('forms.company')"
                        v-model="editedItem.company"
                        class="mt-3"
                        :disabled="isEditing"
                      ></v-text-field>
                    </form-error>

                    <form-error :errors="formErrors.firstname">
                      <v-text-field
                        :label="$t('forms.firstname')"
                        v-model="editedItem.firstname"
                        class="mt-3"
                        :rules="$rules.required"
                      ></v-text-field>
                    </form-error>
                    <form-error :errors="formErrors.lastname">
                      <v-text-field
                        :label="$t('forms.lastname')"
                        v-model="editedItem.lastname"
                        class="mt-3"
                        :rules="$rules.required"
                      ></v-text-field>
                    </form-error>
                    <form-error :errors="formErrors.mobile_number">
                      <v-text-field
                        :label="$t('forms.mobile_number')"
                        v-model="editedItem.mobile_number"
                        :rules="$rules.required && $rules.phone"
                        class="mt-3"
                      ></v-text-field>
                    </form-error>
                    <form-error :errors="formErrors.email">
                      <v-text-field
                        :label="$t('forms.email')"
                        v-model="editedItem.email"
                        :rules="$rules.required && $rules.email"
                        :disabled="isEditing"
                      ></v-text-field>
                    </form-error>
                  </v-card-text>
                  <v-toolbar-items>
                    {{ $t('forms.permissions') }}
                  </v-toolbar-items>
                  <form-error :errors="formErrors.can_manage_filestorage">
                    <v-switch
                      class="col-2"
                      :label="`File Manager`"
                      v-model="permissions"
                      value="can_manage_filestorage"
                      :disabled="isEditing"
                    ></v-switch>
                  </form-error>
                  <form-error :errors="formErrors.can_start_trainings">
                    <v-switch
                      class="col-2"
                      :label="`AI Training/Testing`"
                      v-model="permissions"
                      value="can_start_trainings"
                      :disabled="isEditing"
                    ></v-switch>
                  </form-error>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn type="submit" class="btn btn-radius-corner">
                      {{ $t('forms.save') }}
                    </v-btn>
                    <v-btn @click="closeDialog" class="btn btn-gray btn-radius-corner">
                      {{ $t('forms.cancel') }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6" class="d-grid">
        <div class="box-card elevation-1">
          <div class="box-card__header">
            <h3>Change Password</h3>
          </div>
          <div>
            <v-container>
              <v-row>
                <v-col>
                  <v-form @submit.prevent="onSendResetPasswordEmail" ref="resetForm">
                    <div>
                      <v-col cols="12" md="6" class="py-0">
                        <form-error :errors="resetFormError.password_old"
                        :class="{'mb-6': resetFormError.password_old}">
                          <v-text-field
                            :append-icon="hidePassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="() => (hidePassword1 = !hidePassword1)"
                            :type="hidePassword1 ? 'password' : 'text'"
                            :label="$t('forms.oldPassword')"
                            v-model="resetPassword.oldPassword"
                            :rules="$rules.required"
                            counter
                            ref="oldPassword"
                          ></v-text-field>
                        </form-error>
                      </v-col>
                      <v-col cols="12" md="6" class="py-0">
                        <form-error :errors="resetFormError.password_new"
                        :class="{'mb-6': resetFormError.password_new}">
                          <v-text-field
                            :append-icon="hidePassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="() => (hidePassword2 = !hidePassword2)"
                            :type="hidePassword2 ? 'password' : 'text'"
                            :label="$t('forms.newPassword')"
                            v-model="resetPassword.newPassword"
                            :rules="$rules.required"
                            hint="At least 8 characters"
                            counter
                          ></v-text-field>
                        </form-error>
                      </v-col>
                      <v-col cols="12" md="6" class="py-0">
                        <form-error :errors="resetFormError.password_repeat"
                        :class="{'mb-6': resetFormError.password_repeat}">
                          <v-text-field
                            :append-icon="hidePassword3 ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="() => (hidePassword3 = !hidePassword3)"
                            :type="hidePassword3 ? 'password' : 'text'"
                            :label="$t('forms.confirmPassword')"
                            v-model="resetPassword.confirmPassword"
                            :rules="$rules.required"
                            counter
                          ></v-text-field>
                        </form-error>
                      </v-col>
                      <v-col cols="12" md="12" class="mt-2 py-0">
                        <label>Enter Code</label>
                        <v-row class="mt-3">
                          <v-col cols="12" md="6" sm="12">
                            <v-row class="justify-center pin-codes">
                              <v-text-field
                                class="pin"
                                v-model="pin1"
                                ref="pin1"
                                maxlength="1"
                                autofocus
                                @input="onInput($event,1)"
                                autocomplete="off"
                              ></v-text-field>
                              <v-text-field
                                class="pin"
                                v-model="pin2"
                                ref="pin2"
                                maxlength="1"
                                @input="onInput($event,2)"
                                autocomplete="off"
                              ></v-text-field>
                              <v-text-field
                                class="pin"
                                v-model="pin3"
                                ref="pin3"
                                maxlength="1"
                                @input="onInput($event,3)"
                                autocomplete="off"
                              ></v-text-field>
                              <div class="code-dot">
                                <span class="dot"></span>
                              </div>
                              <v-text-field
                                class="pin"
                                v-model="pin4"
                                ref="pin4"
                                maxlength="1"
                                @input="onInput($event,4)"
                                autocomplete="off"
                              ></v-text-field>
                              <v-text-field
                                class="pin"
                                v-model="pin5"
                                ref="pin5"
                                maxlength="1"
                                @input="onInput($event,5)"
                                autocomplete="off"
                              ></v-text-field>
                              <v-text-field
                                class="pin"
                                v-model="pin6"
                                ref="pin6"
                                maxlength="1"
                                @input="onInput($event,6)"
                                autocomplete="off"
                              ></v-text-field>
                          </v-row>
                        <form-error :errors="resetFormError.pin"
                        :class="{'mb-6': resetFormError.pin}">
                        </form-error>
                        </v-col>
                      </v-row>
                      </v-col>
                    </div>
                    <div class="box-card__footer text-center">
                      <v-btn class="mt-3" small type="submit"
                          color="#CE0F69">
                        Reset password
                      </v-btn>
                    </div>
                  </v-form>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import FormError from '@/components/_common/FormError.vue';
import FormErrorMixin from '@/mixins/FormErrorMixin';
import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin';
import updateUser from '@/api/user/updateUser';
import updateUserAdmin from '@/api/admin/user/updateUser';
import resetChangePassword from '@/api/user/changePassword';

export default {
  name: 'UserAccountPage',
  components: {
    FormError,
  },
  mixins: [
    FormErrorMixin,
    FormValidationRulesMixin,
  ],
  data() {
    return {
      data: [],
      hidePassword1: true,
      hidePassword2: true,
      hidePassword3: true,
      dialog: false,
      loading: true,
      editedIndex: -1,
      isEditing: true,
      headers: [
        { text: '', value: 'label' },
        { text: '', value: 'value' },
      ],
      resetFormError: [],
      resetPassword: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      editedItem: {
        id: null,
        company: null,
        can_start_trainings: false,
        can_manage_filestorage: false,
        firstname: '',
        lastname: '',
        email: '',
        mobile_number: '',
        isActive: false,
        roles: null,
      },
      defaultItem: {
        id: null,
        company: null,
        can_start_trainings: false,
        can_manage_filestorage: false,
        firstname: null,
        lastname: null,
        email: null,
        mobile_number: null,
        isActive: false,
        roles: null,
      },
      permissions: [],
      pin1: null,
      pin2: null,
      pin3: null,
      pin4: null,
      pin5: null,
      pin6: null,
    };
  },
  created() {
    this.fetchModels();
  },
  watch: {
    permissions: {
      deep: false,
      handler() {
        this.editedItem.can_manage_filestorage = this.permissions.indexOf('can_manage_filestorage') !== -1;
        this.editedItem.can_start_trainings = this.permissions.indexOf('can_start_trainings') !== -1;
      },
    },
  },
  methods: {
    fetchModels() {
      this.loading = true;
      const permissions = [
        this.$store.getters['user/canManageFilestorage'] === true ? 'File Manager' : '',
        this.$store.getters['user/canStartTrainings'] === true ? 'AI Training/Testing' : '',
      ];
      this.permissions = [
        this.$store.getters['user/canManageFilestorage'] === true ? 'can_manage_filestorage' : '',
        this.$store.getters['user/canStartTrainings'] === true ? 'can_start_trainings' : '',
      ];

      const permissionValue = permissions.filter(Boolean).join(', ');
      this.data = [
        {
          label: 'Company',
          value: this.$store.getters['user/company'] ? this.$store.getters['user/company'].name : '',
        },
        {
          label: 'First name',
          value: this.$store.getters['user/firstname'],
        },
        {
          label: 'Last name',
          value: this.$store.getters['user/lastname'],
        },
        {
          label: 'Mobile number',
          value: this.$store.getters['user/mobileNumber'],
        },
        {
          label: 'E-mail',
          value: this.$store.getters['user/email'],
        },
        {
          label: 'Permissions',
          value: permissionValue,
        },
      ];
      this.loading = false;
    },
    getProfileDataForEdit() {
      this.editedItem.id = this.$store.getters['user/id'];
      this.editedItem.company = this.$store.getters['user/company'] ? this.$store.getters['user/company'].name : '';
      this.editedItem.firstname = this.$store.getters['user/firstname'];
      this.editedItem.lastname = this.$store.getters['user/lastname'];
      this.editedItem.mobile_number = this.$store.getters['user/mobileNumber'];
      this.editedItem.email = this.$store.getters['user/email'];
      this.editedItem.can_manage_filestorage = this.$store.getters['user/canManageFilestorage']
      === true ? 'can_manage_filestorage' : '';
      this.editedItem.can_start_trainings = this.$store.getters['user/canStartTrainings']
      === true ? 'can_start_trainings' : '';
    },
    onSaveItem() {
      // check company and file storage, not required
      if (!this.$refs.form.validate()) return;

      this.$store.dispatch('dialogloader/show', 'The update is in progress ...');

      const data = {
        id: this.editedItem.id,
        firstname: this.editedItem.firstname,
        lastname: this.editedItem.lastname,
        mobile_number: this.editedItem.mobile_number,
        is_active: true,
        can_start_trainings: this.editedItem.can_start_trainings,
        can_manage_filestorage: this.editedItem.can_manage_filestorage,
      };
      const isAdmin = this.$store.getters['user/isAdmin'];
      let updateUserApi = null;
      // check role if admin
      if (isAdmin) {
        updateUserApi = updateUserAdmin(data);
      } else {
        updateUserApi = updateUser(data);
      }
      updateUserApi
        .then(() => {
          this.$store.dispatch('user/updateUser').then(() => {
            this.fetchModels();
          });
          this.closeDialog();
        })
        .finally(() => this.$store.dispatch('dialogloader/hide'))
        .catch((err) => {
          this.$store.dispatch('notificationbar/showNotification', { msg: err.response.data.message, color: 'error', show: true });
          this.mapSymfonyErrors(err.response.data.errors);
        });
    },
    closeDialog() {
      this.clearDialog();
    },
    clearDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
        this.formErrors = {};
      });
    },
    onSendResetPasswordEmail() {
      if (!this.$refs.resetForm.validate()) {
        return;
      }
      for (let i = 1; i < 7; i += 1) {
        const pin = `pin${i}`;
        if (this.$refs[pin] && !this.$refs[pin].value) {
          this.$store.dispatch('notificationbar/showNotification', { msg: 'Please fill 2 Factor Authentification', color: 'error', show: true });
          return;
        }
      }
      const data = {
        password_old: this.resetPassword.oldPassword,
        password_new: this.resetPassword.newPassword,
        password_repeat: this.resetPassword.confirmPassword,
        pin: this.pin1 + this.pin2 + this.pin3 + this.pin4 + this.pin5 + this.pin6,
      };

      this.$store.dispatch('dialogloader/show', 'Please wait...');
      resetChangePassword(data)
        .then(() => {
          this.$store.dispatch('notificationbar/showNotification', { msg: 'Password changed succesfully', color: 'success', show: true });
          this.resetPasswordFields();
        })
        .finally(() => {
          this.$store.dispatch('dialogloader/hide');
        })
        .catch((err) => {
          this.resetFormError.password_old = err.response.data.errors.children.password_old.errors;
          this.resetFormError.password_new = err.response.data.errors.children.password_new.errors;
          // eslint-disable-next-line
          this.resetFormError.password_repeat = err.response.data.errors.children.password_repeat.errors;
          this.resetFormError.pin = err.response.data.errors.children.pin.errors;
        });
    },
    onInput(value, fieldNumber) {
      if (value && value.length === 0) {
        const nextFieldNumber = fieldNumber - 1;
        if (nextFieldNumber >= 1) {
          const nextFieldRef = `pin${nextFieldNumber}`;
          this.$refs[nextFieldRef].focus();
        }
      } else {
        const nextFieldNumber = fieldNumber + 1;
        if (nextFieldNumber <= 6) {
          const nextFieldRef = `pin${nextFieldNumber}`;
          this.$refs[nextFieldRef].focus();
        }
      }
    },
    resetPasswordFields() {
      this.$refs.resetForm.reset();
      this.$refs.resetForm.resetValidation();
      this.$refs.oldPassword.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.pin-codes .v-input input {
  font-size: 1.5rem;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody {
  &> tr:last-child > td:not(.v-data-table__mobile-row) {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
}
</style>
