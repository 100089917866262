<template>
  <v-container>
    <v-row class="justify-start dashboard" v-if="loading">
      <v-col class="mt-14" cols="12">
        <h2>Dashboard</h2>
      </v-col>
      <v-col cols="12" md="4">
        <v-progress-circular indeterminate color="CE0F69" size="128">
          Loading...
        </v-progress-circular>
      </v-col>
    </v-row>
    <div v-if="!loading && currentUserIsAdmin">
      <admin-dashboard />
    </div>
    <div  v-if="!loading && currentUserIsCustomerOrUser">
      <costumer-user-dashboard />
    </div>
  </v-container>
</template>

<script>
import CostumerUserDashboard from '../components/dashboard/CostumerUserDashboard.vue';
import AdminDashboard from '../components/dashboard/AdminDashboard.vue';

export default {
  name: 'DashboardPage',
  components: {
    AdminDashboard,
    CostumerUserDashboard,
  },
  data() {
    return {
      interval: '',
      currentUserIsAdmin: false,
      currentUserIsCustomerOrUser: false,
      userRoles: [],
      loading: true,
    };
  },
  mounted() {
    this.interval = setInterval(() => {
      this.checkUserApiRequestDone();
    }, 1000);
  },
  created() {
    this.checkUserApiRequestDone();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    checkUserApiRequestDone() {
      const loggedIn = this.$store.getters['user/isFullyLoggedIn'];
      if (!loggedIn) return;
      this.userRoles = this.$store.getters['user/roles'];
      if ((this.userRoles.includes('ROLE_USER')
        || this.userRoles.includes('ROLE_CUSTOMER'))
        && !this.$store.getters['user/isAdmin']) {
        this.currentUserIsCustomerOrUser = true;
        this.loading = false;
        clearInterval(this.interval);
      }
      if (this.userRoles.includes('ROLE_ADMIN') || this.$store.getters['user/isAdmin']) {
        this.currentUserIsAdmin = true;
        this.loading = false;
        clearInterval(this.interval);
      }
    },
  },
};
</script>
