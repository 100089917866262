<template>
    <v-row class="justify-start">
      <v-col class="justify-center" cols="3" v-for="(item, index) in itemsWithIconUrl"
             v-bind:key="index">
        <div class="trainingform__modeltype" v-on:click="activate(item)"
             :class="{ active : (active_el.id !== undefined && active_el.id === item.id) }">
          <img :src="item.iconUrl" style="width: 50px; height: 50px"
               alt=""/>
          <div class="name">{{ item.name }}</div>
        </div>
      </v-col>
      <v-col cols="12" class="justify-start">
        <div class="trainingform__modeltype--descr">
          {{ this.active_el.description }}
        </div>
      </v-col>
    </v-row>
</template>
<script>
import getModels from '@/api/admin/settings/getModels';

export default {
  name: 'TrainingsModels',
  data() {
    return {
      active_el: {},
      data: [],
      loading: true,
      defaultModelName: 'balanced',
    };
  },
  props: {
    selectedId: {
      type: Number,
      default: 2,
    },
  },
  created() {
    this.fetchModels();
  },
  methods: {
    fetchModels() {
      getModels()
        .then((response) => {
          this.data = response.data;
          console.log('data model type', this.data);
          this.totalItems = response.data.length;
        })
        .finally(() => {
          this.syncData();
          this.loading = false;
        });
    },
    syncData() {
      // eslint-disable-next-line
      const selectedModel = this.data.find(x => x.id === this.selectedId);
      if (selectedModel) {
        this.activate(selectedModel);
      }
    },
    activate(el) {
      this.active_el = el;
      this.$emit('input', this.active_el);
    },
  },
  computed: {
    itemsWithIconUrl() {
      return this.data.map(
        (item) => ({
          ...item,
          // eslint-disable-next-line global-require,import/no-dynamic-require
          iconUrl: require(`@/assets/icons/models/${item.icon}`),
        }),
      );
    },
  },
};
</script>
<style scoped>
.active {
  border: #CE0F69 solid 3px;
}
.trainingform__modeltype {
  cursor: pointer;
}
</style>
