function getTrainingsOfMachine(state, machineId) {
  return window.$http({
    url: '/api/v1/training/trainings',
    method: 'get',
    params: {
      state,
      machine_id: machineId,
    },
  });
}

export default getTrainingsOfMachine;
