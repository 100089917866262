<template>
  <div class="testings-page">
    <v-container class="mt-5 mb-15 testings-header">
      <v-row class="justify-start">
        <v-col cols="12" sm="6" md="6" lg="3">
          <v-card class="testings-header__new"
                  :disabled="credit < 0 && usedMinutes > minutesToSpend">
            <v-dialog
              v-model="dialog"
              max-width="800px"
              @keydown.esc="closeDialog"
              @click:outside="closeDialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="new" @click="resetTesting()"
                       >
                  <v-icon left>mdi-plus-circle-outline</v-icon>
                  <div class="btn-text">New Testing</div>
                </v-btn>
              </template>
              <v-card>
                  <template v-if="loadingFileListing">
                    <div class="overlay-loading"></div>
                    <div class="icon-loading">
                      <v-progress-circular
                        indeterminate
                        size="25"
                        width="2"
                        color="primary"
                      ></v-progress-circular>
                    </div>
                  </template>
                <v-form
                  @submit.prevent="onSaveItem"
                  ref="form"
                  class="testingform"
                >
                  <v-toolbar dense flat>
                    <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-row class="justify-start mt-5">
                      <v-col cols="10">
                        <h3>
                          Testmode<v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="tooltip"
                              right
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-help-circle
                            </v-icon>
                          </template>
                          <span>
                              Here you first select the test mode with
                              which your data is to
                              <br />
                              be tested. The title describes the generated
                              model type that will<br />
                              later be used for the evaluation by the AI. A
                              description of which<br />
                              model type is suitable for which application is
                              displayed below <br />
                              the selection. Please note that the selection can
                              have a considerable
                              <br />influence on the duration and the costs of a
                              testing.
                            </span>
                        </v-tooltip>
                        </h3>
                      </v-col>
                    </v-row>
                    <Modes
                      v-model="selectedMode"
                      :selectedId="this.selectedMode.id"
                      :key="componentKeySelectedMode"
                    />
                    <form-error :errors="formErrors.selectedMode" />
                    <v-row class="justify-start mt-5">
                      <v-col cols="10">
                        <h3>
                          Testing Ressources<v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="tooltip"
                              right
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-help-circle
                            </v-icon>
                          </template>
                          <span>
                              Select the machine and the testing folder for
                              which the testing<br />
                              is to be started. Only machines that have
                              previously been created<br />
                              via the settings are available for selection
                              here.<br />
                              The testing folder and its data must be located
                              in the File Manager<br />
                              under "ai-testing" of the machine's data
                              structure.<br />
                              The model on which the testing is to be executed is selected<br />
                              by entering the corresponding Training folder.
                            </span>
                        </v-tooltip>
                        </h3>
                      </v-col>
                    </v-row>
                    <div>
                      <Machines
                        v-model="selectedMachine"
                        :selected="selectedMachine.id"
                        :key="componentKeySelectedMachine"
                      />
                      <form-error :errors="formErrors.machine_id" />
                    </div>
                    <v-select
                      :disabled="isEmpty(selectedMachine)"
                      label="Select model (training folder)"
                      v-model="selectedTraining"
                      :items="trainings"
                      itemText="title"
                      itemValue="id"
                      @change="onTrainingChange"
                      :rules="$rules.required"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content class="notranslate" translate="no">
                          <v-list-item-title v-text="item.title"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-select>
                    <form-error :errors="formErrors.selectedTraining" />
                    <v-select
                      :disabled="isEmpty(selectedTraining)"
                      label="Select test folder"
                      v-model="editedItem.datasetDirectory"
                      :items="currentDirListing"
                      itemText="name"
                      itemValue="name"
                      :rules="$rules.required"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content class="notranslate" translate="no">
                          <v-list-item-title v-text="item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-select>
                    <form-error :errors="formErrors.datasetDirectory" />
                    <v-row class="justify-start mt-5">
                      <v-col cols="10">
                        <h3>
                          Information<v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="tooltip"
                                right
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-help-circle
                              </v-icon>
                            </template>
                            <span>
                              Here you enter a meaningful title<br />
                              and a more detailed description of the test,
                              e.g. the features on which the selected model was trained.<br />
                              In addition, you can enter an internal identifier here,<br />
                              e.g. as for training, an individual order or product number,<br />
                              a company-internal project-specific designation or similar.
                            </span>
                          </v-tooltip>
                        </h3>
                      </v-col>
                    </v-row>
                    <v-text-field
                      label="Title"
                      v-model="editedItem.title"
                      :rules="[rules.minLength(1), rules.maxLength(128)]"
                      class="mt-3"
                    ></v-text-field>
                    <form-error :errors="formErrors.title" />
                    <v-textarea
                      label="Description"
                      v-model="editedItem.description"
                      :rules="[rules.minLength(3), rules.maxLength(500)]"
                      rows="3"
                      class="mt-3"
                    ></v-textarea>
                    <form-error :errors="formErrors.description" />
                    <v-text-field
                      label="Internal identifier"
                      v-model="editedItem.internalIdentifier"
                      :rules="$rules.required"
                      class="mt-3"
                    ></v-text-field>
                    <form-error :errors="formErrors.internalIdentifier" />
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn type="submit" class="btn btn-radius-corner">
                      {{ submitButtonTitle }}</v-btn
                    >
                    <v-btn
                      @click="closeDialog"
                      class="btn btn-gray btn-radius-corner"
                    >
                      Cancel</v-btn
                    >
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="3" class="ps-sm-6">
          <v-card
            class="
              testings-header__counter
              dashboard__card-placeholder
              v-card v-sheet
            "
          >
            <div class="label">Euro</div>
            <div class="value" v-if="credit === null">
              <v-progress-circular
                indeterminate
                size="20"
                width="2"
              ></v-progress-circular>
            </div>
            <div class="value" v-else>{{ credit }}</div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="3" class="ps-lg-6">
          <v-card
            class="
              testings-header__counter
              dashboard__card-placeholder
              v-card v-sheet
            "
          >
            <div class="label">Minutes</div>
            <div class="value" v-if="usedMinutes === null">
                <v-progress-circular
                  indeterminate
                  size="20"
                  width="2"
                ></v-progress-circular>
              </div>
            <div class="value" v-else>{{ usedMinutes }}</div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="3" class="ps-sm-6">
          <v-card
            class="
              testings-header__counter
              dashboard__card-placeholder
              v-card v-sheet
            "
          >
            <div class="label">Completed</div>
            <div class="value" v-if="finishedTestings === null">
              <v-progress-circular
                indeterminate
                size="20"
                width="2"
              ></v-progress-circular>
            </div>
            <div class="value" v-else>{{ finishedTestings }}</div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="content-header-section pt-15 pb-15">
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <div class="d-flex justify-space-between">
            <div class="d-flex">
              <div class="mr-3">
                <v-select
                  clearable
                  v-model="selectedStatus"
                  :items="allStatus"
                  @change="onFilterChange"
                  item-text="name"
                  item-value="value"
                  label="Status"
                  dense
                  class="status-select"
                  v-if="testings"
                  :disabled="loading"
                ></v-select>
              </div>
              <div class="mr-3">
                <v-select
                  clearable
                  v-model="filterSelectedMachine"
                  :items="machines"
                  @change="onFilterChange"
                  item-text="title"
                  item-value="id"
                  label="Machine"
                  dense
                  class="select-machine"
                  v-if="machines"
                  :disabled="loading"
                ></v-select>
              </div>
              <div>
                <v-select
                  clearable
                  v-model="filterSelectedUser"
                  :items="users"
                  @change="onFilterChange"
                  :item-text="item => item.firstname + ' ' + item.lastname"
                  item-value="id"
                  label="User"
                  dense
                  class="select-user"
                  v-if="users"
                  :disabled="loading"
                ></v-select>
              </div>
            </div>
            <div>
              <v-btn
                small
                text
                @click="resetFilter()"
                :disabled="filterSelectedMachine === 'all' && selectedStatus === 'all'
                  && filterSelectedUser === 'all'"
              >Clear filters</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-container
        class="content-header-section pt-15 pb-15 text-center"
        v-if="loading"
      >
        <v-progress-circular
          indeterminate
          size="100"
          color="black"
        ></v-progress-circular>
      </v-container>
      <v-row v-else>
        <v-col cols="12" class="testings">
          <v-expansion-panels multiple v-model="panel" v-if="testings">
            <div
              class="testings__item"
              v-for="(testing, index) in testings"
              :key="index"
            >
              <Testing
                :testing="testing"
                :key="componentKeyTestings"
                :id="index"
                :exceeded_resources="exceeded_resources"
                @start="onStart"
                @delete="onDelete"
                @cancel="onCancel"
                @edit="onEdit"
                @clone="onClone"
                @retry="retryValidation"
                @download="downloadResultTesting"
              />
            </div>
          </v-expansion-panels>
          <div  v-if="testings.length === 0 && loading === false"
            class="col-2 mx-auto">
            <span>{{ $t('common.no-data-available') }}</span>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="content-header-section pt-0 pb-15">
      <v-pagination
        v-if="totalPages > 1"
        v-model="page"
        :length="totalPages"
        :total-visible="7"
        class="justify-center"
        @input="closeAllPanels"
      ></v-pagination>
    </v-container>
    <dialog-confirm  ref="confirm" />
  </div>
</template>

<script>
import getTestings from '@/api/user/getTestings';
import validateDataset from '@/api/user/validateDataset';
import abortTesting from '@/api/user/abortTesting';
import editTesting from '@/api/user/editTesting';
import getAccountBalance from '@/api/customer/getAccountBalance';
import calculateMinutes from '@/api/user/calculateMinutes';
import DialogConfirm from '@/components/DialogConfirm.vue';
import FormErrorMixin from '@/mixins/FormErrorMixin';
import FormError from '@/components/_common/FormError.vue';
import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin';
// import getCountImages from '@/api/filemanager/getCountImages';
import getTrainings from '@/api/user/getTrainings';
import Modes from '@/components/testings/new/Modes.vue';
import createTesting from '@/api/user/createTesting';
import startTesting from '@/api/user/startTesting';
import deleteTesting from '@/api/user/deleteTesting';
import testingFolderList from '@/api/user/testingFolderList';
import getTrainingsOfMachine from '@/api/user/getTrainingsOfMachine';
import getPresignedUrl from '@/api/user/getPresignedUrl';
import getMachines from '@/api/user/getMachines';
import Testing from './Testing.vue';
import Machines from './new/Machines.vue';

export default {
  name: 'Testings',
  components: {
    Testing,
    DialogConfirm,
    Modes,
    Machines,
    FormError,
  },
  mixins: [FormErrorMixin, FormValidationRulesMixin],
  computed: {
    isEditing() {
      return this.editedIndex !== -1;
    },
    dialogTitle() {
      return this.editedIndex === -1 ? 'New Testing' : 'Edit Testing';
    },
    submitButtonTitle() {
      return this.editedIndex === -1 ? 'Create Testing' : 'Update Testing';
    },
  },
  data() {
    return {
      loading: true,
      page: 1,
      totalItems: 0,
      totalPages: 1,
      itemsPerPage: 30,
      interval: '',
      intervalUser: '',
      dialog: false,
      editedIndex: -1,
      editedItem: {
        title: '',
        description: '',
        internalIdentifier: '',
        datasetDirectory: '',
        testingMode: Modes.data().modes[0],
        machineId: 0,
        countOfImages: 1,
        trainings: [],
        training: {},
        testing: {},
      },
      defaultItem: {
        title: '',
        description: '',
        internalIdentifier: '',
        datasetDirectory: '',
        testingMode: Modes.data().modes[0],
        machineId: 0,
        selectedMachine: {},
        countOfImages: 1,
        trainings: [],
        training: {},
        testing: {},
      },
      componentKeyTestings: 0,
      componentKeySelectedMode: 1000,
      componentKeySelectedMachine: 2000,
      currentCompany: null,
      loadingUser: true,
      testings: [],
      trainings: [],
      credit: null,
      usedMinutes: null,
      minutesToSpend: null,
      finishedTestings: null,
      exceeded_resources: false,
      selectedMode: Modes.data().modes[0],
      selectedMachine: {},
      selectedTrainingId: null,
      selectedTraining: {},
      loadingFileListing: false,
      currentDirListing: [],
      bearer: '',
      apiUrl: process.env.VUE_APP_ROOT_API
        ? process.env.VUE_APP_ROOT_API.trim().replace(/\/+$/, '')
        : '',
      panel: [],
      date: new Date(),
      networkConstants: {
        fast: 0.0033333,
        balanced: 0.0033333,
        precise: 0.0108,
        segmentation: 0.015,
      },
      selectedStatus: 'all',
      allStatus: [
        { name: 'Aborted', value: 'aborted' },
        { name: 'Created', value: 'created' },
        { name: 'Running', value: 'running' },
        { name: 'Finished', value: 'completed' },
      ],
      machines: [],
      filterSelectedMachine: 'all',
      users: [],
      filterSelectedUser: 'all',
    };
  },
  created() {
    this.getMachines();
    this.fetchTestings();
    this.calcFinishedTestings();
    const bearer = this.$store.getters['user/token'];
    this.bearer = bearer.replace('bearer ', '');
    if (this.$route.query.trainingData) {
      const trainingData = JSON.parse(this.$route.query.trainingData);
      console.log('trainingData', trainingData);
      this.createFromTraining(trainingData);
      this.dialog = true;
    }
    this.fetchUsedMinutes();
  },
  mounted() {
    this.interval = setInterval(() => {
      this.fetchTestings();
      this.calcFinishedTestings();
    }, 120000);
    this.getCredit();
    this.intervalUser = setInterval(() => {
      this.checkUserApiRequestDone();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.intervalUser);
  },
  methods: {
    resetFilter() {
      this.selectedStatus = 'all';
      this.filterSelectedMachine = 'all';
      this.filterSelectedUser = 'all';
      this.fetchTestings();
    },
    async getCredit() {
      const creditRes = await getAccountBalance();
      this.credit = creditRes.data.account_balance;
    },
    forceRerenderTestings() {
      this.componentKeyTestings += 1;
    },
    resetTesting() {
      // eslint-disable-next-line prefer-destructuring
      this.selectedMode = Modes.data().modes[0];
      this.selectedMachine = {};
      this.componentKeySelectedMode = 1000;
    },
    async getMachines() {
      await getMachines()
        .then((response) => {
          this.machines = response.data;
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.loadingFileListing = false;
        });
    },
    async fetchTestings() {
      this.loading = true;
      this.testings = [];
      const start = this.itemsPerPage * (this.page - 1);
      const state = this.selectedStatus === 'all' ? undefined : this.selectedStatus;
      const machineFilter = this.filterSelectedMachine === 'all' ? undefined : this.filterSelectedMachine;
      const userFilter = this.filterSelectedUser === 'all' ? undefined : this.filterSelectedUser;
      await getTestings(start, state, machineFilter, userFilter)
        .then((response) => {
          this.testings = response.data.data;
          this.totalItems = response.data.total;
          this.totalPages = Math.ceil(response.data.total / this.itemsPerPage);
          this.users = response.data.creators;
        })
        .finally(() => {
          this.loading = false;
          this.forceRerenderTestings();
        });
    },
    async fetchTrainingsOfMachine(machineId) {
      try {
        return getTrainingsOfMachine('completed', machineId)
          .then((response) => {
            this.trainings = response.data.data;
            console.log('this.trainings', this.trainings);
            return response.data.data;
          });
      } catch (error) {
        console.log('error', error);
        return [];
      }
    },
    async createFromTraining(trainingData) {
      const prefix = 'Test: ';

      this.loadingFileListing = true;
      console.log('createFromTraining: ', trainingData);
      this.editedItem = {
        testingMode: this.selectedMode,
        title: prefix + trainingData.title,
        description: prefix + trainingData.description,
        internalIdentifier: trainingData.internal_identifier,
        machineId: trainingData.machine.id,
        training: trainingData,
      };
      await this.fetchFoldersOfMachine(trainingData.machine.id);
      try {
        getTrainingsOfMachine('completed', trainingData.machine.id).then((res) => {
          console.log('res', res);
          this.trainings = res.data.data;
          this.editedItem.trainings = res.data.data;
          this.selectedMachine = { ...trainingData.machine };
          this.selectedTraining = this.trainings.find(
            (training) => training.id === trainingData.id,
          );
          this.selectedTrainingId = trainingData.id;
          this.loadingFileListing = false;
        });
      } catch (error) {
        console.log('error', error);
        this.loadingFileListing = false;
      }
    },
    async fetchTrainings() {
      getTrainings(0, 'completed')
        .then((response) => {
          this.trainings = response.data.data;
          console.log('this.trainings', this.trainings);
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
    async fetchFoldersOfMachine(machineId) {
      this.loadingFileListing = true;
      try {
        const response = await testingFolderList(machineId);
        this.currentDirListing = response.data;
      } catch (error) {
        console.log('error', error);
        this.currentDirListing = [];
      } finally {
        this.loadingFileListing = false;
      }
    },
    calcFinishedTestings() {
      const filter = 'completed';
      getTestings(0, filter)
        .then((response) => {
          this.finishedTestings = response.data.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onDelete(testing) {
      console.log('onDelete', testing);
      this.$refs.confirm
        .open('Delete this testing', 'Do you really want to delete this testing?')
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch(
              'dialogloader/show',
              'The testing is being deleted ...',
            );
            deleteTesting(testing).finally(() => {
              this.fetchTestings();
              this.$store.dispatch('dialogloader/hide');
            });
          }
        });
    },
    onCancel(testing) {
      const testingTitle = testing.title ? `with the title "${testing.title}"` : '';
      this.$refs.confirm
        .open('Cancel this testing', `Are you sure to cancel the ongoing testing "${testingTitle}"?`)
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch(
              'dialogloader/show',
              'The testing is being canceled ...',
            );
            abortTesting(testing).finally(() => {
              this.fetchTestings();
              setTimeout(() => {
                this.$store.dispatch('dialogloader/hide');
              }, 1000);
            });
          }
        });
    },
    retryValidation(testing) {
      this.$refs.confirm
        .open(
          'Retry Validation this test',
          'Retry Validation on this test?',
        )
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch('dialogloader/show', 'Retry validationing...');
            validateDataset('testing', testing.id)
              .then(() => {
                this.fetchTestings();
              })
              .finally(() => {
                this.$store.dispatch('dialogloader/hide');
              });
          }
        });
    },
    async onEdit(testing, index) {
      this.editedItem = { ...testing };
      // eslint-disable-next-line prefer-destructuring
      this.selectedMode = Modes.data().modes[0];
      this.editedItem.testingMode = this.selectedMode;
      this.editedItem.machineId = testing.training.machine_id;
      this.selectedMachine = this.machines.find(
        (machine) => machine.id === testing.training.machine_id,
      );
      if (testing.testing.datasetDirectory) {
        this.editedItem.datasetDirectory = this.testing.datasetDirectory;
      } else {
        this.editedItem.datasetDirectory = testing.testing.dataset_directory;
      }
      this.selectedTrainingId = testing.training.id;
      this.editedIndex = index;
      this.editedItem.title = testing.testing.title;
      this.editedItem.description = testing.testing.description;
      this.editedItem.internalIdentifier = testing.testing.internal_identifier;
      this.editedItem.training = testing.training;
      this.selectedTraining = testing.training;
      await this.fetchFoldersOfMachine(testing.training.machine_id);
      this.componentKeySelectedMode += 1;
      console.log('this.editedItem', this.editedItem);
      this.dialog = true;
    },
    onClone(testing) {
      this.$refs.confirm
        .open('Clone this testing', 'Confirm cloning this testing?')
        .then((confirm) => {
          if (confirm) {
            console.log('testing', testing);
            this.loadingFileListing = true;
            this.editedItem = { ...testing };
            console.log('this.editedItem in clone', this.editedItem);
            this.fetchTrainingsOfMachine(testing.training.machine_id).then(
              (trainings) => {
                console.log('trainings', trainings);
                this.trainings = trainings;
                console.log('this.trainings', this.trainings);
                this.selectedTraining = this.trainings.find(
                  (training) => training.id === testing.training.id,
                );
                console.log('this.selectedTraining', this.selectedTraining);
                // eslint-disable-next-line prefer-destructuring
                this.selectedMode = Modes.data().modes[0];
                this.editedItem.testingMode = this.selectedMode;
                this.editedIndex = -1;
                this.editedItem.datasetDirectory = testing.testing.dataset_directory;
                this.editedItem.title = testing.testing.title;
                this.editedItem.description = testing.testing.description;
                this.editedItem.internalIdentifier = testing.testing.internal_identifier;
                this.editedItem.machineId = testing.training.machine_id;
                this.selectedMachine = this.machines.find(
                  (machine) => machine.id === testing.training.machine_id,
                );
                this.selectedTrainingId = testing.training.id;
                this.editedItem.training = testing.training;
                this.fetchFoldersOfMachine(testing.training.machine_id);
                this.componentKeySelectedMode += 1;
                this.componentKeySelectedMachine += 1;
                this.dialog = true;
              },
            )
              .finally(() => {
                this.loadingFileListing = false;
              });
          }
        });
    },
    onStart(testing) {
      this.$refs.confirm
        .open('Start this testing', `Start testing "${testing.testing.title}"?`)
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch(
              'dialogloader/show',
              'The testing is being started ...',
            );
            startTesting(testing.testing).finally(() => {
              this.fetchTestings();
              this.$store.dispatch('dialogloader/hide');
            });
          }
        });
    },
    closeDialog() {
      if (this.$route.query.trainingData) {
        this.dialog = false;
        this.$router.push('testings');
      } else {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = { ...this.defaultItem };
          this.editedIndex = -1;
          this.$refs.form.reset();
          this.$refs.form.resetValidation();
          this.trainings = [];
          this.formErrors = {};
          this.selectedTraining = {};
          this.componentKeySelectedMode += 1;
          this.componentKeySelectedMachine += 1;
        });
      }
    },
    onSaveItem() {
      console.log('this.editedItem', this.editedItem);
      if (!this.$refs.form.validate()) return;
      if (this.isEditing) {
        this.$store.dispatch('dialogloader/show', 'Editing in progress...');
        editTesting(this.editedItem)
          .then(() => {
            this.closeDialog();
            this.fetchTestings();
          })
          .finally(() => this.$store.dispatch('dialogloader/hide'));
      } else {
        this.$store.dispatch('dialogloader/show', 'Adding in progress...');
        createTesting(this.editedItem)
          .then(() => {
            this.resetTesting();
            this.closeDialog();
            this.fetchTestings();
            this.loading = false;
          })
          .finally(() => this.$store.dispatch('dialogloader/hide'));
      }
    },
    checkUserApiRequestDone() {
      this.currentCompany = this.$store.getters['user/company'];
      if (this.currentCompany) {
        if (this.currentCompany.pricing !== null) {
          this.minutesToSpend = this.currentCompany.pricing.training_minutes
          + this.currentCompany.pricing.more_minutes;
        }
        if (this.credit < 0 && (this.usedMinutes > this.minutesToSpend)) {
          this.exceeded_resources = true;
        } else {
          this.exceeded_resources = false;
        }
      }
    },
    onTrainingChange() {
      const originalSelectedTraining = this.selectedTraining;

      const foundTraining = this.trainings.find(
        (training) => training.id === originalSelectedTraining,
      );
      this.selectedTraining = foundTraining;
      console.log('onTrainingChange this.selectedTraining', this.selectedTraining);
      this.editedItem.training = this.selectedTraining;
      this.componentKeySelectedMode += 1;
    },
    isEmpty(obj) {
      return obj == null || (Object.keys(obj).length === 0 && obj.constructor === Object);
    },
    downloadResultTesting(testing) {
      this.$store.dispatch('dialogloader/show', 'Downloading in progress...');
      getPresignedUrl('testing', testing.testing, this.bearer, this.apiUrl)
        .then((response) => {
          const presignedUrl = response.data.presigned_url;
          const link = document.createElement('a');
          const disposition = response.headers['content-disposition'];
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          let filename = 'download';
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
          link.href = presignedUrl;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => console.log('ERROR: downloadResultTesting', error));
      this.$store.dispatch('dialogloader/hide');
    },
    fetchUsedMinutes() {
      calculateMinutes(this.date.getMonth() + 1, this.date.getFullYear())
        .then((response) => {
          this.usedMinutes = response.data.minutes;
        });
    },
    onFilterChange() {
      this.page = 1;
      this.fetchTestings();
    },
    closeAllPanels() {
      this.panel = [];
    },
  },
  watch: {
    trainings(newTrainings) {
      if (newTrainings && newTrainings.length > 0) {
        this.editedItem.trainings = newTrainings;
        this.trainings = newTrainings;
      }
    },
    dialog(newValue) {
      if (newValue === false) {
        this.closeDialog();
      }
    },
    selectedMachine(newVal) {
      if (newVal && newVal.id) {
        this.selectedMachine = newVal;
        this.fetchTrainingsOfMachine(newVal.id);
        this.fetchFoldersOfMachine(newVal.id);
      }
    },
    page: {
      deep: false,
      handler() {
        this.fetchTestings();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.testings-page {
  .status-toggle {
    height: auto !important;
    padding: 15px;
    column-gap: 10px;
    button.btn {
      border-radius: 4px;
      &:not(.btn-toggle--active) {
        .v-btn__content {
          color: #000 !important;
        }
      }
    }
  }
  hr.solid {
    border-style: solid;
  }
}

</style>
