<template>
  <v-row class="ma-0">
    <v-expansion-panel>
      <v-col class="training py-0" cols="12" :data-id="training.id">
        <v-row class="">
          <v-col class="training__firstcol pl-0 " md="7" sm="5">
            <v-row class="">
              <v-col class="py-0" md="8" sm="12">
                <div class="training__date">
                  {{ format_date(training.created_at) }}
                </div>
                <div class="training__title notranslate" translate="no">
                  <div v-if="!training.title">unknown</div>
                  <div v-else>{{ training.title }}</div>
                </div>
                <div class="training__creator">
                  {{ training.company_user.user.firstname }}
                  {{ training.company_user.user.lastname }}
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="" md="3" sm="4">
            <v-row class="training__actions">
                <v-col md="5" sm="5">
                <div class="dataset"
                        v-if="checkErrorsWarnings(training.errors_and_warnings)
                  || training.state === 'defective'"
                        :class="{
                    'warning-dataset': (training.state === 'created' &&
                    training.errors_and_warnings.warnings ?
                    training.errors_and_warnings.warnings.length : 0 )
                    || training.state === 'defective',
                    'error-dataset': training.errors_and_warnings.errors ?
                    training.errors_and_warnings.errors.length : 0
                  }">
                <v-icon class="mr-1">
                  mdi-alert
                </v-icon>
                <span v-if="training.state !== 'error'">Dataset</span>
              </div>
              </v-col>
              <v-col md="7" sm="7">
                <v-btn class="start-training-btn" small @click="onStart(training)"
                     :disabled="training.state === 'defective' || exceeded_resources"
                     v-if="training.state === 'created' || training.state === 'defective'">
                <v-icon>mdi-play</v-icon>
                Start
              </v-btn>
              <v-btn class="start-download-btn" small v-if="training.state === 'finished'
                && training.is_exported === true" @click="onDownloadResultTraining(training)"
                     color="#21BA45">
                <v-icon>mdi-download</v-icon>
                Download
              </v-btn>
              <div class="training__info-spinner"
                   v-if="training.state === 'started' && training.startedAt === undefined">
                <v-icon class="mdi-loading">mdi-spin</v-icon>
                Initialising<br>Training
              </div>
              <div class="training__info-spinner ml-lg-15"
                   v-if="training.state === 'finished' && training.is_exported === false">
                <v-icon class="mdi-loading">mdi-spin</v-icon>
                Preparing<br>Download
              </div>
          </v-col>
          </v-row>
          </v-col>

          <v-col class="ma-0 pa-3 training__status" md="2" sm="3">
            <div class="training__status--msg" v-bind:class="this.statusMsgClass(training)"
            >
              {{ getTrainingStatus }}
              <div class="training__date" v-if="training.state === 'started'
                && training.started_at">
                {{ format_date(training.started_at) }}
              </div>
              <div class="training__date" v-else-if="training.state === 'finished'">
                {{ format_date(training.finished_at) }}
              </div>
            </div>
            <v-menu offset-y min-width="200px" nudge-left="20px" left>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  :ripple="true"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-card class="training__dots-vertical">
                <v-list>
                  <template
                    v-for="(item, index) in actionTraining"
                  >
                    <v-list-item
                      :disabled="!item.state.includes(training.state)"
                      :title="item.name"
                      :ripple="true"
                      :key="item.key"
                      class="mt-1"
                    >
                      <v-list-item-content
                        @click="actionTrainingFunc(item.key, training, index)"
                      >
                        <v-list-item-title>
                          {{ item.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                </v-list>
              </v-card>
            </v-menu>
            <v-expansion-panel-header class="accordion-btn">
            </v-expansion-panel-header>
          </v-col>
        </v-row>
      </v-col>
      <div v-if="training.state === 'running'" class="mt-3">
        <div v-if="training.state === 'running'"
             class="training__progress--linear">
          <v-progress-linear
            :value="progressPercent"
            color="transparent"
            height="25"
          >
            <template v-slot:default="{ value }">
              <strong class="progess-percent">{{ Math.ceil(value) }}%</strong>
            </template>
          </v-progress-linear>
          <v-progress-linear :value="progressPercent"
                             color="#21BA45"
                             height="6"
                             reverse
                             buffer-value="0"
                             absolute bottom stream
          />
        </div>
      </div>
      <v-expansion-panel-content>
        <v-row class="ma-0">
          <v-col class="pb-0 ps-0" cols="12" sm="6">
            <v-row class="">
              <v-col class="pt-0" md="10" sm="10">
                {{ training.description }}
              </v-col>
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Internal ID: </span>
                <span>{{ training.internal_identifier }}
                </span>
                <hr class="mt-5"/>
              </v-col>
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Machine: </span>
                <span>{{ training.machine.title }}</span>
              </v-col>
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Data directory:&nbsp;</span>
                <span class="notranslate" translate="no">{{ training.dataset_directory }}</span>
              </v-col>
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Number of files: </span>
                <span>{{ training.count_of_files }}</span>
              </v-col>
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Model type: </span>
                <span>{{ training.training_model.name }}</span>
              </v-col>
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Training steps: </span>
                <span>{{ training.steps }}</span>
              </v-col>
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Learning rate: </span>
                <span>{{ training.learning_rate }}</span>
              </v-col>
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Augmentations: </span>
                <span v-if="training.augmentations.length">
                    <span v-for="(augmentation, index) in training.augmentations" :key="index">
                      {{ $t('trainings.augmentations.' + augmentation) }}<span
                      v-if="index !== Object.keys(training.augmentations).length - 1">, </span>
                    </span>
                  </span>
              </v-col>
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Classes in labelmap: </span>
                <span>{{ training.count_of_classes }}</span>
              </v-col>
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Creator: </span>
                <span>
                  {{ training.company_user.user.firstname }}
                  {{ training.company_user.user.lastname }}
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pb-0 mb-5" cols="12" sm="6">
            <v-row class="info-box">
              <v-col class="" md="10" sm="10">
                <span class="font-weight-bold">Status: </span>
                <span class="state" :class="training.state">
                    {{ getTrainingStatus }}</span>
              </v-col>
              <v-col class="" md="10" sm="10"
                     v-if="training.state === 'running' || training.state === 'finished'">
                <span class="font-weight-bold">Elapsed time: </span>
                <span>{{ getElapsedTime }}</span>
              </v-col>
              <v-col class="" md="10" sm="10" v-if="training.state === 'running'">
                <span class="font-weight-bold">Estimated time: </span>
                <span>{{ getEstimatedTime }}</span>
              </v-col>
              <v-col class="" md="10" sm="10"
                     v-if="training.state === 'running' || training.state === 'finished'">
                <span class="font-weight-bold">Starting date: </span>
                <span>{{ format_date(training.started_at, true, true) }}</span>
              </v-col>
              <v-col class="" md="10" sm="10" v-if="training.state === 'finished'">
                <span class="font-weight-bold">Finishing date: </span>
                <span>{{ format_date(training.finished_at, true, true) }}</span>
              </v-col>
              <v-col class="" md="10" sm="10">
                <div class="d-flex align-center mb-3">
                  <span class="font-weight-bold mr-3">Dataset validation: </span>
                  <span
                    class="d-flex align-center">
                    <span v-if="(training.errors_and_warnings.errors &&
                      training.errors_and_warnings.errors.length) || training.state === 'defective'"
                          class="error-message mr-3">
                           fail
                    </span>
                    <span v-else class="ok-message mr-3">
                           ok
                    </span>
                    <v-btn
                      v-if="training.state === 'defective' || training.state === 'created'"
                      class=""
                      small @click="retryValidation(training)">
                              <v-icon>mdi-refresh</v-icon>
                              Retry
                    </v-btn>
                  </span>
                </div>
                <v-row v-if="training.errors_and_warnings.errors">
                  <v-col class="error-message pb-0" cols="12"
                         v-for="(error, index) in training.errors_and_warnings.errors"
                         :key="index">
                    <p>... {{ error.message }}</p>
                    <template v-if="error.data && error.data.length > 0">
                      <v-row
                        v-if="typeof error.data === 'string' || error.data instanceof String">
                        <v-col class="error-message py-0 pl-5" cols="12">
                          <v-icon
                            right
                            class="error-message"
                          >mdi-arrow-right-thin
                          </v-icon>
                          {{ error.data }}
                        </v-col>
                      </v-row>
                      <v-row class="pl-5"
                             v-else>
                        <v-col class="error-message py-0 pl-5" cols="12"
                               v-for="(dataItem, index) in error.data"
                               :key="index">
                          <v-icon
                            right
                            class="error-message"
                          >mdi-arrow-right-thin
                          </v-icon>
                          {{ dataItem }}
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>
                </v-row>

                <v-row v-if="training.errors_and_warnings.warnings
                  && training.errors_and_warnings.warnings.length > 0">
                  <v-col class="warning-message pb-0" cols="12"
                         v-for="(warning, index) in training.errors_and_warnings.warnings"
                         :key="index">
                    <p>... {{ warning.message }}</p>
                    <v-row class="pl-5" v-if="warning.data && warning.data.length > 0">
                      <v-col class="warnings-message py-0 pl-5" cols="12"
                             v-for="(dataItem, index) in warning.data"
                             :key="index">
                        <v-icon
                          right
                          class="warnings-message"
                        >mdi-arrow-right-thin
                        </v-icon>
                        {{ dataItem }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="" cols="12">
                <hr class="mt-5" v-if="training.state === 'started'"/>
              </v-col>
              <v-col class="" md="10" sm="10">
                <loss-graph v-if="training.loss.length > 0" :data="training.loss"/>
              </v-col>
              <v-col class="mt-auto p-2" md="10" sm="10"
                     v-if="training.state === 'started' || training.state === 'running'">
                <v-btn class="btn-as-link"
                       @click="onCancel(training)">
                  cancel training
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-row>
</template>

<script>

import moment from 'moment';
import LossGraph from './LossGraph.vue';

export default {
  name: 'Training',
  components: {
    LossGraph,
  },
  props: {
    training: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
    },
    exceeded_resources: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: 0,
      actionTraining: [
        { name: 'Clone', key: 'clone', state: ['created', 'defective', 'started', 'running', 'aborted', 'finished', 'error', 'failed', 'unknown'] },
        { name: 'Edit', key: 'edit', state: ['created', 'defective'] },
        { name: 'Cancel', key: 'cancel', state: ['started', 'running'] },
        { name: 'Create Testing', key: 'test', state: ['finished'] },
        { name: 'Delete', key: 'delete', state: ['created', 'defective'] },
      ],
    };
  },
  mounted() {
    if (this.training.job && this.training.job.progress) {
      this.value = `${Math.round((this.training.progress) * 1000) / 10}`;
    }
  },
  methods: {
    statusMsgClass(training) {
      return `training__status--msg-${training.state}`;
    },
    checkErrorsWarnings(items) {
      return !!((items.errors && items.errors.length)
        || (items.warnings && items.warnings.length));
    },
    retryValidation(training) {
      this.$emit('retry', training.id);
    },
    onDelete(training) {
      this.$emit('delete', training);
    },
    onCancel(training) {
      this.$emit('cancel', training);
    },
    createTest(training) {
      this.$router.push({
        path: '/customer/testings',
        query: {
          trainingData: JSON.stringify(training),
        },
      });
    },
    onStart(training) {
      this.$emit('start', training);
    },
    onDownloadResultTraining(training) {
      this.$emit('download', training);
    },
    formatPercentFromFactor(factor) {
      return Math.ceil(factor * 100);
    },
    panelClicked() {
      this.$emit('panelClicked', this.id);
    },
    getDuration(d2, d1) {
      const diff = d1.getTime() - d2.getTime();
      return {
        getDays() {
          return Math.floor((diff / (1000 * 60 * 60 * 24)));
        },
        getHours() {
          return Math.floor(((diff - this.getDays() * 1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
        },
        getMinutes() {
          return Math.floor(((diff - (this.getDays() * 1000 * 60 * 60 * 24
            + this.getHours() * 1000 * 60 * 60))
            / (1000 * 60)));
        },
        toString() {
          const days = this.getDays() > 0 ? `${this.getDays()} days` : '';
          const hours = this.getHours() > 0 ? `${this.getHours()} hours` : '';
          const minutes = this.getMinutes() > 0 ? `${this.getMinutes()} minutes` : '';
          return `${days} ${hours} ${minutes}`;
        },
      };
    },
    actionTrainingFunc(key, training, index) {
      // eslint-disable-next-line
      const id = { id: training.id };

      switch (key) {
        case 'clone':
          this.$emit('clone', training);
          break;
        case 'edit':
          this.$emit('edit', training, index);
          break;
        case 'cancel':
          this.onCancel(id);
          break;
        case 'test':
          this.createTest(training);
          break;
        case 'delete':
          this.onDelete(id);
          break;
        default:
      }
    },
  },
  computed: {
    progressPercent() {
      return Math.round((this.training.progress) * 1000) / 10;
    },
    getElapsedTime() {
      if (this.training.started_at) {
        const d1 = moment.utc(this.training.started_at).local().toDate();
        let d2 = new Date();
        if (this.training.state === 'finished') {
          d2 = moment.utc(this.training.finished_at).local().toDate();
        }
        return this.getDuration(d1, d2).toString();
      }
      return '-';
    },
    getEstimatedTime() {
      if (this.training.remaining_minutes_estimate > 0) {
        const d1 = moment.unix(0).local().toDate();
        const d2 = moment.unix(Math.round(this.training.remaining_minutes_estimate) * 60)
          .local().toDate();
        return `approx. ${this.getDuration(d1, d2).toString()}`;
      }
      return '-';
    },
    getTrainingStatus() {
      if (this.training.state === 'defective') {
        return 'Created';
      }
      if (this.training.state === 'created') {
        return 'Ready';
      }
      return this.training.state;
    },
  },
};
</script>

<style lang="scss">
.v-list {
  .v-list-item {
    cursor: pointer;
  }
}
</style>
