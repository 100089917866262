<template>
  <div>
    <v-container>
      <v-row class="pl-3 pr-3">
        <v-col class="box-card rounded-0 col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <!-- CONFIG/PREV/NEXT BUTTON -->
          <v-row style="height: 66px; background-color: #F5F5F5">
            <v-col class="pl-0 pt-4">
              <v-btn @click="backToConfig()" color="primary">Back to configuration</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-4 mt-0 pl-3 pr-3 pb-4">
              <!-- IMAGE LIST (LEFT)-->
              <ImageList :dataset="dataset" :loaded_image="loaded_image_name"
                :pages="pagination_buttons" :unlabelled_images="images_without_labels"
                v-on:image-click="load_image" :per_page="per_page" ref="imglst">
              </ImageList>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="box-card rounded-0 col-12 col-sm-12 col-md-12 col-lg-9 col-xl-6"
          :align="'center'">
          <v-row class="pt-1" style="height: 66px; width: 854px"> <!-- IMAGE NAME + BUTTONS (CENTER)-->
            <v-col cols="12" :align="'center'" class="pl-0 pr-0">
              <span :title="dataset.mname + ' \\ ' + dataset.name + ' \\ ' + loaded_image_name">{{ loaded_image_name.substring(0, 80) }}{{
                loaded_image_name.length > 80 ? '...' : '' }}</span>
            </v-col>
          </v-row>
          <v-row class="pt-4" justify="center">
            <!-- CANVAS (CENTER) -->
            <div id="konvaContainer"></div>
          </v-row>
          <v-row style="font-family: 'Courier New', Courier, monospace; font-size: smaller;
            white-space: pre; width: 854px" class="pb-3">
            <!-- STATUS BAR -->
            <v-col class="pa-0 ma-0" style="text-align: left;">
              Mouse Coordinates: {{ mouse_coordinates }}
            </v-col>
            <v-col :align="'center'" class="pa-0 ma-0">
              {{ images_without_labels.length }} image(s) without labels
            </v-col>
            <v-col class="pa-0 ma-0" style="text-align: right;">
              Image Resolution: {{ loaded_image_resolution_x }}x{{ loaded_image_resolution_y }}
            </v-col>
          </v-row>
        </v-col>
        <v-col class="box-card rounded-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
          <v-row style="height: 66px; background-color: #F5F5F5">
            <v-col>
            </v-col>
          </v-row>
          <!-- CLASS LIST -->
          <v-row>
            <v-col class="pt-4 pb-4 pl-3 pr-3">
              <v-select outlined dense :items="dataset.labelmap"
                v-model="selected_class" :value="selected_class"
                @change="set_active_class" return-object hide-details>
                <template v-slot:selection="{ item }">
                  <div :style="{ 'color': item.Color, 'background-color': item.Color }"
                    class="mr-2">__
                  </div>
                  {{ item.Name }}
                </template>
                <template v-slot:item="data">
                  <v-list-item v-bind="data.attrs" v-on="data.on" class="mt-1 ml-1 mr-1 ma-0 pa-0">
                    <div :style="{ 'color': data.item.Color, 'background-color': data.item.Color }"
                      class="ml-2 mr-2">___</div>
                    <v-list-item-content style="color: black;">{{ data.item.Name
                    }}</v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <!-- LABEL LIST (RIGHT) -->
            <v-col class="pt-0 pl-3 pr-3">
              <v-list v-if="edited_labels && edited_labels.length > 0"
                class="pl-4 pt-4 pr-4 pb-4"
                style="min-height: 439px; border-radius: 5px;
                  box-shadow: inset 0 0 7px #bdbfc0;"
                dense
              >
                <LabelItem v-for="(label, index) of edited_labels" v-bind:key="index"
                  v-on:item-click="on_item_click(index)" :use_list="true"
                  v-on:item-remove-click="on_item_remove_click(index)"
                  :index="index"
                  :title="label.classname" :color="label.color"
                  :highlighted="highlighted_id == edited_labels[index].vtid">
                  Loading...
                </LabelItem>
              </v-list>
              <v-col v-else :align="'center'"
                style="height: 439px; border-radius: 5px; box-shadow: inset 0 0 7px #bdbfc0">
                <span>
                  No Labels
                </span>
              </v-col>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pr-6 pl-6 pt-4 pb-7">
              <v-row justify="space-between">
                <v-btn color="primary" @click="prev_image()" :disabled="isFirstOrNoUnlabelledImage">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-btn color="primary" @click="save_labels()"
                  :disabled="!labelsChanged()" style="width: 40%;">
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
                <v-btn color="primary" @click="next_image()" :disabled="isLastOrNoUnlabelledImage">
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
                <v-btn color="primary" @click="next_image_without_label()" class="ml-1"
                title="Go to first image without a label"
                :disabled="images_without_labels.length === 0">
                <v-icon>mdi-chevron-double-right</v-icon>
              </v-btn>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="debug">
            <!-- RECT DEBUG -->
            DEBUG: <br>
            TopLeft: ( {{ debug_scaled_topleft_x }} ; {{ debug_scaled_topleft_y }} )<br>
            Width: {{ debug_scaled_width }}<br>
            Height: {{ debug_scaled_height }}<br>
            BottomRight: ( {{ debug_scaled_bottomright_x }} ; {{ debug_scaled_bottomright_y }} )<br>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
#konvaContainer {
  border: 1px solid #8f8f8f;
}
</style>

<script>
import Konva from 'konva';
import getImage from '@/api/labelling/getImage';
import getLabel from '@/api/labelling/getLabel';
import saveLabel from '@/api/labelling/saveLabel';
import deleteLabel from '@/api/labelling/deleteLabel';
import LabelItem from '@/components/labelling/LabelItem.vue';
import ImageList from './ImageList.vue';

const W = 854;
const H = 480;

// window.onresize = () => {
//     console.log(window.innerWidth)
//     console.log(window.innerHeight)
// }
// console.log(window.innerWidth)
// console.log(window.innerHeight)

export default {
  components: { LabelItem, ImageList },
  data() {
    return {
      text: '',
      canvas_width: W,
      canvas_height: H,
      stage: null,
      image_layer: null,
      overlay_layer: null,
      selection_layer: null,
      transformer: null,
      transformer_added: false,
      dragging: false,
      first_mouse_move: false,
      pos_start: { x: 0, y: 0 },
      pos_now: { x: 0, y: 0 },
      current_classname: '',
      current_classcolor: '',
      dataset: null,
      method: '',
      loaded_image_name: '',
      loaded_image_url: undefined,
      loaded_image_resolution_x: 0,
      loaded_image_resolution_y: 0,
      leave_confirmed: false,
      active_class_index: 0,
      active_image_index: 0,
      pagination: 1,
      per_page: 10,
      scale_x: 1.0,
      scale_y: 1.0,
      highlighted_id: '',
      selected_rect_index: 0,
      images_without_labels: [],
      saved_labels_info: [],
      edited_labels: [],
      label_classes_added: {},
      selected_class: null,
      pagination_buttons: [],

      debug_number: '',
      debug: false,
      debug_scaled_mouse_x: 0,
      debug_scaled_mouse_y: 0,
      debug_scaled_topleft_x: 0,
      debug_scaled_topleft_y: 0,
      debug_scaled_bottomright_x: 0,
      debug_scaled_bottomright_y: 0,
      debug_scaled_width: 0,
      debug_scaled_height: 0,
      unlabelled_images_switch: false,
      labels_outside_of_canvas: false,
      has_mask_labels: false,
    };
  },

  beforeMount() {
    this.dataset = this.$store.getters['user/dataset'];
    this.machine = this.dataset.machine;
  },

  mounted() {
    this.image_layer = new Konva.Layer({ name: 'image' });
    this.overlay_layer = new Konva.Layer({ name: 'overlay' });
    this.selection_layer = new Konva.Layer({ name: 'selection' });
    this.transformer = new Konva.Transformer({ rotateEnabled: false, keepRatio: false, name: 'transformer' });
    this.image_layer.listening(false);
    this.overlay_layer.listening(true);
    this.selection_layer.listening(false);
    this.stage = new Konva.Stage({
      container: '#konvaContainer',
      width: this.canvas_width,
      height: this.canvas_height,
      listening: true,
    });
    this.stage.on('mousedown', this.start_drag.bind(this)); // TODO: evtl. nur für this.selection_layer?
    this.stage.on('mousemove', this.update_drag.bind(this));
    this.stage.on('mouseup', this.stop_drag.bind(this));
    this.stage.on('mouseleave', this.stop_drag.bind(this));
    this.stage.on('mouseenter', this.stop_drag.bind(this));
    this.stage.add(this.image_layer);
    this.stage.add(this.overlay_layer);
    this.stage.add(this.selection_layer);
    this.transformer.visible(true);
    window.addEventListener('keyup', this.onKeyUp.bind(this));

    let imageIndex = 0;
    if (this.active_image_index >= 0) {
      imageIndex = this.active_image_index;
    }
    if ((this.dataset.images !== undefined) && (this.dataset.images.length > 0)) {
      this.load_image(this.dataset.images[imageIndex]);
    }
    this.set_active_class(this.dataset.labelmap[0]);
    this.dataset.images.forEach((element) => {
      if (!this.dataset.labels.includes(this.new_ext(element, 'json'))) {
        this.images_without_labels.push(element);
      }
    });

    this.dataset.labelmap.forEach(
      (value) => {
        // eslint-disable-next-line no-param-reassign
        value.Index = this.class_index_from_labelmap(value.Name);
        // eslint-disable-next-line no-param-reassign
        value.Color = this.dataset.colortable[value.Index];
      },
    );

    const num = Math.ceil(this.dataset.images.length / this.per_page);
    for (let i = 1; i <= num; i += 1) {
      this.pagination_buttons.push(i);
    }
    // eslint-disable-next-line prefer-destructuring
    this.selected_class = this.dataset.labelmap[0];
    this.$root.$on('unlabelled-images-switched', (u) => {
      this.unlabelled_images_switch = u;
      if (this.dataset.labels.includes(this.new_ext(this.loaded_image_name, 'json'))) {
        if (!this.unlabelled_images_switch) {
          this.load_image(this.loaded_image_name);
        } else {
          this.load_image(this.images_without_labels[0]);
        }
      } else {
        this.load_image(this.loaded_image_name);
      }
    });
  },
  beforeDestroy() {
    this.$root.$off('unlabelled-images-switched');
  },
  computed: {
    mouse_coordinates() {
      const x = this.debug_scaled_mouse_x.toString();
      const y = this.debug_scaled_mouse_y.toString();
      return `(${x.padStart(4)};${y.padStart(4)})`;
    },
    isLastOrNoUnlabelledImage() {
      if (this.unlabelled_images_switch) {
        if (this.images_without_labels.length === 0) {
          return true;
        }
        return this.active_image_index === this.images_without_labels.length - 1;
      }
      return this.active_image_index === this.dataset.images.length - 1;
    },
    isFirstOrNoUnlabelledImage() {
      if (this.unlabelled_images_switch) {
        if (this.images_without_labels.length === 0) {
          return true;
        }
      }
      return this.active_image_index === 0;
    },
  },
  methods: {
    contrast_color(color) {
      const colorInput = parseInt(color.replace('#', ''), 16);
      const colorWhite = '#FFFFFF';
      const mid = '#808080';
      if (colorWhite - colorInput > mid) {
        return '#FFFFFF';
      }
      return '#000000';
    },
    handleErrors(response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response;
    },
    onKeyUp(e) {
      if (e.key === 'Delete') {
        if (this.selected_rect_index >= this.edited_labels.length || this.selected_rect_index < 0) {
          return;
        }
        this.remove_label_from_list(this.selected_rect_index);
      } else if (e.key === 'ArrowRight') {
        this.next_image();
      } else if (e.key === 'ArrowLeft') {
        this.prev_image();
      } else {
        // TBD
      }
    },
    clear_labels() {
      for (let i = 0; i < this.overlay_layer.getChildren().length; i += 1) {
        if (!(this.overlay_layer.getChildren()[i] instanceof Konva.Transformer)) {
          this.overlay_layer.getChildren()[i].destroy();
        }
      }
      this.overlay_layer.removeChildren();
      this.transformer_added = false;
      for (let i = 0; i < this.edited_labels.length; i += 1) {
        if (this.edited_labels[i].bbox !== undefined) {
          this.edited_labels[i].bbox.destroy();
        }
      }
      this.saved_labels_info = [];
      this.edited_labels = [];
    },
    remove_label_from_list(index) {
      const deleted = this.edited_labels.splice(index, 1)[0];
      let updateTransformerNodes = false;
      this.transformer.nodes().forEach((node) => {
        if (node.id() === deleted.bbox.id()) {
          updateTransformerNodes = true;
        }
      });
      deleted.bbox.destroy();
      if (this.edited_labels.length > 0) {
        if (updateTransformerNodes) {
          this.transformer.nodes([this.edited_labels[0].bbox]);
          this.highlighted_id = this.edited_labels[0].vtid;
        }
      } else {
        this.transformer.visible(false);
      }
    },
    new_ext(s, ext) {
      const pos = s.includes('.') ? s.lastIndexOf('.') : s.length;
      const fileName = s.substr(0, pos);
      const output = `${fileName}${(ext.startsWith('.') ? ext : `.${ext}`)}`;
      return output;
    },
    labelsChanged() {
      // TODO: evtl edited_labels direkt mit overlay_layer vergleichen? =>
      //  würde funktionieren (geprüft)
      // dadurch bräuchte man saved_labels_info nicht mehr
      if (this.leave_confirmed) {
        this.leave_confirmed = false;
        return false;
      }
      if (this.saved_labels_info.length !== this.edited_labels.length) {
        this.$root.$emit('labels-changed');
        return true;
      }
      for (let i = 0; i < this.saved_labels_info.length; i += 1) {
        if ((this.saved_labels_info[i].rect.x !== this.edited_labels[i].bbox.position().x)
          || (this.saved_labels_info[i].rect.y !== this.edited_labels[i].bbox.position().y)) {
          this.$root.$emit('labels-changed');
          return true;
        }
        if ((this.saved_labels_info[i].rect.width !== this.edited_labels[i].bbox.size().width)
          || (this.saved_labels_info[i].rect.height !== this.edited_labels[i].bbox.size().height)) {
          this.$root.$emit('labels-changed');
          return true;
        }
      }
      this.$root.$emit('labels-unchanged');
      return false;
    },
    next_image() {
      if (this.labelsChanged()) {
        if (!window.confirm('Do you really want to leave? You have unsaved changes!')) {
          return;
        }
        this.leave_confirmed = true;
      }
      if (this.active_image_index < this.dataset.images.length - 1) {
        let nextImageName = '';
        if (!this.unlabelled_images_switch) {
          nextImageName = this.dataset.images[this.active_image_index + 1];
        } else {
          nextImageName = this.images_without_labels[this.active_image_index + 1];
        }
        this.load_image(nextImageName);
        this.active_image_index += 1;
      }
    },
    next_image_without_label() {
      if (this.labelsChanged()) {
        if (!window.confirm('Do you really want to leave? You have unsaved changes!')) {
          return;
        }
        this.leave_confirmed = true;
      }
      const nextImageName = this.images_without_labels[0];
      if (this.loaded_image_name !== nextImageName) {
        this.load_image(nextImageName);
      }
    },
    prev_image() {
      if (this.labelsChanged()) {
        if (!window.confirm('Do you really want to leave? You have unsaved changes!')) {
          return;
        }
        this.leave_confirmed = true;
      }
      if (this.active_image_index > 0) {
        let nextImageName = '';
        if (!this.unlabelled_images_switch) {
          nextImageName = this.dataset.images[this.active_image_index - 1];
        } else {
          nextImageName = this.images_without_labels[this.active_image_index - 1];
        }
        this.load_image(nextImageName);
        this.active_image_index -= 1;
      }
    },
    start_drag(e) {
      if ((e.evt.button !== 0) || (!this.stage)) {
        return;
      }
      const x = e.evt.layerX;
      const y = e.evt.layerY;
      const hit = this.stage.getIntersection({ x, y });
      if (hit) {
        return;
      }
      this.transformer.nodes([]);
      this.highlighted_id = '';
      this.dragging = true;
      this.first_mouse_move = false;
    },
    update_drag(e) {
      this.debug_scaled_mouse_x = Math.round(e.evt.layerX / this.scale_x);
      this.debug_scaled_mouse_y = Math.round(e.evt.layerY / this.scale_y);
      if (!this.dragging) {
        return;
      }
      // if (e.evt.layerX > this.stage!.width() - 5) e.evt.stopPropagation();
      if (!this.first_mouse_move) {
        this.first_mouse_move = true;
        this.current_classname = this.dataset.labelmap[this.active_class_index].Name;
        this.current_classcolor = this.dataset.colortable[
          this.class_index_from_labelmap(this.current_classname)];
        this.selection_box = new Konva.Rect({ fill: this.current_classcolor, opacity: 0.3, name: 'selection_box' });
        this.selection_box.visible(true);
        this.selection_layer.add(this.selection_box);
        this.pos_start = { x: e.evt.layerX, y: e.evt.layerY };
        this.pos_now = this.pos_start;
        this.transformer.visible(false);
        this.add_transformer_to_layer();
      }
      if (!this.selection_box) {
        return;
      }
      this.pos_now = { x: e.evt.layerX, y: e.evt.layerY };
      const posRect = this.reverse(this.pos_start, this.pos_now);
      this.selection_box.position({ x: posRect.x, y: posRect.y });
      this.selection_box.size({ width: posRect.width, height: posRect.height });
      this.debug_scaled_topleft_x = Math.round(posRect.x / this.scale_x);
      this.debug_scaled_topleft_y = Math.round(posRect.y / this.scale_y);
      this.debug_scaled_bottomright_x = Math.round((posRect.x / this.scale_x)
        + (posRect.width / this.scale_x));
      this.debug_scaled_bottomright_y = Math.round((posRect.y / this.scale_y)
        + (posRect.height / this.scale_y));
      this.debug_scaled_width = this.debug_scaled_bottomright_x - this.debug_scaled_topleft_x;
      this.debug_scaled_height = this.debug_scaled_bottomright_y - this.debug_scaled_topleft_y;
    },
    stop_drag(/* e */) {
      this.dragging = false;
      if (!this.first_mouse_move || !this.selection_box) {
        return;
      }
      this.first_mouse_move = false;
      const vtid = this.make_id();
      const boxColor = this.selection_box.fill();
      const boxSize = this.selection_box.size();
      const boxPosition = this.selection_box.position();
      this.selection_layer.destroyChildren();
      this.selection_box.destroy();
      if (boxSize.width < 20 || boxSize.height < 20) {
        return;
      }
      const konvaRect = new Konva.Rect({
        name: vtid,
        fill: boxColor,
        opacity: 0.3,
        visible: true,
        draggable: true,
        x: boxPosition.x,
        y: boxPosition.y,
        width: boxSize.width,
        height: boxSize.height,
      });
      this.apply_rect_event_handler(konvaRect);
      this.overlay_layer.add(konvaRect);
      const vtLabel = {
        vtid,
        classname: this.current_classname,
        color: this.current_classcolor,
        bbox: konvaRect,
      };
      this.transformer.nodes([konvaRect]); // MUSS NACH HINZUFÜGEN ZUM OVERLAY_LAYER ERFOLGEN!!!!!
      // WENN DAVOR => EXCEPTION
      this.transformer.visible(true);
      this.add_label_to_list(vtLabel);
      this.selected_rect_index = this.edited_labels.length - 1;
      this.debug_number = konvaRect.id();
    },
    class_index_from_labelmap(classname) {
      let result = -1;
      this.dataset.labelmap.forEach((element, index) => {
        if (element.Name === classname) {
          result = index;
        }
      });
      return result;
    },
    highlight_label_in_list(index) {
      if (index >= this.edited_labels.length || index < 0) {
        return;
      }
      this.transformer.nodes([this.edited_labels[index].bbox]);
      this.highlighted_id = this.edited_labels[index].vtid;
    },
    can_continue() {
      return !this.labelsChanged;
    },
    labels_saved() {
      this.saved_labels_info.length = 0;
      this.edited_labels.forEach((obj) => {
        this.saved_labels_info.push({
          name: obj.vtid,
          rect: {
            x: obj.bbox.position().x,
            y: obj.bbox.position().y,
            width: obj.bbox.size().width,
            height: obj.bbox.size().height,
          },
        });
        const labelName = this.new_ext(this.loaded_image_name, 'json');
        if (!this.dataset.labels.includes(labelName)) {
          this.dataset.labels.push(labelName);
        }
      });
      this.images_without_labels = this.images_without_labels.filter((v) => v !== this.loaded_image_name);
      const ds = this.dataset;
      this.$store.dispatch('user/setDataset', { ds });
    },
    labels_deleted() {
      this.saved_labels_info.length = 0;
      const ds = this.dataset;
      this.$store.dispatch('user/setDataset', { ds });
    },
    save_labels() {
      if (this.labelsOverlapped()) {
        this.$root.$emit('labels-overlapped');
        return;
      }
      if (this.labels_outside_of_canvas) {
        this.$root.$emit('labels-outside-of-canvas');
        return;
      }
      if (this.has_mask_labels) {
        if (!window.confirm('This image has mask labels. If you save your changes, the labels will be overwritten with bounding box labels!')) {
          return;
        }
      }
      const labelFile = this.new_ext(this.loaded_image_name, 'json');
      if (this.edited_labels.length > 0) {
        const labels = [];
        this.edited_labels.forEach((obj) => {
          const scaledX = Math.round(obj.bbox.x() / this.scale_x);
          const scaledY = Math.round(obj.bbox.y() / this.scale_y);
          // INFO: "Transforming tool is not changing width and height properties of nodes when
          // you resize them. Instead it changes scaleX and scaleY properties."
          const scaledW = Math.round((obj.bbox.width() * obj.bbox.scaleX()) / this.scale_x);
          const scaledH = Math.round((obj.bbox.height() * obj.bbox.scaleY()) / this.scale_y);
          labels.push({
            Comment: 'Created with VT label tool',
            ClassName: obj.classname,
            Left: scaledX,
            Top: scaledY,
            Right: scaledX + scaledW,
            Bottom: scaledY + scaledH,
            Tag: 0,
            Segmentation: {
              Type: 'Rect',
              Data: [
                scaledX,
                scaledY,
                scaledX + scaledW,
                scaledY + scaledH,
              ],
            },
          });
        });
        saveLabel(this.dataset.machine, this.dataset.name, labelFile, labels)
          .then(() => {
            this.labels_saved();
            this.$root.$emit('labels-saved');
            const delIndex = this.images_without_labels.indexOf(this.loaded_image_name);
            if (delIndex >= 0) {
              this.images_without_labels.splice(delIndex, 1);
            }
            this.leave_confirmed = true;
            this.has_mask_labels = false;
            this.load_image(this.images_without_labels[0]);
          })
          .catch((error) => {
            console.log('error', error);
          })
          .finally(() => {
          });
      } else {
        deleteLabel(this.dataset.machine, this.dataset.name, labelFile)
          .then(() => {
            const deletedLabelIndex = this.dataset.labels
              .findIndex((value) => value === labelFile);
            this.dataset.labels.splice(deletedLabelIndex, 1);
            this.images_without_labels.push(this.loaded_image_name);
            this.labels_deleted();
          })
          .catch((error) => {
            console.log('error', error);
          })
          .finally(() => {
          });
      }
    },
    add_transformer_to_layer() {
      if (!this.transformer_added) {
        this.overlay_layer.add(this.transformer);
      }
      this.transformer_added = true;
    },
    set_active_class(obj) {
      this.active_class_index = obj.Index;
    },
    add_label_to_list(lbl) {
      this.highlighted_id = lbl.vtid;
      this.edited_labels.push(lbl);
    },
    collides(r1, r2) {
      if ((r1.x() < r2.x() + r2.width())
        && (r1.x() + r1.width() > r2.x())
        && (r1.y() < r2.y() + r2.height())
        && (r1.height() + r1.y() > r2.y())) {
        return true;
      }
      return false;
    },
    rand_char_from(s) {
      return s.charAt(Math.floor(Math.random() * s.length));
    },
    make_id(len) {
      let result = '';
      let actualLen = 0;
      if (len === undefined) {
        actualLen = 8;
      } else {
        actualLen = len;
      }
      const charactersLc = 'abcdefghijklmnopqrstuvwxyz';
      const charactersUc = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const charactersAn = '0123456789';
      result = this.rand_char_from(charactersLc);
      for (let i = 0; i < actualLen - 1; i += 1) {
        if (i % 2 === 0) {
          if (Math.random() > 0.2) {
            result += this.rand_char_from(charactersUc);
          } else {
            result += this.rand_char_from(charactersAn);
          }
        } else {
          result += this.rand_char_from(charactersLc);
        }
      }
      return result;
    },
    reverse(topleft, bottomright) {
      let r1x = topleft.x;
      let r1y = topleft.y;
      let r2x = bottomright.x;
      let r2y = bottomright.y;
      let d;
      if (r1x > r2x) {
        d = Math.abs(r1x - r2x);
        r1x = r2x;
        r2x = r1x + d;
      }
      if (r1y > r2y) {
        d = Math.abs(r1y - r2y);
        r1y = r2y;
        r2y = r1y + d;
      }
      return {
        x: r1x, y: r1y, width: r2x - r1x, height: r2y - r1y,
      };
    },
    restore_scene_from_labels_file(labels) {
      this.add_transformer_to_layer();
      labels.forEach((obj, index) => {
        const color = this.dataset.colortable[this.class_index_from_labelmap(obj.ClassName)];
        const scaledX = obj.Left * this.scale_x;
        const scaledY = obj.Top * this.scale_y;
        const scaledW = (obj.Right - obj.Left) * this.scale_x;
        const scaledH = (obj.Bottom - obj.Top) * this.scale_y;
        const vtid = this.make_id();
        const konvaRect = new Konva.Rect({
          name: vtid,
          fill: color,
          opacity: 0.3,
          draggable: true,
          x: scaledX,
          y: scaledY,
          width: scaledW,
          height: scaledH,
        });
        this.apply_rect_event_handler(konvaRect);
        const lbl = {
          vtid,
          classname: obj.ClassName,
          color,
          bbox: konvaRect,
        };
        this.overlay_layer.add(konvaRect);
        this.add_label_to_list(lbl);
        this.saved_labels_info.push({
          name: vtid,
          rect: {
            x: scaledX,
            y: scaledY,
            width: scaledW,
            height: scaledH,
          },
        });
        if (index === 0) {
          this.transformer.nodes([konvaRect]); // MUSS NACH HINZUFÜGEN ZUM
          // OVERLAY_LAYER ERFOLGEN!!!!! WENN DAVOR => EXCEPTION
          this.debug_scaled_topleft_x = Math.round(scaledX / this.scale_x);
          this.debug_scaled_topleft_y = Math.round(scaledY / this.scale_y);
          this.debug_scaled_bottomright_x = Math.round((scaledX / this.scale_x)
            + (scaledW / this.scale_x));
          this.debug_scaled_bottomright_y = Math.round((scaledY / this.scale_y)
            + (scaledH / this.scale_y));
          this.debug_scaled_width = this.debug_scaled_bottomright_x - this.debug_scaled_topleft_x;
          this.debug_scaled_height = this.debug_scaled_bottomright_y - this.debug_scaled_topleft_y;
          this.selected_rect_index = 0;
        }
        if (obj.Segmentation.Type === 'Mask') {
          this.has_mask_labels = true;
        }
      });
      this.overlay_layer.draw();
      this.highlighted_id = this.edited_labels[0].vtid;
    },
    load_image(imageName) {
      if (!this.dataset.images.includes(imageName)) {
        return;
      }
      if (this.labelsChanged()) {
        if (!window.confirm('Do you really want to leave? You have unsaved changes!')) {
          return;
        }
        this.leave_confirmed = true;
      }
      getImage(this.machine, this.dataset.name, imageName)
        .then(
          (response) => {
            const imageObj = new Image();
            this.loaded_image_url = URL.createObjectURL(response.data);
            imageObj.onload = () => {
              const image = new Konva.Image({
                x: 0,
                y: 0,
                name: imageName,
                image: imageObj,
                draggable: false,
              });

              const { images } = this.dataset;
              let imageIndex = '';
              if (this.unlabelled_images_switch) {
                imageIndex = this.images_without_labels
                  .findIndex((value /* ,index,obj */) => value === imageName);
              } else {
                imageIndex = images.findIndex((value /* ,index,obj */) => value === imageName);
              }
              this.loaded_image_name = imageName;
              if (imageIndex > -1) {
                this.active_image_index = imageIndex;
              }

              if (this.active_class_index === undefined) {
                this.active_class_index = 0;
              }
              sessionStorage.setItem('active_image_index', this.active_class_index);

              const imageWidth = image.width();
              const imageHeight = image.height();
              this.loaded_image_resolution_x = imageWidth;
              this.loaded_image_resolution_y = imageHeight;
              this.scale_x = this.canvas_width / imageWidth;
              this.scale_y = this.canvas_height / imageHeight;
              image.scale({ x: this.scale_x, y: this.scale_y });
              this.image_layer.destroyChildren();
              this.image_layer.add(image);
              this.image_layer.draw();
              const labelFileName = this.new_ext(this.loaded_image_name, 'json');
              if (this.dataset.labels.includes(labelFileName)) {
                getLabel(this.dataset.machine, this.dataset.name, labelFileName)
                  .then((resp) => {
                    // this.handleErrors(response);
                    const respData = resp.data;
                    this.clear_labels();
                    this.restore_scene_from_labels_file(respData);
                  })
                  .catch((error) => {
                    console.log('error', error);
                  })
                  .finally(() => {
                  });
              } else {
                this.clear_labels();
              }
              this.$refs.imglst.update_active_page(Math.ceil((imageIndex + 1) / this.per_page));

              URL.revokeObjectURL(this.loaded_image_url);
            };
            imageObj.src = this.loaded_image_url;
          },
        );
    },
    apply_rect_event_handler(r) {
      r.on('dragstart', (e) => { this.onRectDragStart(e); });
      r.on('dragmove', this.onRectDragMove.bind(this));
      r.on('dragend', (e) => { this.onRectDragEnd(e); });
      r.on('mousedown', this.onRectMouseDown.bind(this));
      r.on('mouseup', this.onRectMouseUp.bind(this));
      // r.on('mouseenter', this.onRectMouseEnter.bind(this));
      // TODO: cursor-change fixen (transformer anchors haben falschen cursor wenn
      // das hier aktiv ist)
      // r.on('mouseleave', this.onRectMouseLeave.bind(this));
    },
    onRectDragStart(/* e */) { },
    onRectDragMove(e) {
      this.labels_outside_of_canvas = false;
      const rect = (e.target);
      if (rect.position().x <= 0
        || (rect.position().x + rect.size().width) >= this.canvas_width
        || rect.position().y <= 0
        || (rect.position().y + rect.size().height) >= this.canvas_height) {
        rect.stopDrag();
      }
      if (rect.position().x < 0
        || (rect.position().x + rect.size().width) > this.canvas_width
        || rect.position().y < 0
        || (rect.position().y + rect.size().height) > this.canvas_height) {
        this.labels_outside_of_canvas = true;
      }
    },
    onRectDragEnd(/* e */) { },
    onRectMouseDown(e) {
      if ((!this.transformer) || (!e.target)) {
        return;
      }
      const rect = e.target;
      // eslint-disable-next-line max-len, no-underscore-dangle
      this.selected_rect_index = this.edited_labels.findIndex((label) => label.bbox._id === rect._id);
      this.transformer.nodes([rect]);
      this.transformer.visible(true);
      this.highlighted_id = rect.name();
    },
    onRectMouseUp(/* e */) { },
    onRectMouseEnter(e) { e.evt.target.style.cursor = 'pointer'; },
    onRectMouseLeave(e) { e.evt.target.style.cursor = 'auto'; },
    item_highlight(v) { this.highlighted = v; },
    on_item_remove_click(index) {
      if (index > -1) {
        this.remove_label_from_list(index);
      }
    },
    on_item_click(index) {
      if (index > -1) {
        this.highlight_label_in_list(index);
      }
    },
    backToConfig() {
      this.$root.$emit('back-to-config');
    },
    labelsOverlapped() {
      const list1 = this.overlay_layer.getChildren();
      const list2 = this.overlay_layer.getChildren();
      let result = false;
      list1.forEach((shape) => {
        for (let i = 0; i < list2.length; i += 1) {
          // eslint-disable-next-line no-underscore-dangle
          if ((shape.className === 'Rect') && (list2[i].className === 'Rect') && (shape._id !== list2[i]._id)) {
            if (this.collides(shape, list2[i])) {
              result = true;
              return;
            }
          }
        }
      });
      return result;
    },
  },
};
</script>
