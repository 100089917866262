function createUser(user) {
  const data = {
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    mobile_number: user.mobile_number,
    is_active: true,
    can_start_trainings: user.can_start_trainings,
    can_manage_filestorage: user.can_manage_filestorage,
  };

  return window.$http({
    url: '/api/v1/customer/user',
    method: 'post',
    data,
  });
}

export default createUser;
