<template>
  <v-data-table
    :headers="headers"
    :loading="loading"
    :items="trainingsFiltered"
    disable-sort
    hide-default-footer
    hide-default-header
    class="elevation-0 training-results notranslate"
    translate="no"
  >
    <template v-slot:item.created_at="{ item }">
      {{ format_date(item.created_at) }}
    </template>
    <template v-slot:item.action="{ item }">
      <v-btn class="ml-lg-15" small @click="onDownloadResultTraining(item)"
             color="#21BA45">
        <v-icon>mdi-download</v-icon>Download
      </v-btn>
    </template>
    <template v-slot:footer="">
    </template>
  </v-data-table>
</template>

<script>

import getTrainings from '@/api/user/getTrainings';
import axios from 'axios';

export default {
  name: 'TrainingResults',
  data() {
    return {
      trainings: [],
      trainingsFiltered: [],
      test: [],
      dialog: false,
      loading: true,
      canStartTrainings: false,
      headers: [
        { text: '', align: 'start', value: 'created_at' },
        { text: '', align: 'start', value: 'title' },
        { text: '', align: 'end', value: 'action' },
      ],
      maxResults: 5,
      bearer: '',
      apiUrl: process.env.VUE_APP_ROOT_API ? process.env.VUE_APP_ROOT_API.trim().replace(/\/+$/, '') : '',
    };
  },
  created() {
    this.canStartTrainings = this.$store.getters['user/canStartTrainings'];
    if (this.canStartTrainings) {
      this.fetchTrainings();
    }
    const bearer = this.$store.getters['user/token'];
    this.bearer = bearer.replace('bearer ', '');
  },
  methods: {
    fetchTrainings() {
      getTrainings(0, 'completed')
        .then((response) => {
          this.trainings = response.data.data;
          let trainingsFinishedCount = 0;
          this.trainings.every((training) => {
            this.trainingsFiltered.push(training);
            trainingsFinishedCount += 1;
            return trainingsFinishedCount < this.maxResults;
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onDownloadResultTraining(training) {
      this.$store.dispatch('dialogloader/show', 'Downloading in progress...');
      const config = {
        responseType: 'json',
        headers: {
          Authorization: `Bearer ${this.bearer}`,
        },
      };
      const url = `${this.apiUrl}/api/v1/training/download-result/get-presigned-url/${training.id}`;
      axios
        .get(url, config)
        .then((response) => {
          const presignedUrl = response.data.presigned_url;
          const link = document.createElement('a');
          const disposition = response.headers['content-disposition'];
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          let filename = 'download';
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
          link.href = presignedUrl;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => console.log('ERROR: downloadResultTraining', error));
      this.$store.dispatch('dialogloader/hide');
    },
  },
};
</script>
<style lang="scss">
.training-results {
  height: 68%;
  .v-data-table__wrapper {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
}
</style>
