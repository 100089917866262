<template>
  <v-card v-if="layout !== 'free'"
          :class="(route === '' || disabled === true) ? 'notavailable' : 'available'">
    <div class="v-card-inner d-flex flex-row mb-6" @click="changeRoute(route)"
         v-if="route !== ''">
      <div class="icon-wrap">
        <v-img
          class="v-card-icon"
          height="80"
          width="80"
          :src="require(`@/assets/icons/${iconsvg}`)"
        ></v-img>
      </div>
      <div class="">
        <v-card-title class="flex justify-space-between">
        <div class="text-left dashboard__stats">
          <h3 class="text-h6">{{ title }}</h3>
        </div>
      </v-card-title>
        <v-card-subtitle>
          <div class="text-left">
            {{ description }}
          </div>
        </v-card-subtitle>
      </div>
    </div>
    <div class="v-card-inner d-flex flex-row mb-6" v-else>
      <v-overlay
        :absolute="true"
        opacity="0.7"
        value="false"
        :dark="false"
        :light="true"
        color="#ffffff"
        z-index="0"
      >
      </v-overlay>
      <div class="icon-wrap">
        <v-img
          class="v-card-icon"
          height="80"
          width="80"
          :src="require(`@/assets/icons/${iconsvg}`)"
        ></v-img>
      </div>
      <div class="">
        <v-card-title class="flex justify-space-between">
          <div class="text-left dashboard__stats">
            <h3 class="text-h6">{{ title }}</h3>
          </div>
        </v-card-title>
        <v-card-subtitle>
          <div class="text-left">
            {{ description }}
          </div>
        </v-card-subtitle>
      </div>
    </div>
  </v-card>
  <v-card v-else class="dashboard__card-placeholder">
  </v-card>
</template>

<script>
export default {
  name: 'DashboardCard',
  props: {
    layout: {
      type: String,
      required: false,
    },
    inactives: {
      type: String,
      required: false,
    },
    iconsvg: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    route: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    changeRoute(route) {
      // `route` is either a string or object
      this.$router.push(route);
    },
  },
};
</script>

<style lang="scss">
.dashboard {
  .v-card.v-sheet {
    color: white;
    min-height: 168px;
    background-color: #CE0F69;
    box-shadow: 5px 5px 10px #222222;
    &.available {
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        padding-left: 10px;
        .v-card-inner {
          background-color: #555555;
        }
      }
    }
    &.notavailable {
      box-shadow: unset;
      background-color: unset;
    }
    .v-card__subtitle {
      color:inherit;
    }
    .v-card-inner {
      min-height: 168px;
      background-color: #777777;
    }
    .v-card-icon {
      margin: 14px 0 14px 14px;
      fill: rgb(1,1,1);
      filter: brightness(100);
    }
  }
  &__stats {
    position: relative;
    .text-h6 {
      font-size: 21px;
      margin-bottom: 10px;
    }
    @media (max-width: 1263px) {
      width: 100%;
    }

    &-title {
      white-space: nowrap;
    }
  }
  &__card-placeholder.v-card.v-sheet {
    box-shadow: unset;
    border-radius: unset;
    background-color: #efefef;
    border: #cccccc dashed 2px;
  }
}
</style>
