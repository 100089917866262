function saveDataset(machineId, dataset) {
  const data = {
    name: dataset.name,
    labelmap: dataset.labelmap,
    colortable: dataset.colortable,
  };
  return window.$http({
    url: `/api/v1/labelling/machine/${machineId}/dataset`,
    method: 'post',
    data,
  });
}

export default saveDataset;
