var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.use_list)?_c('v-list-item',{class:_vm.highlighted?'highlighted':''},[_c('v-chip',{staticStyle:{"min-width":"100px"},attrs:{"color":_vm.color,"outlined":"","pill":"","close-icon":"mdi-delete","close":""},on:{"click":function($event){return _vm.$emit('item-click', _vm.index)},"click:close":function($event){return _vm.$emit('item-remove-click', _vm.index)}}},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1):_c('div',{staticStyle:{"display":"table","width":"100%"},on:{"click":function($event){return _vm.$emit('item-click', _vm.index)}}},[_c('div',{staticStyle:{"display":"table-row","border-radius":"5px","cursor":"pointer","width":"80%"}},[_c('div',{class:(_vm.highlighted ? 'highlighted' : ''),staticStyle:{"display":"table-cell"}},[_c('span',{style:({
        'width': '24px',
        'max-height': '5px',
        'border-radius': '4px',
        'border': '1px solid black',
        'margin-left': '5px',
        'background-color': _vm.color,
        'color': _vm.color
      })},[_vm._v(" ___ ")]),_c('span',{staticStyle:{"padding-left":"5px"}},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('div',{staticStyle:{"display":"table-cell","width":"20%"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('item-remove-click', _vm.index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }