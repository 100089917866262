<template>
  <v-container>
    <v-row>
      <v-col class="mt-14" cols="10" sm="4">
        <h2>Downloads</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          disable-sort
          class="elevation-1"
          hide-default-footer
          disable-pagination
        >

          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Files</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>

          <template v-slot:item.created_at="{ item }">
            {{ format_date(item.created_at, true) }} h
          </template>

          <template v-slot:item.actions="{ item }">
            <div class="d-flex flex-nowrap">
              <v-btn icon small @click="onDownload(item)">
                <v-icon small>mdi-cloud-download</v-icon>
              </v-btn>
              <v-btn icon small @click="onDelete(item)"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <dialog-confirm ref="confirm"/>
  </v-container>
</template>

<script>
import DialogConfirm from '@/components/DialogConfirm.vue';
import getAllDownloads from '@/api/filemanager/downloads';
import axios from 'axios';
import deleteDownload from '@/api/filemanager/deleteDownload';

export default {
  name: 'DownloadsPage',
  components: {
    DialogConfirm,
  },
  data() {
    return {
      dialog: false,
      bearer: null,
      apiUrl: process.env.VUE_APP_ROOT_API ? process.env.VUE_APP_ROOT_API.trim().replace(/\/+$/, '') : '',
      items: [],
      loading: false,
      headers: [
        { text: '#', align: 'start', value: 'index' },
        { text: 'Filename', value: 'zip_filename' },
        { text: 'Size', value: 'size' },
        { text: 'Created at', value: 'created_at' },
        { text: 'User', value: 'user' },
        { text: 'Actions', value: 'actions' },
      ],
    };
  },
  created() {
    const bearer = this.$store.getters['user/token'];
    this.bearer = bearer.replace('bearer ', '');
    this.fetchDownloads();
  },
  methods: {
    fetchDownloads() {
      this.loading = true;
      getAllDownloads()
        .then((response) => {
          this.items = response.data.map(
            (item, index) => ({
              ...item,
              index: index + 1,
            }),
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onDownload(item) {
      this.$refs.confirm.open('Download file', `Download file ${item.zip_filename} ?`)
        .then((confirm) => {
          if (confirm) {
            const msg = `The file ${item.zip_filename} is being downloaded...`;
            this.$store.dispatch('notificationbar/showNotification', {
              msg,
              color: 'info',
              show: true,
            });
            this.downloadZip(item.id, item.zip_filename);
          }
        });
    },
    onDelete(item) {
      this.$refs.confirm.open('Delete download', `Are you sure to delete ${item.zip_filename}?`)
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch('dialogloader/show', 'The download is being deleted...');
            deleteDownload(item.id)
              .then(() => {
                const msg = `The download  ${item.zip_filename} has been deleted`;
                this.$store.dispatch('notificationbar/showNotification', {
                  msg,
                  color: 'info',
                  show: true,
                });
                this.fetchDownloads();
                this.closeDialog();
              })
              .finally(() => this.$store.dispatch('dialogloader/hide'))
              .catch((err) => {
                this.$store.dispatch('notificationbar/showNotification', { msg: err.response.data[0], color: 'error', show: true });
              });
          }
        });
    },
    downloadZip(fileId, fileName) {
      const url = `${this.apiUrl}/api/v1/filemanager/get-presigned-url/${fileId}`;
      const config = {
        headers: {
          responseType: 'json',
          Authorization: `Bearer ${this.bearer}`,
        },
      };
      axios
        .get(url, config)
        .then((response) => {
          const presignedUrl = response.data.presigned_url;
          const link = document.createElement('a');
          link.href = presignedUrl;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          const msg = this.checkForErrorMassage(error, 'Unable to download file');
          this.$store.dispatch('notificationbar/showNotification', {
            msg,
            color: 'error',
            show: true,
          });
        });
    },
    checkForErrorMassage(error, defaultMessage) {
      let msg = defaultMessage;
      const errorData = error.response.data;
      if ((typeof errorData === 'string' || errorData instanceof String)) {
        if (errorData.trim().length > 0) {
          msg = errorData;
        }
      } else if (Array.isArray(errorData)) {
        if (errorData.join(',').trim().length > 0) {
          msg = errorData.join(',');
        }
      }
      return msg;
    },
    prepareIframe(name) {
      const ifrm = document.createElement('iframe');
      ifrm.setAttribute('name', name);
      ifrm.style.width = '0px';
      ifrm.style.height = '0px';
      document.body.appendChild(ifrm);
    },
    existsIframe(name) {
      const iframes = document.getElementsByName(name);
      for (let i = 0; i < iframes.length; i += 1) {
        if (iframes[i].getAttribute('name') === name) {
          return true;
        }
      }
      return false;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>
