<template>
  <div>
    <v-row class="justify-center my-auto">
      <v-col v-if="isPrimary && !isOnlyUser"
        cols="3" class="text-center">
        <v-progress-circular
          :rotate="-90"
          :size="100"
          :width="3"
          :value="userUsedPercent"
          color="#21BA45"
        >
          <div class="value">{{usersLength}}/{{this.currentCompany.pricing.seats
             + this.currentCompany.pricing.more_user}}</div>
        </v-progress-circular>
        <div class="circle-label pt-7">Users</div>
      </v-col>
      <v-col cols="3" class="text-center">
        <v-progress-circular
          :rotate="-90"
          :size="100"
          :width="3"
          :value="spaceUsedPercent"
          :indeterminate="loadingUsedSpace"
          :color="progressBarColor(spaceUsedPercent)"
        >
          <div class="value">{{usedSpace}}/{{ volumeSize }}</div>
        </v-progress-circular>
        <div class="circle-label pt-7">Storage GB</div>
      </v-col>
      <v-col cols="3" class="text-center">
        <v-progress-circular
          :rotate="-90"
          :size="100"
          :width="3"
          :value="minutesUsedPercent"
          :color="progressBarColor(minutesUsedPercent, 'minutes')"
        >
          <div class="value"> {{usedMinutes}}/{{this.currentCompany.pricing.training_minutes
          + this.currentCompany.pricing.more_minutes}}</div>
        </v-progress-circular>
        <div class="circle-label pt-7">Minutes</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import getUsedSpace from '@/api/filemanager/getUsedSpace';
import getCompanyUsers from '@/api/customerUser/getCompanyUsers';
import calculateMinutes from '@/api/user/calculateMinutes';

export default {
  name: 'Statistics',
  data() {
    return {
      usedSpace: 0,
      volumeSize: 0,
      usersLength: null,
      currentCompany: null,
      mainCompany: null,
      userRoles: null,
      loadingUsedSpace: true,
      usedMinutes: null,
      date: new Date(),
    };
  },
  created() {
    this.currentCompany = this.$store.getters['user/company'];
    this.mainCompany = this.$store.getters['user/mainCompany'];
    this.userRoles = this.$store.getters['user/roles'];
    this.fetchUsedSpace();
    if (this.currentCompany.id === this.mainCompany.id
      && this.userRoles[0] !== 'ROLE_USER') {
      this.fetchUsers();
    }
    this.fetchUsedMinutes();
  },
  computed: {
    spaceUsedPercent() {
      if (this.volumeSize === 0) {
        return 0;
      }
      return Math.round((this.usedSpace * 100) / this.volumeSize);
    },
    minutesUsedPercent() {
      const minutesToSpend = this.currentCompany.pricing.training_minutes
        + this.currentCompany.pricing.more_minutes;
      if (this.usedMinutes === 0) {
        return 0;
      }
      return Math.round((this.usedMinutes * 100) / minutesToSpend);
    },
    userUsedPercent() {
      const userToUse = this.currentCompany.pricing.seats
        + this.currentCompany.pricing.more_user;

      if (this.usersLength === 0) {
        return 0;
      }
      return Math.round((this.usersLength * 100) / userToUse);
    },
    isPrimary: {
      get() { return this.currentCompany.id === this.mainCompany.id; },
    },
    isOnlyUser: {
      get() { return this.userRoles[0] === 'ROLE_USER'; },
    },
  },
  methods: {
    progressBarColor(item, type = '') {
      let color = '#21BA45';
      if (item >= 90) {
        color = '#FC9E00';
      }
      if (item >= 100) {
        color = '#DB2828';
        if (type === 'minutes') {
          color = '#FC9E00';
        }
      }
      return color;
    },
    fetchUsedSpace() {
      getUsedSpace()
        .then((response) => {
          this.usedSpace = Math.round((response.data.size * 10) / (1024 * 1024 * 1024)) / 10;
          this.volumeSize = this.currentCompany.pricing.storage_space
            + this.currentCompany.pricing.more_storage;
        })
        .finally(() => {
          this.loadingUsedSpace = false;
        });
    },
    fetchUsers() {
      getCompanyUsers()
        .then((response) => {
          this.usersLength = response.data.total;
        });
    },
    fetchUsedMinutes() {
      calculateMinutes(this.date.getMonth() + 1, this.date.getFullYear())
        .then((response) => {
          this.usedMinutes = response.data.minutes;
        });
    },
  },
};
</script>
