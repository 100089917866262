function getCountImages(id, datasetDirectory) {
  const data = {
    machine_id: id,
    dataset_directory: datasetDirectory,
  };
  return window.$http({
    url: `/api/v1/training/count-images?machine_id=${data.machine_id}&dataset_directory=${data.dataset_directory}`,
    method: 'get',
  });
}

export default getCountImages;
