import axios from 'axios';

function getPresignedUrl(type, item, bearer, apiUrl) {
  const { id } = item;
  const config = {
    responseType: 'json',
    headers: {
      Authorization: `Bearer ${bearer}`,
    },
  };
  const url = `${apiUrl}/api/v1/${type}/download-result/get-presigned-url/${id}`;
  return axios.get(url, config);
}

export default getPresignedUrl;
