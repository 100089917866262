<template>
  <div>
    <v-container>
      <v-row>
        <v-col class="mt-14" cols="12">
              <h2>Trainings</h2>
        </v-col>
      </v-row>
    </v-container>
    <Trainings />
  </div>
</template>

<script>

import Trainings from '@/components/trainings/Trainings.vue';

export default {
  name: 'TrainingsPage',
  components: {
    Trainings,
  },
};
</script>
<style lang="scss">

</style>
