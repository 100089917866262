<template>
  <div>
    <div v-if="loading" class="d-flex justify-center">
      <v-progress-circular
        indeterminate
        size="25"
        width="2"
        color="primary"
      ></v-progress-circular>
    </div>
    <LineChartGenerator
      v-show="!loading"
      ref="lineChart"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </div>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy';
import calculateMinutes from '@/api/user/calculateMinutes';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
);

export default {
  name: 'LineChart',
  components: {
    LineChartGenerator,
  },
  props: {
    activeMonthNr: {
      type: Array,
      default: () => [],
    },
    activeMonths: {
      type: Array,
      default: () => [],
    },
    chartId: {
      type: String,
      default: 'line-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: true,
      activeMinutes: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      date: new Date(),
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Minutes',
            backgroundColor: '#CE0F69',
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.getChartData();
  },
  methods: {
    async getChartData() {
      const activeMonthsShow = [];
      let dates = null;
      const months = [];
      const updateChartDataset = () => {
        this.chartData.datasets[0].data = this.activeMinutes;
        this.chartData.labels = activeMonthsShow;
        this.$refs.lineChart.updateChart();
        this.loading = false;
      };
      // eslint-disable-next-line
      for (let i = 0; i < 12; i++) {
        this.loading = true;
        const month = new Date();
        month.setMonth(month.getMonth() - i);
        months.push({
          month: (month.getMonth() + 1),
          year: month.getFullYear(),
        });
        activeMonthsShow.push(`${month.toLocaleString('default', { month: 'long' })} ${month.getFullYear()}`);
      }
      dates = months.reverse();
      activeMonthsShow.reverse();
      // eslint-disable-next-line no-restricted-syntax
      for (const [index, element] of dates.entries()) {
        // eslint-disable-next-line no-await-in-loop
        const res = await calculateMinutes(element.month, element.year);
        this.activeMinutes[index] = res ? res.data.minutes : 0;
        updateChartDataset();
      }
    },
  },
};
</script>
