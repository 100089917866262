<template>
  <v-container>
    <v-row>
      <v-col class="mt-14" cols="10" sm="4">
        <h2>Users</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="users"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          disable-sort
          class="elevation-1"
          :disable-pagination="false"
        >

          <template v-slot:top>
            <v-toolbar flat class="overflow-fix" style="height: auto">
              <v-toolbar-title>User list</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items style="flex-wrap: wrap">
                <v-dialog v-model="createDialog" max-width="800px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-col>
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        block
                      >
                        <v-icon left>mdi-account-plus</v-icon>
                        Create
                      </v-btn>
                    </v-col>
                  </template>
                  <v-card>
                    <v-form @submit.prevent="onCreateItem" ref="form">
                      <v-toolbar dense flat>
                        <v-toolbar-title>{{ createDialogTitle }}</v-toolbar-title>
                      </v-toolbar>
                      <v-card-text>
                        <v-row class="justify-start mt-5">
                          <v-col cols="10" v-if="roleSelected === 'ROLE_CUSTOMER'">
                            <h3>Contact person</h3>
                          </v-col>
                        </v-row>
                        <form-error :errors="formErrors.firstname">
                        <v-text-field
                          label="First name"
                          v-model="editedItem.firstname"
                          class="mt-3"
                          :rules="$rules.required"
                        ></v-text-field>
                        </form-error>
                        <form-error :errors="formErrors.lastname">
                        <v-text-field
                          label="Last name"
                          v-model="editedItem.lastname"
                          class="mt-3"
                          :rules="$rules.required"
                        ></v-text-field>
                        </form-error>
                        <form-error :errors="formErrors.mobile_number">
                        <v-text-field
                          label="Mobile number"
                          v-model="editedItem.mobile_number"
                          :rules="$rules.required && $rules.phone"
                          class="mt-3"
                        ></v-text-field>
                        </form-error>
                        <form-error :errors="formErrors.email">
                        <v-text-field
                          label="E-mail"
                          v-model="editedItem.email"
                          :rules="$rules.required && $rules.email"
                          :disabled="isEditing"
                          validate-on-blur
                        ></v-text-field>
                        </form-error>
                      <v-row class="justify-start mt-5">
                        <v-col cols="10"
                              v-if="roleSelected === 'ROLE_CUSTOMER'
                              || roleSelected === 'ROLE_USER'">
                          <h3>Permissions</h3>
                        </v-col>
                      </v-row>
                      <form-error :errors="formErrors.can_manage_filestorage">
                      <v-switch
                        class="col-2"
                        :label="`File Manager`"
                        v-model="permissions"
                        v-if="roleSelected === 'ROLE_CUSTOMER' || roleSelected === 'ROLE_USER'"
                        :rules="[rules.noEmptyArray()]"
                        value="can_manage_filestorage"
                      ></v-switch>
                      </form-error>
                      <form-error :errors="formErrors.can_start_trainings">
                      <v-switch
                        class="col-2"
                        :label="`AI Training/Testing`"
                        v-model="permissions"
                        v-if="roleSelected === 'ROLE_CUSTOMER' || roleSelected === 'ROLE_USER'"
                        :rules="[rules.noEmptyArray()]"
                        value="can_start_trainings"
                      ></v-switch>
                      </form-error>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn type="submit" class="btn btn-radius-corner">Save</v-btn>
                        <v-btn @click="closeCreateDialog" class="btn btn-gray btn-radius-corner">
                          Cancel</v-btn>
                      </v-card-actions>
                      </v-card-text>
                    </v-form>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="inviteDialog" max-width="800px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-col>
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        block
                      >
                        <v-icon left>mdi-account-multiple-check</v-icon>
                        Invite
                      </v-btn>
                    </v-col>
                  </template>
                  <v-card>
                    <v-form @submit.prevent="onInviteItem" ref="form">
                      <v-toolbar dense flat>
                        <v-toolbar-title>Invite user</v-toolbar-title>
                      </v-toolbar>
                      <v-card-text>
                        <form-error :errors="formErrors.email">
                        <v-text-field
                          label="E-mail"
                          v-model="invitedItem.email"
                          :rules="$rules.required && $rules.email"
                          :disabled="isEditing"
                          validate-on-blur
                        ></v-text-field>
                        </form-error>
                        <v-row class="justify-start mt-5">
                          <v-col cols="10"
                                v-if="roleSelected === 'ROLE_CUSTOMER'
                                || roleSelected === 'ROLE_USER'">
                            <h3>Permissions</h3>
                          </v-col>
                        </v-row>
                        <form-error :errors="formErrors.can_manage_filestorage">
                        <v-switch
                          class="col-2"
                          :label="`File Manager`"
                          v-model="permissions"
                          v-if="roleSelected === 'ROLE_CUSTOMER' || roleSelected === 'ROLE_USER'"
                          :rules="[rules.noEmptyArray()]"
                          value="can_manage_filestorage"
                        ></v-switch>
                        </form-error>
                        <form-error :errors="formErrors.can_start_trainings">
                        <v-switch
                          class="col-2"
                          :label="`AI Training`"
                          v-model="permissions"
                          v-if="roleSelected === 'ROLE_CUSTOMER' || roleSelected === 'ROLE_USER'"
                          :rules="[rules.noEmptyArray()]"
                          value="can_start_trainings"
                        ></v-switch>
                        </form-error>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn type="submit" class="btn btn-radius-corner">Invite</v-btn>
                          <v-btn @click="closeInviteDialog"
                            class="btn btn-gray btn-radius-corner"
                          >
                            Cancel</v-btn>
                        </v-card-actions>
                      </v-card-text>
                    </v-form>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="emailDialog" max-width="800px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-col>
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        block
                      >
                        <v-icon left>mdi-email</v-icon>
                        E-mail
                      </v-btn>
                    </v-col>
                  </template>
                  <v-card>
                    <v-form @submit.prevent="onSendEmail" ref="form">
                      <v-toolbar dense flat>
                        <v-toolbar-title style="text-overflow: clip; overflow: visible;">
                          Send e-mail to all users
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-card-text>
                        <form-error :errors="formErrors.subject">
                        <v-text-field
                          label="Subject"
                          v-model="emailItem.subject"
                          class="mt-3"
                          :rules="$rules.required"
                        ></v-text-field>
                        </form-error>
                        <form-error :errors="formErrors.message">
                        <v-textarea
                          :counter="1000"
                          label="Message"
                          v-model="emailItem.message"
                          class="mt-3"
                          :rules="$rules.required && messageRules"
                        ></v-textarea>
                        </form-error>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn type="submit" class="btn btn-radius-corner">Send</v-btn>
                          <v-btn @click="closeEmailDialog" class="btn btn-gray btn-radius-corner">
                            Cancel</v-btn>
                        </v-card-actions>
                      </v-card-text>
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-toolbar-items>
            </v-toolbar>
          </template>

          <template v-slot:item.roles="{ item }">
            {{ item.roles | roles }}
          </template>

          <template v-slot:item.isActive="{  }">
            Active
          </template>

          <template v-slot:item.actions="{ item }"> <!-- item is companyUser -->
            <div class="d-flex flex-nowrap">
              <v-btn icon small @click="onEdit(item)">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon small @click="onResetPw(item)">
                <v-icon small title="Reset password and send via e-mail">mdi-email-lock</v-icon>
              </v-btn>
              <v-btn icon small @click="onDelete(item)" v-if="item.deletable">
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <DialogConfirm ref="confirm"/>
  </v-container>
</template>

<script>
import FormError from '@/components/_common/FormError.vue';
import FormErrorMixin from '@/mixins/FormErrorMixin';
import statuses from '@/variables/statuses';
import userRoles from '@/variables/userRoles';
import DialogConfirm from '@/components/DialogConfirm.vue';
import onResetPw from '@/mixins/onResetPw';
import updateUser from '@/api/user/updateUser';
import createUser from '@/api/user/createUser';
import deleteUser from '@/api/user/deleteUser';
import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin';
import getCompanyUsers from '@/api/customerUser/getCompanyUsers';
import sendInvitation from '@/api/customerUser/sendInvitation';
import sendEmail from '@/api/customerUser/sendEmail';

export default {
  name: 'UsersPage',
  components: {
    FormError,
    DialogConfirm,
  },
  mixins: [
    FormErrorMixin,
    FormValidationRulesMixin,
    onResetPw,
  ],
  props: {
    isActive: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    isEditing() {
      return this.editedIndex !== -1;
    },
    createDialogTitle() {
      return this.editedIndex === -1 ? 'Create user' : 'Edit user';
    },
    userStatus() {
      return this.editedItem.isActive ? 'Confirmed' : 'Pending';
    },
  },
  data() {
    return {
      createDialog: false,
      inviteDialog: false,
      emailDialog: false,
      editedIndex: -1,
      editedItem: {
        id: null,
        company: null,
        firstname: '',
        lastname: '',
        email: '',
        mobile_number: '',
        isActive: false,
        roles: null,
        can_start_trainings: false,
        can_manage_filestorage: false,
      },
      defaultItem: {
        id: null,
        company: null,
        firstname: null,
        lastname: null,
        email: null,
        mobile_number: null,
        isActive: false,
        roles: null,
        can_start_trainings: false,
        can_manage_filestorage: false,
      },
      invitedItem: {
        email: '',
        can_start_trainings: false,
        can_manage_filestorage: false,
      },
      defaultInvitedItem: {
        email: null,
        can_start_trainings: false,
        can_manage_filestorage: false,
      },
      emailItem: {
        subject: '',
        message: '',
      },
      defaultEmailItem: {
        subject: null,
        message: null,
      },
      userRoles: [
      ],
      currentUserRoles: [
      ],
      users: [],
      isUserLoading: false,
      userSearchInput: null,
      filter: [],
      status: null,
      loading: true,
      headers: [
        { text: '#', align: 'start', value: 'index' },
        { text: 'Company', value: 'user.main_company.name' },
        { text: 'First name', value: 'user.firstname' },
        { text: 'Last name', value: 'user.lastname' },
        { text: 'E-mail', value: 'user.email' },
        { text: 'Role', value: 'roles' },
        { text: 'Status', value: 'isActive' },
        { text: 'Actions', value: 'actions' },
      ],
      options: {
        sortBy: '',
        sortDesc: '',
        page: 1,
        itemsPerPage: 10,
      },
      totalItems: 0,
      totalPages: 0,
      itemsPerPage: 10,
      statuses: [],
      currentUser: null,
      currentUserIsAdmin: false,
      roleSelected: 'ROLE_USER',
      permissions: [],
      messageRules: [(v) => v?.length <= 1000 || 'Max 1000 characters'],
    };
  },
  created() {
    this.currentUserIsAdmin = this.$store.getters['user/isAdmin'];
    this.roleSelected = this.currentUserIsAdmin === true ? 'ROLE_CUSTOMER' : 'ROLE_USER';
    this.statuses = statuses;
    if (this.isActive !== null) {
      this.status = this.isActive;
    }
    this.userRoles = userRoles;
    this.currentUserRoles = this.$store.getters['user/roles'];
  },
  methods: {
    fetchUsers() {
      this.loading = true;
      const {
        // eslint-disable-next-line no-unused-vars
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;
      let start = 0;
      let limit = 10;
      if (itemsPerPage === -1) {
        start = 10000 * (page - 1);
        limit = 10000;
      } else {
        start = itemsPerPage * (page - 1);
        limit = itemsPerPage;
      }
      getCompanyUsers(start, limit)
        .then((response) => {
          this.users = response.data.data.map(
            (item, index) => ({
              ...item,
              index: index + 1 + start,
            }),
          );
          this.totalItems = response.data.total;
          this.totalPages = Math.ceil(response.data.total / itemsPerPage);
          this.itemsPerPage = itemsPerPage;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onCreateItem() {
      if (!this.$refs.form.validate()) return;

      if (this.editedIndex > -1) {
        this.$store.dispatch('dialogloader/show', 'The update is in progress ...');
        updateUser(this.editedItem)
          .then(() => {
            Object.assign(this.users[this.editedIndex], this.editedItem);
            this.fetchUsers();
            this.closeCreateDialog();
          })
          .finally(() => this.$store.dispatch('dialogloader/hide'))
          .catch((err) => {
            this.$store.dispatch('notificationbar/showNotification', { msg: err.response.data[0], color: 'error', show: true });
            this.mapSymfonyErrors(err.response.data.errors);
          });
      } else {
        this.$store.dispatch('dialogloader/show', 'Creating in progress...');
        createUser(this.editedItem)
          .then((response) => {
            this.editedItem.id = response.data.id;
            this.editedItem.roles = [this.roleSelected];
            this.fetchUsers();
            this.closeCreateDialog();
          })
          .finally(() => this.$store.dispatch('dialogloader/hide'))
          .catch((err) => {
            let errorMessage = '';
            if (err.response.data.errors && typeof err.response.data.errors.children === 'object') {
              const errors = err.response.data.errors.children;
              // eslint-disable-next-line no-restricted-syntax
              for (const key in errors) {
                if (errors[key].errors && Array.isArray(errors[key].errors)) {
                  errorMessage = errors[key].errors.join(', ');
                  break;
                }
              }
            } else if (Array.isArray(err.response.data) && err.response.data[0]
              && err.response.data[0].errors) {
              errorMessage = err.response.data[0].message;
            }

            if (errorMessage) {
              this.$store.dispatch('notificationbar/showNotification', { msg: errorMessage, color: 'error', show: true });
            }
            this.mapSymfonyErrors(err.response.data.errors);
          });
      }
    },
    onEdit(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = { ...item, ...item.user };
      if (this.editedItem.can_manage_filestorage) {
        if (this.permissions.indexOf('can_manage_filestorage') === -1) {
          this.permissions.push('can_manage_filestorage');
        }
      } else if (this.permissions.indexOf('can_manage_filestorage') !== -1) {
        this.permissions.splice(this.permissions.indexOf('can_manage_filestorage'), 1);
      }
      if (this.editedItem.can_start_trainings) {
        if (this.permissions.indexOf('can_start_trainings') === -1) {
          this.permissions.push('can_start_trainings');
        }
      } else if (this.permissions.indexOf('can_start_trainings') !== -1) {
        this.permissions.splice(this.permissions.indexOf('can_start_trainings'), 1);
      }
      this.createDialog = true;
    },
    onDelete(item) {
      this.$refs.confirm.open('Delete user', 'Delete user?')
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch('dialogloader/show', 'The user is being deleted...');
            deleteUser(item.user.id)
              .then(() => {
                const msg = `The user  ${item.user.firstname} ${item.user.lastname} (${item.user.email}) has been deleted`;
                this.$store.dispatch('notificationbar/showNotification', {
                  msg,
                  color: 'info',
                  show: true,
                });
                for (let i = 0; i < this.users.length; i += 1) {
                  if (this.users[i].user.id === item.user.id) {
                    this.users.splice(i, 1);
                  }
                }
                this.fetchUsers();
                this.closeCreateDialog();
              })
              .finally(() => this.$store.dispatch('dialogloader/hide'))
              .catch((err) => {
                this.$store.dispatch('notificationbar/showNotification', { msg: err.response.data[0], color: 'error', show: true });
              });
          }
        });
    },
    onInviteItem() {
      if (!this.$refs.form.validate()) return;
      this.$store.dispatch('dialogloader/show', 'Inviting in progress...');
      sendInvitation(this.invitedItem, this.permissions)
        .then((response) => {
          this.invitedItem.email = response.data.email;
          this.invitedItem.can_manage_filestorage = this.permissions.can_manage_filestorage;
          this.invitedItem.can_start_trainings = this.permissions.can_start_trainings;
          this.fetchUsers();
          this.closeInviteDialog();
        })
        .finally(() => this.$store.dispatch('dialogloader/hide'))
        .catch((err) => {
          this.$store.dispatch('notificationbar/showNotification', { msg: err.response.data[0], color: 'error', show: true });
          this.mapSymfonyErrors(err.response.data.errors);
        });
    },
    onSendEmail() {
      if (!this.$refs.form.validate()) return;
      this.$store.dispatch('dialogloader/show', 'Sending e-mail in progress...');
      sendEmail(this.emailItem)
        .then((response) => {
          this.emailItem.subject = response.data.subject;
          this.emailItem.message = response.data.message;
          this.closeEmailDialog();
        })
        .finally(() => this.$store.dispatch('dialogloader/hide'))
        .catch((err) => {
          this.$store.dispatch('notificationbar/showNotification', { msg: err.response.data[0], color: 'error', show: true });
          this.mapSymfonyErrors(err.response.data.errors);
        });
    },
    closeCreateDialog() {
      this.createDialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.formErrors = {};
      this.permissions = [];
    },
    closeInviteDialog() {
      this.inviteDialog = false;
      this.$nextTick(() => {
        this.invitedItem = { ...this.defaultInvitedItem };
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.formErrors = {};
      this.permissions = [];
    },
    closeEmailDialog() {
      this.emailDialog = false;
      this.$nextTick(() => {
        this.emailItem = { ...this.defaultEmailItem };
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.formErrors = {};
    },
  },
  watch: {
    permissions: {
      deep: false,
      handler() {
        this.editedItem.can_manage_filestorage = this.permissions.indexOf('can_manage_filestorage') !== -1;
        this.editedItem.can_start_trainings = this.permissions.indexOf('can_start_trainings') !== -1;

        this.invitedItem.can_manage_filestorage = this.permissions.indexOf('can_manage_filestorage') !== -1;
        this.invitedItem.can_start_trainings = this.permissions.indexOf('can_start_trainings') !== -1;
      },
    },
    options: {
      handler() {
        this.fetchUsers();
      },
      deep: true,
    },
  },
};
</script>

<style>
.overflow-fix .v-toolbar__content {
  height: auto !important;
  flex-wrap: wrap;
}
</style>
