<template>
  <v-list-item v-if="use_list"
    :class="highlighted?'highlighted':''"
  >
    <v-chip
      :color="color"
      outlined
      pill
      close-icon="mdi-delete"
      close
      @click="$emit('item-click', index)"
      @click:close="$emit('item-remove-click', index)"
      style="min-width: 100px;"
    >
      {{ title }}
    </v-chip>
  </v-list-item>
  <div v-else style="display: table; width: 100%;" @click="$emit('item-click', index)">
    <div style="display: table-row; border-radius: 5px; cursor: pointer; width: 80%;">
      <div style="display: table-cell;" :class="(highlighted ? 'highlighted' : '')">
        <span :style="{
          'width': '24px',
          'max-height': '5px',
          'border-radius': '4px',
          'border': '1px solid black',
          'margin-left': '5px',
          'background-color': color,
          'color': color
        }">
          ___
        </span>
        <span style="padding-left: 5px;">
          {{ title }}
        </span>
      </div>
      <div style="display: table-cell; width: 20%;">
        <v-btn icon @click="$emit('item-remove-click', index)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LabelItem',
  props: {
    labelid: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: '',
    },
    use_list: {
      type: Boolean,
      default: false,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
div .highlighted {
  background-color: #f2f2f2;
  border: 1px solid #cccccc;
  border-radius: 5px;
}
</style>
