<template>
  <v-row class="justify-start">
    <v-col cols="12" sm="6">
      <v-select
        label="Select machine (path)"
        v-model="selectedItemId"
        :items="data"
        itemText="title"
        itemValue="id"
        :rules="$rules.required"
        :validate-on-blur="true"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
import getMachines from '@/api/user/getMachines';

export default {
  name: 'Machines',
  data() {
    return {
      data: [],
      loading: true,
      totalItems: 0,
      selectedItemId: 0,
    };
  },
  props: {
    selected: {
      type: Number,
      default: 0,
    },
  },
  async created() {
    console.log('selectedItemId in Kind', this.selectedItemId);
    this.fetchData();
    this.selectedItemId = this.selected;
  },
  watch: {
    selectedItemId() {
      this.activate();
    },
    selected: {
      immediate: true,
      handler(newVal) {
        if (newVal && this.selectedItemId !== newVal) {
          this.selectedItemId = newVal;
          if (this.selectedItemId) {
            this.activate();
          }
        }
      },
    },
  },
  methods: {
    fetchData() {
      this.loading = true;
      getMachines()
        .then((response) => {
          this.data = response.data;
          this.totalItems = response.data.length;

          if (this.$route.query.trainingData) {
            const trainingData = JSON.parse(this.$route.query.trainingData);
            this.selectedItemId = trainingData.machine.id;
            console.log('selectedItemId in Kind', this.selectedItemId);
          }
          this.activate();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    activate() {
      if (this.selectedItemId) {
        const machine = this.data.find((e) => e.id === this.selectedItemId);
        console.log('activate machine', machine);
        this.$emit('input', machine);
      }
    },
  },
};
</script>
