<template>
    <v-row class="justify-start">
      <v-col class="justify-center" cols="3" v-for="(item, index) in itemsWithIconUrl"
             v-bind:key="index">
        <div class="testingform__modetype" v-on:click="activate(item)"
             :class="{ active : (active_el.id !== undefined && active_el.id === item.id) }">
          <img :src="item.iconUrl" style="width: 50px; height: 50px"
               alt=""/>
          <div class="name">{{ item.name }}</div>
        </div>
      </v-col>
      <v-col cols="12" class="justify-start">
        <div class="trainingform__modeltype--descr">
          {{ this.active_el.description }}
        </div>
      </v-col>
    </v-row>
</template>
<script>

export default {
  name: 'TestingModes',
  data() {
    return {
      active_el: {},
      data: [],
      loading: true,
      defaultModelName: 'basic',
      modes: [
        {
          id: 1,
          name: 'Basic',
          description: 'Runs the basic test mode (with the model of the training selected below) on each image and writes the raw results in a csv file. No logical or statistical evaluations are applied.',
          icon: 'icon-testmode-basic.svg',
        },
      ],
    };
  },
  props: {
    selectedId: {
      type: Number,
      default: 1,
    },
  },
  created() {
    this.fetchModes();
  },
  methods: {
    fetchModes() {
      // TODO uncomment when API is ready
      // getModels()
      //   .then((response) => {
      //     this.data = response.data;
      //     console.log('data model type', this.data);
      //     // eslint-disable-next-line prefer-destructuring
      //     // this.active_el = response.data[1];
      //     // this.$emit('input', response.data[1]);
      //     this.totalItems = response.data.length;
      //   })
      //   .finally(() => {
      //     this.syncData();
      //     this.loading = false;
      //   });
      // eslint-disable-next-line prefer-destructuring
      this.active_el = this.modes[0];
      this.loading = false;
    },
    syncData() {
      // eslint-disable-next-line
      const selectedMode = this.data.find(x => x.id === this.selectedId);
      console.log('selectedMode', selectedMode);
      console.log('this.selectedId', this.selectedId);
      if (selectedMode) {
        this.activate(selectedMode);
      }
    },
    activate(el) {
      this.active_el = el;
      this.$emit('input', this.active_el);
    },
  },
  computed: {
    itemsWithIconUrl() {
      return this.modes.map(
        (item) => ({
          ...item,
          // eslint-disable-next-line global-require,import/no-dynamic-require
          iconUrl: require(`@/assets/icons/modes/${item.icon}`),
        }),
      );
    },
  },
};
</script>
<style scoped>
.active {
  border: #CE0F69 solid 3px;
}
.testingform__modetype {
  cursor: pointer;
}
</style>
