<template>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="testingsFiltered"
          disable-sort
          hide-default-footer
          hide-default-header
          class="elevation-0 testing-results notranslate"
          translate="no"
        >
          <template v-slot:item.created_at="{ item }">
            {{ format_date(item.created_at) }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn class="ml-lg-15" small @click="onDownloadResultTesting(item)"
                   color="#21BA45">
              <v-icon>mdi-download</v-icon>Download
            </v-btn>
          </template>
          <template v-slot:footer="">
          </template>
        </v-data-table>
</template>

<script>

import getTestings from '@/api/user/getTestings';
import getPresignedUrl from '@/api/user/getPresignedUrl';

export default {
  name: 'TestingResults',
  data() {
    return {
      testings: [],
      testingsFiltered: [],
      dialog: false,
      loading: true,
      headers: [
        { text: '', align: 'start', value: 'created_at' },
        { text: '', align: 'start', value: 'title' },
        { text: '', align: 'end', value: 'action' },
      ],
      maxResults: 5,
      bearer: '',
      apiUrl: process.env.VUE_APP_ROOT_API ? process.env.VUE_APP_ROOT_API.trim().replace(/\/+$/, '') : '',
    };
  },
  created() {
    this.fetchTestings();
    const bearer = this.$store.getters['user/token'];
    this.bearer = bearer.replace('bearer ', '');
  },
  methods: {
    fetchTestings() {
      getTestings(0, 'completed')
        .then((response) => {
          this.testings = response.data.data;
          let testingsFinishedCount = 0;
          this.testings.every((test) => {
            this.testingsFiltered.push(test.testing);
            testingsFinishedCount += 1;
            return testingsFinishedCount < this.maxResults;
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onDownloadResultTesting(testing) {
      this.$store.dispatch('dialogloader/show', 'Downloading in progress...');
      getPresignedUrl('testing', testing, this.bearer, this.apiUrl)
        .then((response) => {
          const presignedUrl = response.data.presigned_url;
          const link = document.createElement('a');
          const disposition = response.headers['content-disposition'];
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          let filename = 'download';
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
          link.href = presignedUrl;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => console.log('ERROR: downloadResultTraining', error));
      this.$store.dispatch('dialogloader/hide');
    },
  },
};
</script>
<style lang="scss">
.testing-results {
  height: 68%;
  .v-data-table__wrapper {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
}
</style>
