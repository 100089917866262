function createTraining(training) {
  console.log('createTraining', training);
  const data = {
    title: training.title,
    description: training.description,
    internal_identifier: training.internalIdentifier,
    training_model_id: training.trainingModelId,
    steps: training.steps,
    learning_rate: training.learningRate,
    machine_id: training.machineId,
    dataset_directory: training.datasetDirectory,
    augmentations: training.augmentations,
  };
  return window.$http({
    url: '/api/v1/training/create',
    method: 'post',
    data,
  });
}

export default createTraining;
